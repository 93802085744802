.login {
    border: none;
    margin: 0;
    padding: 0;

    &-modal {
        background: $theme-primary-color;

        @include breakpoint(xlarge) {
            background-image: none;
            padding: 0;
            width: rem-calc(500);

            &:focus {
                outline: none;
            }
        }

        .block-title {

            @include breakpoint(xlarge) {
                background: $theme-primary-color;
                color: $white;
                padding: rem-calc(8 16);
                text-align: center;
                text-transform: uppercase;
            }
        }

        .block-content {

            @include breakpoint(xlarge) {
                padding: rem-calc(16);
            }
        }
    }

    .actions-toolbar {
        @include flex;
        flex-flow: wrap;

        .secondary {
            @include flex-grid-row;
            @include flex-align($x: center);
            font-size: rem-calc(14);
            text-align: center;
            width: 100%;

            @include breakpoint(xlarge) {
                @include flex-order(2);
                margin-top: rem-calc(10);
            }

            a {

                @include breakpoint(xlarge) {
                    color: $theme-primary-font-color;

                    &:hover {
                        color: darken($theme-primary-font-color, 20%);
                    }
                }
            }
        }

        .primary {
            @include flex-grid-row;
            width: 100%;
        }

        .login-button {
            padding: rem-calc(10 0);
            margin-top: rem-calc(10);

            @include breakpoint(xlarge) {

                @include border-rounded;
                background: $theme-primary-color;

                &:hover {
                    background: $theme-secondary-color;
                }
            }
        }
    }
}
