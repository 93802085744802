$wishlist-double-shadow: 0 -7px 14px 0 rgba(0,0,0,0.14), 0 7px 14px 0 rgba(0,0,0,0.14);

.wishlist-index-index {

    .product {

        &-items {
            @include flex-grid-row(nest);
        }

        &-item {
            @include flex-grid-column();
			@include flex-grid-size(12);

			@include breakpoint(medium) {
				@include flex-grid-column(6);
                margin-bottom: rem-calc(30px);
			}

			@include breakpoint(large) {
				@include flex-grid-size(4);
            }

            &-info {
                overflow: visible;

                @include breakpoint(large down) {
                    height: auto!important;
                }
            }

            &-details {

                .product-item-prices-container {

                    .price-box {
                        height: 100%;

                        .configurable {
                            display: none
                        }

                        .price-as-configured {
                            margin: 0;
                            height: 100%;
                            display: flex;
                            flex-direction: column-reverse;
                            justify-content: space-between;

                            .old-price {
                                .price-container {
                                    .price-label {
                                        display: none;
                                    }
                                    .configurable-product {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.exclusive-price {

                        .exclusive-price-container {
                            position: absolute;
                            bottom: 30px;
                        }

                        .price-box .price-as-configured .special-price .price-container .price-wrapper .price {
                            color: $theme-accent-color;
                        }
                    }
                }
            }

            .product-item-tooltip {
                display: none;
            }

            &:hover {

                .product-item-details {
                    opacity: 1;
                    pointer-events: 1;
                }
            }
        }
    }

    .product-item {

        .price-box {
            span {
                align-items: center;
                display: flex;
                justify-content: center;
            }
            .price-label {
                font-size: rem-calc($pb-price-special-font-size);
				font-weight: $pb-old-normal-font-weight;
				line-height: rem-calc($pb-price-line-heihgt);
				margin-right: rem-calc(5);
				opacity: 0.5;
				text-transform: lowercase;
            }
        }

        &-inner {
            position: relative;
            width: 100%;
            display: none;

            @include breakpoint(large down) {
                display: block;
                width: auto;
                margin-left: rem-calc(-15);
                margin-right: rem-calc(-15);
            }

            .comment-box {
                @include element-invisible;
            }

            .box-tocart {
                padding: rem-calc(0 20px);

                .fieldset {
                    padding: 0;
                    margin: 0;
                    display: block;
                    border: none;
                    font-size: 0;
                    padding-top: rem-calc(25);
                    max-height: rem-calc(60);

                    &:before, &:after {
                        content: "";
                        display: table;
                        clear: both;
                    }
                }

                .field.qty {
                    width: 30%;
                    display: block;
                    float: left;

                    @include breakpoint(small only) {

                        @include element-invisible;

                    }

                    label {
                        position: absolute;
                        top:0;
                    }

                    input {
                        margin-bottom: 0;
                        height: rem-calc(35px);
                        text-align: center;
                        -moz-appearance:textfield;
                        -webkit-appearance: none;
                        line-height: rem-calc(17px);
                    }

                    input[type=number]:-webkit-outer-spin-button,
                    input[type=number]:hover:-webkit-outer-spin-button,
                    input[type=number]:focus:-webkit-outer-spin-button,
                    input[type=number]:-webkit-inner-spin-button,
                    input[type=number]:hover:-webkit-inner-spin-button,
                    input[type=number]:focus:-webkit-inner-spin-button {
                        -webkit-appearance: none!important;
                        margin: 0!important;
                        display: none!important;
                    }
                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                        opacity: 0;
                    }
                }

                .product-item-actions {
                    width: 70%;
                    display: block;
                    float: left;
                    padding: rem-calc(0 0 0 10);
                    margin: 0;

                    @include breakpoint(small only) {
                        float: none;
                        width: 100%;
                        padding: 0;
                    }
                    .actions-primary {

                        width: 100%;

                        button {
                            margin-bottom: 0;

                            @include breakpoint(large only) {
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .product-item-actions {

                > .action {
                    margin-bottom: rem-calc(8);
                }
            }
        }

        &:hover {

            @include breakpoint(xlarge) {
                z-index: 1000;

                .product-item-inner {
                    background: $white;
                    @include vendor(box-shadow, 0px 10px 18px 0px rgba(0, 0, 0, 0.14));
                    display: block;
                    padding: rem-calc(0 0 10px 0);
                    position: absolute;
                    top:100%;
                    left: 0;
                }
            }
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            pointer-events: all;
            position: relative;
            bottom: auto;
            padding: 0;
            min-height: initial;
            opacity: 1;
            margin-top: 3rem;

            .wishlist-dropdown {
                width: auto;
                max-width: 50%;
                order: 1;
            }

            .action {

                &.edit, &.delete {
                    min-width: 50%;

                    @include font(12, 12, $global-weight-semibold);
                    padding: rem-calc(0 20);

                    @include breakpoint(medium down) {
                        width: 50%;
                    }

                }

                &.edit {
                    padding-right: 0;
                }

                &.delete {
                    text-align: right;
                    padding-left: 0;
                }
            }
        }

        .product-item-checkbox {
            position: absolute;
            top:rem-calc(20);
            right: rem-calc(20);
        }
    }

    .block-wishlist-management {
        align-items: center;
        background: $white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: rem-calc(0 0 30 0);
        position: relative;
        z-index: 2;
    }

    .wishlist {

        &-name {

            &-label {
                @include font(14, 14, $global-weight-semibold);
                white-space: nowrap;

                &:after {
                    content: ':'
                }

                @include breakpoint(medium down) {
                    line-height: rem-calc(21);
                }
            }

            &-current {
                @include font(14, 14, $global-weight-semibold);
                display: none;

                @include breakpoint(large down) {
                    display: inline-flex;
                    align-items: center;

                    &:after {
                        height: rem-calc(14);
                        margin-left: rem-calc(5);
                        pointer-events: none;
                        transition: all 0.14s ease-in-out;
                        width: rem-calc(14);
                        @include ird-icon($ird-icon-chevron-down, 10px);
                        @include flex-center;
                    }
                }
            }

            &.active {

                .wishlist-name-current:after {
                    transform: rotate(-180deg);
                }
            }
        }

        &-select {
            align-items: center;
            border-bottom: 1px solid $input-border-color;
            display: flex;
            justify-content: flex-start;
            margin-bottom: rem-calc(40);
            padding: rem-calc(16 16 20);
            width: 100%;

            @include breakpoint(large down) {
                border: none;
                flex-wrap: wrap;
                margin-bottom: 0;
                padding-bottom: 0;
                position: relative;
                width: auto;
            }

            &-name {

                @include breakpoint(large down) {

                    padding: 1rem 0;

                }

            }

            &-items {

                align-items: center;
                display: flex;
                flex-grow: 1;
                margin-bottom: 0;

                @include breakpoint(large down) {

                    display: none;
                    flex-wrap: wrap;


                    &.active {

                        background: $white;
                        @include vendor(box-shadow, 0 7px 14px 0 rgba(0,0,0,0.14));
                        display: block;
                        left: 0;
                        position: absolute;
                        top:100%;
                        width: auto;
                        z-index: 1000;

                    }

                }

                @include breakpoint(medium) {

                    &.active {

                        width: auto;

                    }

                }

                .item:not(.wishlist-add) {

                    margin-left: rem-calc(20px);
                    padding: rem-calc(10px 0px);

                    @include breakpoint(large down) {

                        margin-left: 0;
                        padding: 0;

                    }

                    a, span {

                        @include font(14, 14, $global-weight-semibold);

                        color: inherit;
                        display: block;
                        text-align: center;

                        @include breakpoint(large down) {

                            @include font(13, 21, $global-weight-semibold);
                            font-weight: $global-weight-skinny;
                            text-align: left;
                            padding: rem-calc(8 20);
                            color: inherit;

                        }

                    }

                }

                .wishlist-add {

                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;

                    @include breakpoint(large down) {

                        justify-content: flex-start;
                        width: 100%;

                    }

                }
                .wishlist.add {
                    white-space: nowrap;

                    @include breakpoint(xlarge) {

                        @include button-style-3

                    }

                    @include breakpoint(large down) {

                        @include font(13, 21, $global-weight-semibold);

                        border-top: 1px solid rgba($theme-primary-color, 0.14);
                        color: inherit;
                        font-weight: $global-weight-skinny;
                        justify-content: flex-start;
                        padding: rem-calc(8 20);
                        width: 100%;

                        &:before {

                            content: "+";
                            display: inline-block;
                            margin-right: rem-calc(5);

                        }

                    }

                }

                .item.current {

                    border-bottom: 2px solid $theme-tertiary-color;

                    @include breakpoint(large down) {

                        border-bottom: none;

                        span {

                            color: $theme-tertiary-color;
                            font-weight: $global-weight-semibold;

                        }

                    }

                }

            }

        }

        &-title {
            width: 100%;
            display: flex;
            align-items: flex-end;
            margin-bottom: rem-calc(24);
            padding: rem-calc(0 16);

            @include breakpoint(large down) {
                border-top: 1px solid $input-border-color;
                margin-top: rem-calc(20);
                padding-top: rem-calc(20);
            }

            strong {
                @include font(24, 24, $global-weight-skinny);
            }

            .edit {
                color: $theme-secondary-color;
                @include font(12, 12, $global-weight-semibold);
                margin-left: rem-calc(10);
            }
        }

        &-info {
            display: flex;
            flex-wrap: wrap;
            @include font(12, 12, $global-weight-semibold);
            padding: rem-calc(16);

            @include breakpoint(large down) {
                width: 100%;
            }

            .private {
                display: none;
            }
        }

        &-toolbar {
            display: flex;
            align-items: center;
            padding: rem-calc(16);

            @include breakpoint(large down) {
                flex-wrap: wrap;
            }

            @include breakpoint(large) {
                justify-content: space-between;
                margin-right: rem-calc(-16);
            }

            &-actions {
                display: flex;
                align-items: center;

                @include breakpoint(small only) {
                    flex-wrap: wrap;
                    margin-top: 1rem;
                    width: auto;
                    margin-left: rem-calc(-16px);
                    margin-right: rem-calc(-16px);

                    .wishlist-dropdown {
                        width: 100%;
                    }
                }

                .wishlist-dropdown {
                    position: relative;

                    .toggle {
                        padding: rem-calc(10 20);
                    }

                    &.active .dropdown {
                        @include vendor(box-shadow, 0 7px 14px 0 rgba(0, 0, 0, 0.14));
                    }
                }
            }

            &-select {

                label {
                    margin-left: 0;
                }
            }
        }
    }

    .wishlist-dropdown {
        position: static;

        &.active {
            @include vendor(box-shadow, 0 -7px 14px 0 rgba(0, 0, 0, 0.14));
        }

        .toggle {
            @include font(12, 12, $global-weight-semibold);
            cursor: pointer;
            user-select: none;
            padding: rem-calc(10 20);
            display: flex;

            &:after {
                @include ird-icon($ird-icon-chevron-down, 10px);
                @include flex-center;
                width: rem-calc(10);
                height: rem-calc(10);
                margin-left: rem-calc(5);
                pointer-events: none;
                transition: all 0.14s ease-in-out;
            }
        }

        .dropdown {
            display: none;
            left: 0;
            position: absolute;
            top: 100%;
            background: $white;
            width: 100%;
            margin-left: 0;
            z-index: 1000;

            .item {
                @include font(13, 21, $global-weight-skinny);

                cursor: pointer;

                > span {
                    display: block;
                    padding: rem-calc(8px 20px);
                }

                &:hover {
                    background: rgba($theme-primary-color, 0.14);
                }

                .action.new {
                    border-top: 1px solid rgba($theme-primary-color, 0.14);

                    &:before {
                        content: "+";
                        display: inline-block;
                        margin-right: rem-calc(5);
                    }
                }
            }
        }

         &.active {

            .toggle {
                background: $white;
                position: relative;
                z-index: 2000;

                &:after {
                    transform: rotate(-180deg);
                }
            }

            .dropdown {
                display: block;
                @include vendor(box-shadow, $wishlist-double-shadow);

                &[aria-hidden="true"] {
                    display: none;
                }
            }
        }
    }

    .form-wishlist-items {

        .message {
            margin: 0 auto;

            &.empty {
                @include message(warning);
            }
        }
    }
}

#wishlist-view-form {

    .actions-toolbar {

        .primary {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            margin-top: rem-calc(30);

            @include breakpoint(medium down) {
                align-items: center;
            }

            .action {

                @include breakpoint(large) {
                    margin: rem-calc(0 10px 0 0);
                }

                @include breakpoint(medium only) {
                    margin: rem-calc(0 5px 20px 0);
                }

                @include breakpoint(small only) {
                    margin: rem-calc(10px 0 20px 0);
                    width: 100%;
                }
            }

            .update {
                @include button-style-1;
            }

            .share {
                @include element-invisible;
            }

            .tocart {
                @include button-style-1;
            }

            .remove {
                @include cta($white, $error, $error);
            }
        }

        .secondary {
            display: flex;
            justify-content: flex-start;
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(200px);
            width: 100%;

            @include breakpoint(large) {
                padding-left: 0;
            }

            @include breakpoint(retina) {
                padding-left: 0;
            }

            @include breakpoint(medium down) {
                margin-bottom: 0;
                margin-top: rem-calc(10);
            }

            .back {
               @include button-style-3
            }

            @include breakpoint(small only) {
                width: 100%;

                .action {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
}

.window.wishlist.overlay {
    position: fixed;
    top:0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 3000;
    transition: all 0.5 ease;
}

#create-wishlist-block {
    display: block;
    position: fixed;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    width: 90%;
    max-width: rem-calc($global-weight-semibold);
    z-index: 3001;
    @include vendor(box-shadow, 0 0px 148px 0 rgba(0, 0, 0, 0.14));
    transition: all 0.5s ease;
    margin: 5rem auto;

    .popup {

        &-actions {
            position: absolute;
            top:0;
            right: 0;
            padding: 1rem;
            width: rem-calc(60px);
            height: rem-calc(60px);

            .close {
                font-size: 0;
                display: block;
                width: rem-calc(24px);
                height: rem-calc(24px);
                position: absolute;

                &:after {
                    @include ird-icon($ird-icon-close, 24px);
                    @include flex-center;
                    @include absolute-full;
                    color: $theme-primary-alternative-font-color;
                }
            }
        }

        &-header {
            background: $theme-primary-color;

            @include breakpoint(small only) {
                padding:rem-calc(20px 60px 20px 30px);
            }

            @include breakpoint(medium) {
                padding:rem-calc(20px 60px 20px 20px);
            }

            .title {
                color: $white;
                text-align: center;
                @include font(18, 20, $global-weight-semibold);
                display:block;
            }
        }

        &-content {

            @include breakpoint(medium) {
                padding: rem-calc(30px);
            }

            @include breakpoint(small only) {
                padding: rem-calc(20px);
            }

            .fieldset {
                margin: 0;
                padding: 0;
                border: none;

                .field.choice {
                    display: none;
                }
            }

            .actions-toolbar {
                display: flex;
                margin-top: rem-calc(30px);

                > div {
                    flex-grow: 1;
                    margin-right: rem-calc(20px);

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .save {
                    @include button-style-1;
                    width: 100%;
                }

                .cancel {
                    @include button-style-3;
                    width: 100%;
                }
            }
        }
    }
}

.wishlist-index-index .modal-inner-wrap {

    .modal-header {
        padding:rem-calc(15px 30px);
        background: $theme-primary-color;
        text-align: center;
        position: relative;

        .title {
            color: $white;
            @include font(18, 20, $global-weight-semibold);
        }
    }

    .action-close {
        position: absolute;
        top:0;
        right: 0;
        padding: 1rem;
        font-size: 0;
        display: block;
        height: 100%;
        width: rem-calc(60px);
        box-sizing: border-box;

        span {
            display: none;
        }

        &:before {
            @include flex-center;
            @include absolute-full;
            @include ird-icon($ird-icon-close);
        }

    }

    .modal-footer {
        padding-top: 0;

        button:first-child {
            @include button-style-1;
            margin-right: rem-calc(20);
        }

        button:last-child {
            @include button-style-3;
            margin-right: rem-calc(0);
        }
    }
}

.page-multiple-wishlist {

    #edit-wishlist-form {

        .field.choice {
            display: none;
        }
    }
}

#wishlist-hidden-form {
    @include element-invisible;
}
