.catalog-product-view {
	background: $white;

	.column.main {
		background: $theme-body-background;
	}

	.page-main {
		max-width: 100%!important;

		.page-title-wrapper {
			padding: 0;
		}
	}

	.product-info {

		&-wrapper {
			background: $white;
			padding: rem-calc(24 0);
			padding-bottom: rem-calc(36);
		}

		&-top {
			@include flex-grid-row();

			@include breakpoint(large) {
				flex-flow: nowrap;
			}

		}

		&-main {
			@include flex-grid-column;
			@include flex-grid-size(12);
			width: 100%;
			@include breakpoint(large) {
				@include flex-grid-size(6);
			}

			@include breakpoint(xlarge) {
				@include flex-grid-size(5);
			}

			.box-tocart {

				.fieldset,
				.field {
					@include flex;
					flex-flow: column;

					@include breakpoint(large) {
						@include flex-align($y: middle);
						flex-flow: row;
					}
				}

				.field {

					@include flex-align($y: middle);
					flex-flow: row;

					input[type='number'] {
						margin: rem-calc(0 8);
						text-align: center;
						width: rem-calc(40);
						padding: 0;
					}

					#qty-error {
						@include element-invisible;
					}

					.label {
						font-size: rem-calc(13);
						font-weight: $global-weight-skinny;
						margin-right: rem-calc(8);
					}

					.control {
						@include flex;
						@include flex-align($y: middle);

						input[type='number'] {
							-moz-appearance:textfield;
						}

						/* Webkit browsers like Safari and Chrome */
						input[type=number]::-webkit-inner-spin-button,
						input[type=number]::-webkit-outer-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}
					}

					.increase,
					.decrease {
						color: $theme-product-qty-selector;
						cursor: pointer;

						img {
							height: rem-calc(20);
							width: rem-calc(20);
						}
					}
				}

				.actions {
					@include flex;
					flex-wrap: wrap;
				}
			}

			.tocart {
				@include buttonStyle($theme-product-basket-button-style);
				font-size: rem-calc(12);
				margin-top: rem-calc(10);
				padding: rem-calc(12 13);
				width: 100%;

				@include breakpoint(large) {
					margin: rem-calc(0 8);
					width: auto;
				}
			}

			.auctions {
				.tocart {
					@include breakpoint(large) {
						margin: rem-calc(0 0);
					}
				}
			}

			.tocheckout {
				@include buttonStyle($theme-product-checkout-button-style);
				cursor: pointer;
				font-size: rem-calc(12);
				margin-top: $global-margin;
				padding: rem-calc(12 13);
				width: 100%;

				@include breakpoint(large) {
					margin: rem-calc(0 8);
					width: auto;
				}
			}

			.towishlist {
				@include buttonStyle($theme-product-wishlist-button-style);

				font-size: rem-calc(13);
				padding: rem-calc(8 10);
				text-align: center;
				@include breakpoint(medium down) {
					width: 100%;
				}

				&:before {
					@include flex-center;
					@include ird-icon($ird-icon-heart, 14px);
					color: $theme-button-style-2-color;
					width: rem-calc(25px);
				}
			}

			.product-social-links {

				.wishlist {
					order: 10;
					display: inline-flex;
					justify-content: flex-start;
					align-items: center;
					position: relative;
					padding: rem-calc(10px 15px);
					margin-left: rem-calc(-15px);
					width: auto;
					z-index: 1;
					@include breakpoint(medium down) {
						justify-content: center;
						width: 100%;
					}

					> button[data-action="add-to-wishlist"] {
						bottom: rem-calc(16);
						color: $theme-button-style-2-color;
						font-size: rem-calc(13);
						font-weight: $global-weight-semibold;
						margin: 0;
						padding: rem-calc(0 10);
						margin-right: rem-calc(10);
						width: auto;
						z-index: 11;
						border-right: 1px solid rgba($theme-product-font-color, 0.75);
						@include flex-center;

						&:before {
							@include flex-center;
							@include ird-icon($ird-icon-heart, 14px);
							color: $theme-button-style-2-color;
							width: rem-calc(25px);
						}
					}

					.action.toggle.change {
						cursor: pointer;
						display: block;
						font-size: 0;
						height: rem-calc(12px);
						transition: all 0.14s linear;
						width: rem-calc(12px);
						z-index: 11;
						position: relative;

						&:after {
							@include absolute-full;
							@include flex-center;
							@include ird-icon($ird-icon-chevron-down, 12px);
						}

						&.active {
							transform: rotate(180deg);
						}
					}

					.items {
						background: $white;
						@include vendor(box-shadow, 0px 0px 18px 0px rgba(0, 0, 0, 0.13));
						left: 0;
						margin: 0;
						padding: rem-calc(45px 0px 0px);
						position: absolute;
						top:0;
						min-width: rem-calc(200px);
						max-width: 100%;
						width: 100%;
						z-index: 10;
						opacity: 1;
						height: auto;
						transition: all 0.15s ease;
						@include breakpoint(medium down) {
							left: rem-calc(15);
						}

						&[aria-hidden="true"] {
							display: none;
						}

						.item {
							@include font(14, 21, $global-weight-skinny);
							cursor: pointer;

							> span {
								display: block;
								padding: rem-calc(8px 15px);
							}

							&:hover {
								background: rgba($theme-primary-font-color, 0.14);
							}

							.action.new {
								border-top: 1px solid rgba($theme-primary-font-color, 0.14);

								&:before {
									content: "+";
									display: inline-block;
									margin-right: rem-calc(5);
								}
							}
						}
					}
				}
			}
		}

		&-media {
			@include flex-grid-column;
			@include flex-grid-size(12);

			@include breakpoint(large) {
				@include flex-grid-size(6);
			}

			@include breakpoint(xlarge) {
				@include flex-grid-size(7);
				max-width: $fotorama-image-max-width + $fotorama-nav-thumbnail-width-small + $fotorama-nav-thumbnail-gutter + rem-calc(map-get($grid-column-gutter, medium));
			}

			@include breakpoint(xxlarge) {
				max-width: $fotorama-image-max-width + $fotorama-nav-thumbnail-width-large + $fotorama-nav-thumbnail-gutter + rem-calc(map-get($grid-column-gutter, medium));
			}
		}

		&-price {
			@include flex-grid-row;
			border-bottom: $element-border;
			padding: $global-margin 0;
			align-items: flex-start;

			.price-box {
				@include flex-order(1);
				@include flex-grid-column(12, 0);

				margin-bottom: rem-calc(10);

				@include breakpoint(large) {
					@include flex-grid-column(6, 0);
					@include flex-align($x: left);
					margin-bottom: 0;
				}

				&.price-final_price {
					display: flex;
					flex-direction: column;
				}

				.price-container {
					display: flex;
				}

				.price {
					@include breakpoint(large only) {
						font-size: rem-calc(20);
					}

					font-size: rem-calc(22);
					font-weight: $global-weight-semibold;
				}

				.special-price,
				.old-price {
					@include flex;
					width: 100%;
					margin-top: 0;

				}

				.special-price {
					@include flex-order(1);
				}

				.old-price {
					order: -1;

					.price {
						font-size: rem-calc(14)!important;
						font-weight: $global-weight-skinny!important;
						margin-top: 0;
						line-height: rem-calc(24px);
					}
				}
			}

			.price-label {
				font-size: rem-calc(12);
                display: none;
			}

			.exclusive-price-container {
                @include font(14, 16, $global-weight-skinny);
                width: 100%;
                text-align: left;
                color: $theme-accent-color;
				display: block;
				order: 100;
				margin-top: rem-calc(10px);

				@include breakpoint(small only) {
					margin-top: 0;
				}

                .price {
                    display: block;
                    margin-top: rem-calc(5px);
                    @include font(21, 21, $global-weight-semibold);
				}
			}

			.exclusive-price-container.logged-in + .price-box .special-price .price-container .price-wrapper .price {
				color: $theme-accent-color;
			}

		}

		&-stock-sku {
			@include flex-order(2);
			@include flex-grid-row;
			@include flex-grid-column(12, 0);
			@include flex-align($x: justify, $y: middle);

			@include breakpoint(large) {
				@include flex-grid-column(6, 0);
			}

			@include breakpoint(medium down) {
				@include flex-order(-1);
				margin-bottom: rem-calc(10px);
			}

			.attribute {
				@include flex;
				font-size: rem-calc(12);
				font-weight: $global-weight-skinny;

				.type {
					font-weight: $global-weight-skinny;
					margin-right: rem-calc(6);

					&:after {
						content: ': '
					}
				}

				.value {
					font-weight: $global-weight-normal;
				}
			}

			.stock {
				@include flex;
				@include flex-align($y: middle);

				background: $theme-product-stock-background;
				color: $theme-product-stock-color;
				font-size: rem-calc(12);
				padding: rem-calc(3 8);
			}

		}

		&-delivery {
			border-bottom: $element-border;
			font-size: rem-calc(12);
			margin-bottom: rem-calc(15);
			padding: $global-margin 0;

			p {
				font-weight: $global-weight-skinny;
				margin-bottom: 0;
			}

			span {
				font-weight: $global-weight-semibold;
			}
		}

		&-details {
			@include flex-grid-row;
			padding: 0 $global-margin;

			.tabs {
				background: none;
				@include vendor(box-shadow, none);
				border: 0;
				margin-top: rem-calc(-18);
				width: 100%;

				&-title {
					background: $white;
					@include vendor(box-shadow, 0 0 6px 0 rgba(0, 0, 0, 0.3));
					width: auto;
					border-color: $theme-product-tabs-border-color;

					& > a {
						color: $theme-product-tabs-font-color;
					}

					& > a[aria-selected='true'] {
						background: $theme-product-tabs-heighlight-background !important;
						color: $theme-product-tabs-heighlight-color;
					}
				}

				&-content {
					width: 100%;
					margin-bottom: $global-margin;
				}

				&-panel {
					@include flex-grid-column(12);
					background: $white;
					border-top: 2px solid $theme-product-tabs-heighlight-background;
					box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
					font-size: rem-calc(12);
					margin-top: $global-margin;
				}
			}

			.accordion {
				@include flex-grid-column(12);
				background: $theme-body-background;

				@include breakpoint (small only){
					padding: 0;
				}
				&-title {
					text-transform: initial;
					&:focus {
						background: transparent;
					}
					@media (hover: hover) {
						&:hover {
							background-color: rgba($theme-tabs-color, 0.14);
						}
					}
				}

				&-item {
					background: $white;
					color: $white;
					margin-bottom: rem-calc(10);
					border-color: $theme-product-tabs-border-color;

					a {
						color: $theme-tabs-color;
						@include flex-align($y: middle);
						@include flex;
						display: block;
						font-size: rem-calc(14px);
						font-weight: $global-weight-semibold;
						line-height: rem-calc(17px);
						padding: rem-calc(14 24);
						text-align: center;
						width: 100%;
					}
					a[aria-selected='true'] {
						background: $theme-product-tabs-heighlight-background;
						color: $theme-product-tabs-heighlight-color;
					}
				}

				.is-active {
					background: $theme-product-tabs-heighlight-background;
					color: $theme-product-tabs-heighlight-color;

					a {
						color: $white;
					}
					@include breakpoint(medium down) {
						.accordion-content {
							ul {
								margin-left: 0;
							}
							a {
								color: $anchor-color;
								display: inline;
								padding: 0;
								font-weight: 400;
							}
						}
					}
				}

				&-content {
					background: $white;
				}
			}
		}
	}
	.auctioned-product {
		.product-info {
			display: flex;
            align-items: flex-start;
            flex-flow: row wrap;
            justify-content: left;
            margin-right: auto;
            margin-left: auto;
			border-bottom: 1px solid #333;
			padding: 0.9rem 0px 1rem 0px;
			.stock {
				span {
					background-color: #333;
					color: #fff;
					padding: 5px 9px;
				}
			}

			.current-bid {
                margin-right: 1rem;
				font-size: 22px;
				span {
					font-weight: 500;
					font-size: rem-calc(22);
				}
			}

            .stock-sku {
                display: flex;
				flex: 100 0 50%;
				flex-wrap: wrap;

                @include breakpoint(small down) {
                    display: flex;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

			.stock {
				@include breakpoint(medium down) {
					clear: none;
					text-align: left;
				}
                margin-right: auto;
                margin-left: 0;
				text-align: right;
				font-size: 12px;
				line-height: 2.8;
			}

			.product-sku {
				@include breakpoint(medium down) {
					width: auto;
					float: right;
					clear: right;
					max-width: 75%;
				}
                margin-right: 0;
                margin-left: auto;
				text-align: right;

				font-size: 12px;
				line-height: 2.8;
				span {
					font-weight: 300;
				}
			}
		}

		.auction-bid {
			width: 100%;
			float: left;
			margin: 1rem 0px auto;
			border-bottom: 1px solid #333;
			font-size: 12px;
			font-weight: 300;

			#end-date {
				display: none;
			}

			.bid-date-and-time {
				width: 100%;
			}

			@include breakpoint(large down) {
				.number-of-bids {
					float: right;
				}
			}
			@include breakpoint(small only) {
				.number-of-bids {
					padding-top: rem-calc(10);
					float: left;
				}
			}
			@include breakpoint(medium) {
				.number-of-bids {
					float: right;
				}
			}

			div {
				@include breakpoint(small only)  {
					min-width: 100%;
				}
				float: left;
				margin-right: 0;
			}

			p, span {
				font-weight: 500;
			}

            .time-label {
                font-weight: 300;
            }
		}

		.bid-increment {
			margin-top: -0.5rem;
			margin-bottom: 1rem;

			.bid-increment-max {
				margin-left: rem-calc(20);
				@include breakpoint(small only) {
					margin-left: 0;
					padding-top: rem-calc(7);
				}
			}

		}

		.auction-countdown-timer {
			width: 250px;
			display: inline-block;
			@include breakpoint(small only) {
			width: 240px;
			}
		}

		#submit-bid-button {
			font-size: rem-calc(15);
		}

		#bid-amount {
			font-size: rem-calc(15);
		}

		input#bid-amount {
			display: inline-block;
			margin-top: rem-calc(10);
			margin-bottom: 0;
		}
	}


	.product {

		&.detailed {
			background: $body-background;
		}
	}

	.product-options {

		&-wrapper {
			border-bottom: $element-border;

			.fieldset {
				padding: 0;
			}
		}

		&-bottom {
			@include flex;
			flex-flow: column;
			@include breakpoint(large) {
				@include flex-align($y: middle);
				flex-flow: row;
			}
		}
	}

	.related, .upsell {
		@include flex;
		background: #f2f2f2;
		flex-direction: column;
		margin-bottom: rem-calc(50);
		padding: rem-calc(30 0);

		.products-related, .products-upsell {
			width: 100%;
		}

		.title {
			@include font(24, 29, $global-weight-semibold);
			color: $theme-primary-font-color;
			margin-bottom: rem-calc(30);
		}

		.content {
			@include flex;
			@include flex-grid-row();
			padding: 0 $global-margin;
			width: 100%;
		}

		.product-items {
			display: flex;
			overflow: hidden;
			width: auto;
			white-space: nowrap;

			@include breakpoint(xlarge) {
				margin-left: -$product-margin;
			}

			@include breakpoint(medium down) {
				margin-left: rem-calc(-10);
				margin-right: rem-calc(-10);
			}

			&.slick-slider {
				@include breakpoint(large down) {
					display: block;
					overflow: visible;
				}
			}

			> .product-item {
				min-width: 25%;

				@include breakpoint(xlarge) {
					padding-left: $product-margin;
				}

				@include breakpoint(medium down) {
					min-width: 50%;
					padding: 0 10px;
				}

				@include breakpoint(small only) {
					min-width: 100%;
				}
			}
		}

		.product-item {
			margin-bottom: 0;
		}

		.slick-list {
			width: 100%;

			.slick-track {
				display: flex;
				min-width: 100%;

				.slick-slide {
					padding-top: rem-calc(3px);

					@include breakpoint(xlarge){
						margin-left: $product-margin;
					}

					@include breakpoint(medium down){
						margin-left: rem-calc(10);
					}

					@include breakpoint(medium down) {
						padding-right: rem-calc(10);
					}
				}
			}
		}

		.slick-dots {

			@include breakpoint(large down) {
				margin-bottom: rem-calc(-30);
				width: 100%;
			}
		}
	}
}

.product-info-main {

	[type='text'], [type='number'], textarea {
		@include breakpoint(large) {
		width: 30%;
		}
	}

	.page-title {
		border-bottom: $element-border;
		font-size: rem-calc(24);
		font-weight: $global-weight-skinny;
		padding-bottom: $global-margin;
	}

	.fieldset {
		border: 0;
		margin: 0;
		padding: 0.5rem 0;
	}
}

.auctions {
	@include breakpoint(large) {
	display: inline-block;
	}
}

.catalog-product-view,
.cms-index-index,
.cms-index-defaultindex {
    .recommended-products-list {
        .product {
            &-items {
                @include flex-grid-row();
            }

            &-item {
                @include flex-grid-column(12, 0);

                @include breakpoint(xlarge) {
                    padding-left: $product-margin;
                }

                @include breakpoint(xlarge) {
                    @include flex-grid-column(3, 0);

                    padding-right: 0px;
                    padding-left: rem-calc(30px);

                    &-info:hover {

                        .product-item-actions {
                            opacity: 1;
                            pointer-events: all;
                        }

                        .product-item-name {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
        &.grid {
            max-width: 1280px;
            margin: 0 auto;
        }
    }
    .product-recommender-block {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.catalog-product-view {
    .recommended-products-list {
        .product {
            &-item {
                @include breakpoint(xlarge) {
                    padding-right: rem-calc(15px);
                    padding-left: rem-calc(15px);
                }
            }
        }
    }
    .product-recommender-title {
        @include breakpoint(xlarge) {
            padding-left: rem-calc(20px);
            padding-right: rem-calc(20px);
        }
    }
}

.page-product-configurable {
	.product-info-main {
		select.super-attribute-select {
			-webkit-appearance: auto;
			-moz-appearance: auto;
			appearance: auto;
			background-image: none;
		}
	}
}
