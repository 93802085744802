.tabs {
    @include flex;
    @include vendor(box-shadow, 0 0 8px 0 rgba(0,0,0,0.14));

    &-title {
        @include flex;
        border: 1px solid $theme-tabs-border-color;
        width: 100%;

        &:not(.is-active) {

            a:focus {
                background: initial!important;
                color: initial;
            }
        }

        a {
			color: $theme-tabs-color;
            @include flex-align($y: middle);
            @include flex;	
            display: block;
            font-size: rem-calc(14px);	
            font-weight: $global-weight-semibold;	
            line-height: rem-calc(17px);	
            padding: rem-calc(14 24);
            text-align: center;
            width: 100%;	

			&:hover {
				background: rgba($theme-tabs-color, 0.14);
            }
        }

        a[aria-selected='true'] {
            background: none;
        }
    }

    &-panel {
        padding: rem-calc($product-margin 0);
    }

    .is-active {
        background: $theme-tabs-hihglight-color;

        &:hover {
            background: $theme-tabs-hihglight-color;
        }

        a {
            font-weight: $global-weight-bold;
            color: $theme-tabs-hihglight-text;
        }
    }
}

.accordion {

    &-title {
        text-transform: uppercase;
    }

}

.tabs-content {
    border: none;
    background:transparent;
    color: $theme-primary-font-color;
}
