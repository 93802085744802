.reveal {
    border: none;

    &-search {
        background: $white;
        top: 0!important;
    }

    &-options {
        background: $white;
        top: 0!important;

        @include breakpoint(large down) {
            overflow-y: scroll;
        }
    }
}

.reveal-search {
    width: 100vw!important;
    height: 100vh!important;
    top:0!important;
    left:0!important;
    right:0!important;
    bottom:0!important;
    margin: 0!important;
}
