.points-slider {

    input[type="number"] {
        background: none;
        border: 0;
        box-shadow: none;
        color: $theme-primary-font-color;
        display: inline-flex;
        height: auto;
        margin-bottom: 0;
        padding: 0;
        width: inherit;
        @include vendor(box-shadow, none);
    }

    .slider {
        height: rem-calc(4);
        background: $theme-body-background;
        width: 100%;
    }

    .slider-handle {
        @include border-rounded;
        background: $theme-search-bar-slider-color;
        border: rem-calc(2) solid $white;
        height: rem-calc(26);
        width: rem-calc(26);
        @include vendor(box-shadow, 0 0 18px 0 rgba(0,0,0,0.14));

        &:focus {
            border: 0;
        }
    }

    .slider-fill {
        background-color: $theme-search-bar-slider-color;
        height: rem-calc(4);
    }

    .points-values {
        color: $theme-primary-font-color;
        font-size: rem-calc(12);
        min-width: rem-calc(175);
        @include font(12, 14, $global-weight-skinny);
        @include vendor(user-select, none);
    }

    .point-title {
        font-weight: bold;
    }

    .ranger-slider {
        margin-left: rem-calc(16);
        width: 100%;

        @include breakpoint(medium) {
            width: rem-calc(328);
        }

        .slider {
            margin-bottom: 0;
            margin-top: 0;
            @include vendor(user-select, none);
        }
    }
}
