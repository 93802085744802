.cms-terms-and-conditions,
.cms-cookie-policy,
.cms-privacy-policy,
.cms-terms-of-use {
    background: $white;
}

.static-page-content {

    article {
        position: relative;

        section {
            position: relative;
            margin-bottom: rem-calc(0);
            
            &:not(:last-of-type){
                margin-bottom: rem-calc(30); 
            } 

            p, div, ul, li, span {
                @include font(14, 21, $global-weight-skinny);

                text-align: justify;
            }

            strong, b {
                @include font(14, 21, $global-weight-semibold);
            }

            em, i {
                @include font(14, 21, $global-weight-skinny, italic);
            }

            a {
                @include font(14, 21, $global-weight-semibold);

                color: $theme-tertiary-color;
                text-decoration: underline;
            }

            p {
                margin-bottom: rem-calc(8);
            }

            p + ul {
                margin-top: rem-calc(-3);
            }

            ul {
                margin: rem-calc(8px 0);
                padding-left: rem-calc(40);
                list-style-type: disc;

                li {
                    position: relative;
                    margin-bottom: rem-calc(3);

                }
            }
            
            u {
                text-decoration: underline;
            }
        }
    }

    section.static-page-contact-info {
        padding-top: rem-calc(20);
        margin-top: rem-calc(20);
        display: inline-block;
        border-top: 1px solid $light-grey;
    }

    .static-page-contact-info {

        span, strong {
            display: block;
        }
    }
}