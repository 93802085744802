.page-footer {
    background: $theme-footer-background;
    color: $theme-footer-color;

    > .footer {
        @include flex-grid-row();
        @include flex-align($y: middle);
        padding: rem-calc(15 0);

        @include breakpoint(medium down) {
            padding: rem-calc(8 0);
        }

        ul.links {
            max-width: 100%;
        }

        .links {
            @include flex-grid-column(12);
            @include flex-align($x: center);
            @include flex-order(-1);
            display: flex;
            flex-wrap: wrap;
            font-size: rem-calc(15);
            font-weight: $global-weight-skinny;
            margin-bottom: 0;

            @include breakpoint(medium only) {
                justify-content: space-evenly;
            }
    
            @include breakpoint(large) {
                @include flex-grid-size(7);
                @include flex-order(1);
                @include flex-align($x: right);
            }
    
            .item {
                width: auto;
                
                a {
                    display: block;
                    text-align: center;
                    padding: rem-calc(15 8);
                    color: $theme-footer-color;
                    @include font(15, 17, $global-weight-skinny);

                    @include breakpoint(medium down) {
                        padding: rem-calc(8);
                    }
                }
            }
        }
    
        .copyright {
            @include flex-grid-column(12);
            @include font(14, 17, $global-weight-skinny);
            text-align: center;
            padding: rem-calc(15 0);
    
            @include breakpoint(large) {
                @include flex-grid-size(5);
                text-align: left;
                color: inherit;
            }

            @include breakpoint(medium down) {
                padding: rem-calc(8 0);
            }
        }
    }
}
