// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        //.lib-button-l();
        //.lib-button-as-link(
        //    margin: 0 10px 10px 0,
        //    padding: 15px 17px;
        //);
        //.lib-css(font-weight, 600);
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
    }

    &.pagebuilder-button-primary {
        //.lib-link-as-button();
        //.lib-button-primary(margin: 0 10px 10px 0);
        //.lib-button-l();
        //.lib-css(border-radius, @button__border-radius);
        @include buttonStyle(1);
        padding: rem-calc(8 0);
        width: rem-calc(150);
        box-shadow: none;

    }

    &.pagebuilder-button-secondary {
        //.lib-link-as-button();
        //.lib-button(@_button-margin: 0 10px 10px 0);
        //.lib-button-l();
        //.lib-css(border-radius, @button__border-radius);
        box-shadow: none;
    }
}
