// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: 768px) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
    }
}
