.page-layout-1column:not(.cms-index-index) {

    .page-main {
        @include flex-grid-row();
        align-content: flex-start;
        display: block;

        > .row {
            width: 100%;
        }
    }

    .main {
        @include flex-grid-column(12, 0);
        padding-bottom: rem-calc(16);
    }

    main > .columns {
        width: 100%;
    }
}
