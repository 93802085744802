.checkout-index-index {
	background: $white;
	color: $theme-checkout-font-color;

	.page-main .page-title-wrapper {
		@include flex-grid-column(12);
		padding-top: 0;

		@include breakpoint(large) {
			@include flex-grid-column(12,0);
		}
	}

	.page-title {
		@include flex-grid-column(12);
		@include font(20, 24, $global-weight-semibold);
		letter-spacing: rem-calc(1.07);
		margin-bottom: 0;
		padding-top: rem-calc(40);
		text-transform: initial;
	}

	.opc-summary-wrapper .title {

		@include font(18, 24, $global-weight-semibold);

		@include breakpoint(small only) {
			font-size: rem-calc(16px);
		}

		letter-spacing: rem-calc(1.07);
		margin-bottom: rem-calc(27);
		display: flex;

		span {
			text-transform: uppercase;
			display: flex;
		}

		strong {
			font-weight: inherit;
		}
	}

	.page-main .main {
		overflow: inherit;
	}

	.modal-popup {

		.modal-inner-wrap {
			margin: 5vh auto;
			max-height: 90%;
			max-width: rem-calc(500);
			width: 90%;

			.modal-header {
				align-items: center;
				background: $theme-checkout-modal-title-background;
				display: flex;
				justify-content: space-between;

				.modal-title {
					@include font(18, 20, $global-weight-semibold);
					flex-grow: 1;
					text-align: center;
					color:$theme-checkout-modal-title-color;
				}

			}

			.modal-content {
				color: $theme-checkout-modal-font-color;
			}

			.modal-footer {

				.action-save-address {
					@include button-style-1;
				}

				.action-hide-popup {
					@include button-style-3;
				}
			}
		}
	}

	.messages:empty {
		margin: 0;
		padding: 0;
	}

	.billing-email {
		span{
			padding-left: 0;
		}
	}
}

.checkout {

	&-container {
		@include flex-grid-row(nest);
		margin-bottom: $global-margin;

		@include breakpoint(large down) {
			margin-left:0;
			margin-right: 0;
		}

		.messages,
		.opc-estimated-wrapper,
		.opc-progress-bar,
		.opc-wrapper {
			@include flex-grid-column(8);

			@include breakpoint(large only) {
				@include flex-grid-column(7);
			}

			@include breakpoint(medium down) {
				@include flex-grid-column(12);
			}
		}

		.opc-wrapper {

			[name="shippingAddress.telephone"],
			[name="billingAddresspoints_gateway.telephone"],
			[name="billingAddressworldpay_cc.telephone"] {

				._with-tooltip {

					.field-tooltip {
						display: none;
					}
				}
			}
		}

		.opc-summary-wrapper {
			@include flex-grid-column(4);

			.modal-inner-wrap {
				height: 100%;

				.modal-header {
					display: none;
				}
			}

			@include breakpoint(large only) {
				@include flex-grid-column(5);
			}

			@include breakpoint(large) {
				margin-top: rem-calc(-95);
			}

			@include breakpoint(medium down) {
				@include flex-grid-column(12);
				margin-top: rem-calc(0);
				order: -1;
			}

			.items-count-span {
				margin-right: rem-calc(5);
			}

			.modal-content {
				background: $theme-body-background;
				padding: $global-padding;
				padding-top: rem-calc(40);
				height: 100%;
				color: $theme-checkout-order-summary-font-color;

				#opc-sidebar {
					max-height: 100%;
					padding-right: 0;
				}
			}
		}
	}

	&-billing-address {
		margin-left: 0!important;

		@include breakpoint(large) {
			margin-left: $global-margin;
		}

		.billing-address-form {
			@include breakpoint(small only) {
				padding: rem-calc(0 5px);
			}
		}

		.billing-address-same-as-shipping-block {
			@include flex-grid-column(12);
            @include breakpoint(large) {
                padding-left: 0px;
            }
		}

		.field-select-billing {
			padding: rem-calc(0 15px);


			label {
				display: none;
			}
		}

		.field-tooltip-content {
			@include element-invisible;
		}

		.fieldset .actions-toolbar {
			padding-right: rem-calc(15px);
		}
	}

	&-payment-method {

		.payment-method {
			border-bottom: 0;

			@include breakpoint(large down) {
				margin-left: rem-calc(-15);
			}

			.payment-method-billing-address {

				@include breakpoint(large down) {
					margin-right: rem-calc(-15px);
				}
			}

			p:empty {
				margin-bottom: 0;
			}
		}

		.no-quotes-block {
			@include element-invisible;
		}

		.legend {
			@include flex-grid-column(12);

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		.payment-method-content > .actions-toolbar {
			padding-right: rem-calc(15px);

			@include breakpoint(medium down) {
				padding-right: 0;
			}
		}

		.giftcardaccount {
			@include element-invisible;
		}

		.discount-code {
			border-bottom: 1px solid $light-grey;
			border-top: 1px solid $light-grey;
			margin: rem-calc(0 16);

			@include breakpoint(large down) {
				margin: 0;
			}

			.label {
				@include element-invisible;
			}

			.action-apply, .action-cancel {
				@include buttonStyle($theme-checkout-discount-button-style);
				min-width: rem-calc(144px);

				@include breakpoint(small only) {
					width: 100%;
				}
			}

			input[name="discount_code"] {
				margin-bottom: 0;
				width: 100%;
			}
		}
	}

	&-agreements {
		width: 100%;
		padding: rem-calc(0 15);

		@include breakpoint(medium down) {
			padding-right: 0;
		}

		.checkout-agreement {

			label {
				margin-left: 0;
				margin-right: 0;
				display: inline-flex!important;
				@include vendor(user-select, none);

				button {
					color: $theme-secondary-color;
					@include vendor(user-select, none);

					span {
						color: inherit;
						user-select: none;
						@include is-required;
						@include vendor(user-select, none);

						&:after {
							font-size: rem-calc(16px);
						}
					}
				}
			}

			input:not(:checked).mage-error + label:before {
				border-color: $error;

			}

			.mage-error {
				margin-top: rem-calc(10px);
			}

			input:checked + label + .mage-error {
				display: none!important;
			}
		}
	}

	&-onepage-success {
		background: $white;

		.page-main {
			@include flex-grid-column(12);
		}

		.page-title-wrapper {
			margin-top: rem-calc(50);

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		.page-title {
			@include font(20, 24, $global-weight-semibold);
			letter-spacing: rem-calc(1.07px);
			margin-bottom: rem-calc(40);
			margin-top: rem-calc(50);
		}

		.thank-you {
			@include font(20, 24, $global-weight-skinny);
			display: block;
			margin-bottom: rem-calc(20);
		}

		.print {
			@include buttonStyle($theme-checkout-print-button-style);

			@include breakpoint(small only) {
				width: 100%;
				margin-bottom: rem-calc(15);
			}
		}

		.continue {
			@include buttonStyle($theme-checkout-continue-shopping-button-style);

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		.checkout-success {
			width: 100%;
			max-width: rem-calc(710);

			p {
				@include font(15, 18, $global-weight-skinny);
				margin-bottom: rem-calc(20);
				color: $theme-checkout-font-color;

				a {
					color: inherit;
					font-weight: $global-weight-semibold;
				}
			}

			.actions-toolbar {

				.primary {
					@include breakpoint(small only) {
						width: 100%;
					}
				}
			}
		}

		.actions-toolbar {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;

			@include breakpoint(large down) {
				justify-content: flex-start;
			}
		}
	}
}


.checkout-container,
.checkout-index-index .modal-popup {

	.step-title {
		@include font(20, 24, $global-weight-normal);

		color: $theme-checkout-font-color;
		margin-bottom: rem-calc(25px);
		margin-top: rem-calc(16px);

		@include breakpoint(large down) {
			padding: 0;
		}

		@include breakpoint(small only) {
			text-align: center;
		}
	}

	.label {
		@include font($input-label-size, $input-label-size, $input-label-weight);
		margin-bottom: rem-calc(7px);
		order: -1;
	}

	[type='text'],
	[type='password'],
	[type='date'],
	[type='datetime'],
	[type='datetime-local'],
	[type='month'],
	[type='week'],
	[type='email'],
	[type='number'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='url'],
	[type='color'],
	textarea,
	select {
		border-radius: 0;
		border: $input-border-width solid $input-border-color;
		height: rem-calc($input-height);
		line-height: rem-calc($input-height);
		margin-bottom: rem-calc(15);
		padding: rem-calc(0 16);
	}

	.choice.field {

		@include breakpoint(large down) {
			padding: rem-calc(0 16);
		}
	}

	select {
		-webkit-appearance: auto;
		-moz-appearance: auto;
		appearance: auto;
		background-image: none;
		padding-right: rem-calc(40px);

		&:after {
			content: 'a';
		}
	}

	.checkout-agreements-block {
        margin: 0 rem-calc(15) 0 0;

        .checkout-agreements {
			.field.choice {
				display: flex;
				justify-content: inherit;
				align-items: inherit;
			}
		}
	}

	.field.choice {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		input {
			margin-bottom: 0;
		}

		label {
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	.form-shipping-address {
		width: 100%;

		.fieldset.address {
			margin-left: rem-calc(-7);
			margin-right: rem-calc(-7);
		}

		.field {
			padding-left: rem-calc(7px);
			padding-right: rem-calc(7px);
		}

		.fieldset legend {
			margin-left: 0;
			padding-left: rem-calc(7);
		}

		fieldset.field {
			padding-left: rem-calc(0px);
			padding-right: rem-calc(0px);

			.legend {
				padding-left: rem-calc(7);
			}
		}
	}


	.opc {
		list-style-type: none;
		max-width: rem-calc(710);

		@include breakpoint(medium down) {
			max-width: 100%;
		}
	}

	.modal-inner-wrap {
		height: auto;
	}

	.form-login {
		width: 100%;
	}

	.shipping-address-items {

		@include breakpoint(large only) {
			justify-content: space-between;
		}

		@include breakpoint(small only) {
			justify-content: center;
		}
	}
    .billing-address-details {
        @include breakpoint(medium only) {
            margin-left: rem-calc(15px);
        }

        @include breakpoint(small only) {
            margin-left: rem-calc(15px);
        }
    }

	.billing-address-details,
	.shipping-address-item {
		border: 1px solid #d4d4d4;
		padding: rem-calc(25px);
		display: block;
		width: 100%;
		max-width: rem-calc(287px);
		@include font(14, 20, $global-weight-skinny);
		margin-right: rem-calc(30px);
		margin-bottom: rem-calc(50px);
		position: relative;
		flex-shrink: 0;

		&:first-line {
			color: $theme-checkout-billing-address-name;
			font-weight: $global-weight-semibold;
			line-height: 30px;
		}

		@include breakpoint(medium only) {
			max-width: rem-calc(270px);
		}

		@include breakpoint(small only) {
			margin-bottom: rem-calc(30px);
		}

		&:empty {
			display: none;
		}

		@include breakpoint(large only){
			margin-right: rem-calc(0px);
			max-width: rem-calc(265);
		}

		@include breakpoint(medium only) {
			max-width: rem-calc(250);
		}

		@include breakpoint(small only) {
			margin-right: 0;
			max-width: 100%;
			width: 100%;
		}

		strong {
			display: block;
			margin-bottom: rem-calc(20px);
			@include font(15, 18, $global-weight-semibold);
			letter-spacing: rem-calc(0.8px);
			color: $theme-tertiary-color;
		}

		a {
			color: $theme-tertiary-color;
			text-decoration: none;
			display: block;
			margin-top: rem-calc(20px);
		}

		.action-select-shipping-item {
			@include buttonStyle($theme-checkout-select-address-button-style);
			@include font(15, 15, $global-weight-semibold);
			bottom: 0;
			left: 50%;
			position: absolute;
			transform: translate(-50%, 50%);
			width: rem-calc(165px);
			background: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem-calc(9px 15px);
			margin-bottom: 0;
		}

		&.selected-item {
			padding-top: 50px;

			.action-select-shipping-item {
				bottom: auto;
				top:0;
				transform: none;
				left: 0;
				border-radius: 0px!important;
				background:$theme-checkout-current-address;
				color: $white;
				justify-content: flex-start;
				width: 100%;
				border-color: $theme-checkout-current-address;
			}
		}
	}

	.billing-address-details {
		border-color: $theme-checkout-billing-border-color;

		@include breakpoint(small only) {
			width: auto;
			margin-right: rem-calc(15px);
		}
	}

	.action-show-popup {
		@include buttonStyle($theme-checkout-add-address-button-style);
		margin-left: auto;
		padding-right: rem-calc(20px);
		order: -1;
		position: relative;
		cursor: pointer;

		&:before {
			content: "+";
			width: rem-calc(40);
			height: 100%;
			position: absolute;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			@include font(20, 20, $global-weight-semibold);
			pointer-events: none;

			@include internet-explorer {
				top: 0;
			}
		}

		@include breakpoint(medium) {
			top:rem-calc(-53px);
			margin-bottom: rem-calc(-40px);
		}

		@include breakpoint(small only) {
			width: 100%;
			order: 1;
			margin-bottom: rem-calc(20px);
		}
	}

	.checkout-shipping-address {

		.step-content {

			display: flex;
			align-items: flex-end;
			flex-wrap: wrap;

			@include breakpoint(large down) {
				padding: 0;
			}

			@include breakpoint(small only) {
				margin-top: 0;
				align-items: center;
				flex-direction: column;
			}

			.field.addresses {
				width: 100%;

			}
		}

		.form-login {

			.actions-toolbar {
				display: flex;
				flex-wrap: wrap;
				padding: rem-calc(0 7px);
				margin-top: rem-calc(20);

				.primary,
				.secondary {

					@include breakpoint(small only) {
						width: 100%;

						.action {
							width: 100%;
							margin-bottom: rem-calc(10);
						}
					}
				}

				.action {

					@include breakpoint(medium) {
						margin-right: rem-calc(20);
					}
				}

				.login {
					@include button-style-1;
				}

				.remind {
					@include button-style-3;
				}
			}
		}
	}

	.checkout-payment-method {

		@include breakpoint(small only) {
			margin-top: rem-calc(15);
		}

		legend {
			padding-left: 0;
			margin-left: 0;
		}
	}

	.payments {

		.step-title {

			@include font(20, 24, $global-weight-normal);
			margin-bottom: rem-calc(0);
			display: block;
			margin-top:rem-calc(22px);
		}

		.fieldset {
			margin: 0;
		}

		.billing-address-same-as-shipping-block {
            @include breakpoint(large) {
                padding-left: 0px;
            }
			margin-bottom: rem-calc(18);

			label {
				@include font(14, 17, $global-weight-normal);
				color: $theme-checkout-font-color;
			}
		}

		.discount-code {

			.payment-option-content {
				display: flex!important;
				flex-direction: column-reverse;
			}

			.messages {
				margin-bottom: 0;
				padding: 0;
				width: 100%;
				max-width: 100%;
			}
		}

		.customerbalance {
			padding: rem-calc(16);
			display: none;

			.field.choice {
				justify-content: space-between;
			}

			.actions-toolbar {
				flex-shrink: 1;
				padding-left: 0;
			}

			.action {
				@include button-style-3;
			}
		}

		.billing-address-form {

			.field.choice {
				padding: rem-calc(0 15px);
			}
		}
	}

	.payment-option.discount-code {
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);

		.payment-option-title {

			@include breakpoint(small only) {
				justify-content: flex-start;
			}

			@include breakpoint(medium down) {
				padding: 0;
			}


			.action-toggle {
				@include font(16, 19, $global-weight-skinny);
				color: $theme-checkout-font-color;
				margin-bottom: rem-calc(7px);

				@include breakpoint(small only) {
					margin-bottom: rem-calc(10px);
				}
			}
		}

		.form-discount {
			display: flex;

			@include breakpoint(small only) {
				flex-direction: column;
				align-items: flex-start;
			}

			.payment-option-inner {
				width: 100%;
				margin-right: rem-calc(30);

				@include breakpoint(small only) {
					margin-right: 0;
				}
			}

			.actions-toolbar {
				flex-shrink: 0;

				@include breakpoint(small only) {
					margin-top: rem-calc(10);
					width: 100%;
				}
			}
		}
	}

	.opc-block-shipping-information {

		.shipping-information-title {
			@include font(20, 24, $global-weight-semibold);
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: rem-calc(15);
		}

		.shipping-information-content {
			@include font(14, 23, $global-weight-skinny);

			&:first-line {
				font-weight: $global-weight-semibold;
				color: $theme-checkout-shipping-address-name;
				display: block;
				margin-bottom: rem-calc(15);
			}

			a {
				color: inherit;
				pointer-events: none;
				cursor: default;
			}
		}
	}

	.field-tooltip-content {
		@include element-invisible;
	}

	[name="shippingAddress.telephone"] {
		._with-tooltip {
			.field-tooltip {
				display: none;
			}
		}
	}
}

.checkout-index-index .checkout-container .payments > .fieldset {
	@include breakpoint(large) {
		margin-left: rem-calc(0px);
	}
}

.checkout-index-index .modal-popup.agreements-modal {

	.modal-inner-wrap .modal-footer .secondary {
		margin: 0;
	}
}

.checkout-cart-index {
	background: $white;

	.page-title-wrapper {
		margin-top: rem-calc(40);
		margin-bottom: rem-calc(50);


		.page-title {
			@include font(20, 24, $global-weight-semibold);
			letter-spacing: rem-calc(1.07);
		}

		.cart-empty {

			p {
				@include font(15, 18, $global-weight-skinny);
				margin-bottom: rem-calc(20);

				a {
					color: inherit;
					font-weight: $global-weight-semibold;

				}
			}
		}
	}

	.page-main {

		@include breakpoint(large down) {
			padding: rem-calc(0 15);
		}
	}
	.modals-wrapper .modal-inner-wrap .modal-footer {
		button {
			@media only screen  and (max-width: 391px) {
				margin: 0.5rem;
			}
		}
	}
}

.checkout-index-index {
    .recommended-products-list {
        .product {
            &-items {
                @include flex-grid-row();
            }

            &-item {
                @include flex-grid-column(12, 0);

                @include breakpoint(xlarge) {
                    padding-left: $product-margin;
                }

                @include breakpoint(xlarge down) {
                    padding-left: 0;
                }

                @include breakpoint(xlarge) {
                    @include flex-grid-column(3, 0);

                    padding-left: $product-margin;

                    &-info:hover {

                        .product-item-actions {
                            opacity: 1;
                            pointer-events: all;
                        }

                        .product-item-name {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

table.data.table.table-totals {
    tr.totals.discount {
        th.mark {
            max-width: 12ch;
            word-break: break-word;
        }
    }
}

