.swatch-option-tooltip {
	@include element-invisible;
}

$product-swatch-size: 24px;
$product-swatch-distance:  8px;
$product-item-padding: 12px;

$product-remove-icon-size: 20px;
$product-swatch-boxshadow: 0px 0px 0px 1px $white, 0 0 8px 0 rgba($theme-primary-color, 0.7);;


.swatch-opt {
	margin: 0 !important;
	position: relative;

	* {
		@include vendor(user-select, none);
	}

	.swatch-input {
		@include element-invisible;
	}

	.swatch-attribute {
		margin-bottom: rem-calc(10px);

		.mage-error {
			margin-top: rem-calc(10px);
			display: block!important;
		}

		&[option-selected] {
			
			.mage-error {
				display: none!important;
			}
		}

		.swatch-attribute-label {
			font-size: rem-calc(12);
			font-weight: $global-weight-skinny !important;
			display: block;
			margin-bottom: rem-calc(5px);
		}

		.swatch-attribute-options {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: flex-start;
			align-items: stretch;
			margin-bottom: rem-calc(-$product-swatch-distance);
			margin-top: rem-calc(0);
		}

		.swatch-attribute-selected-option {
			@include element-invisible;
		}

	}

	.swatch-option {
		@include font(12, 12, 500);
		margin: 0;
		background: $theme-body-background;
		color: $theme-primary-font-color;
		white-space: nowrap;
		padding: rem-calc(0 5px);
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		min-width: rem-calc($product-swatch-size);
		position: relative;
		margin-right: rem-calc($product-swatch-distance)!important;
		margin-bottom: rem-calc($product-swatch-distance)!important;
		min-height: rem-calc($product-swatch-size);
		cursor: pointer;
		-webkit-tap-highlight-color: transparent !important;

		&:last-child {
			margin-right: 0!important;
		}

		&.selected {
			@include vendor(box-shadow, $product-swatch-boxshadow);
			outline: 0;
			-webkit-tap-highlight-color: transparent !important;
		}

		&.color {

			&:not(.disabled):hover {
				@include vendor(box-shadow, 0 0 8px 0 rgba($theme-primary-color, 0.7));
				outline: 0 !important;
			}
		}
	}

	select {
		-webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
        background-image: none;
	}
}
