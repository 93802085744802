.toolbar {
	@include flex;

	.pages {
		@include element-invisible();
	}

	&-sorter {
		@include flex;
		width: 100%;

		label {
			margin-left: rem-calc(8);

			@include breakpoint(large) {
				margin-right: rem-calc(5);
			}
		}

		@include internet-explorer {

			.sorter-options:focus::-ms-value {
				background-color: $white;
				color: $theme-category-font-color;
			}
		}
	}

	&-subcategories {
		margin-top: $global-margin;

		@include breakpoint(medium) {
			margin-top: 0;
			margin-right: rem-calc(12);
		}

		@include breakpoint(large) {
			@include element-invisible();
		}
	}

	.sorter-action {
		background: $white;
		border: 1px solid $theme-category-sort-border-color;
		display: block;
		margin-left: rem-calc(10);

		&.sort-asc,
		&.sort-desc {
			display: block;
			height: rem-calc(40);
			width: rem-calc(48);
			position: relative;
			text-indent: -999999px;
			overflow: hidden;

			&:after {
				text-indent: 0;
			}
		}

		&.sort-asc {

			&:after {
				@include absolute-full;
				@include flex-center;
				@include ird-icon($ird-icon-arrow-down, 20px);
				color: $theme-category-sort-chevron-arr;
			}
		}

		&.sort-desc {
			padding: rem-calc(10);

			&:after {
				@include absolute-full;
				@include flex-center;
				@include ird-icon($ird-icon-arrow-up, 20px);
				color: $theme-category-sort-chevron-arr;
			}
		}
	}

	&-amount {
		@include element-invisible();
	}
}

.top-toolbar {
	margin-bottom: rem-calc(24px);

	@include breakpoint(medium) {
		@include flex;
		@include flex-align($x: right);
	}

	// @include breakpoint(large) {
	// 	margin-top: rem-calc(-65px);
	// }

	& > .toolbar {
		@include flex-align($x: right);
	}
}

.search .top-toolbar {
	@include breakpoint(large) {
		margin-top: 0;
	}
}

.bottom-toolbar {

	.toolbar-sorter {
		@include element-invisible();
	}

	.pages {
		@include element-invisible-off();
		@include flex;
		@include flex-align($x: center);
		font-size: rem-calc(14);
		margin-bottom: 0;
		width: 100%;

		&-items {
			@include flex;
			align-items: center;
			flex-wrap: nowrap;
			width: 100%;
			justify-content: center;

			.item {
				flex-shrink: 1;

				 a, strong {
					color: $theme-primary-font-color;
					font-weight: $global-weight-normal;
					padding: rem-calc(0 16);
					display: flex;
					justify-content: center;
					align-items: center;

					@include breakpoint(small only) {
						padding: rem-calc(0 10);
					}
				}

				&.current strong {
					color: $theme-category-page-highlight;
					font-weight: $global-weight-bold;
				}
			}
		}

		&-item-previous {
			margin-right: rem-calc(16);

			@include breakpoint(small only) {
				margin-right: rem-calc(10);
			}
		}

		&-item-next {
			margin-left: rem-calc(16);

			@include breakpoint(small only) {
				margin-left: rem-calc(10);
			}
		}

		&-item-previous,
		&-item-next {
			@include buttonStyle($theme-category-prev-next-buton-style);
			padding: 0;
			@include vendor(border-radius, 5px!important);

			a {
				color: inherit!important;
			}
		}
	}

	.toolbar-subcategories {
		@include element-invisible;
	}
}

.custom-sorter {
	@include flex;
	@include flex-align($y: middle);
	background: $white;
	border: 1px solid $theme-category-sort-border-color;
	width: 100%;
	min-width: rem-calc(230px);
	
	.sorter-label {
		width: 49%;
	}
}

#sorter {
	border: none;
	margin: 0;
	background-image: none;
	appearance: auto;
	-moz-appearance: auto;
	-webkit-appearance: auto;
}

.subcategories {
	background: $theme-primary-color;
	color: $white;
	padding: rem-calc(10 0);
	width: 100%;

	@include breakpoint(medium) {
		margin-left: rem-calc(10);
		padding: rem-calc(12 10);
	}
}

.bottom-toolbar .pager .limiter {
    display: none;
}
