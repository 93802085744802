
@import '../../../../node_modules/foundation-sites/scss/util/util';

// ========== TYPOGRAPHY ==========
$theme-font-family:                                 'Gotham', -apple-system, "Helvetica Neue", sans-serif !default;

// ========== COLOR ==========
$white:                                             #ffffff;

$theme-primary-color:                               #333333                                           !default;
$theme-secondary-color:                             #007dba                                           !default;
$theme-tertiary-color:                              #003865                                           !default;
$theme-accent-color:                                #db4082                                           !default;

$theme-primary-font-color:                          $theme-primary-color                                !default;
$theme-primary-alternative-font-color:              $white                                              !default;
$theme-secondary-alternative-font-color:            $white                                              !default;
$theme-tertiary-alternative-font-color:             $white                                              !default;
$theme-accent-alternative-font-color:               $white                                              !default;
$theme-price-font-color: $theme-primary-color !default;

$theme-header-background:                           $white                                              !default;
$theme-body-background:                             #f6f6f6                                           !default;
$theme-footer-background:                           $white                                              !default;
$theme-footer-color:                                $theme-primary-font-color                           !default;


// ========== CCOMPONENTS ==========

// + Buttons
    // - Primary
$theme-button-style-1-background:                   $theme-secondary-color                              !default;
$theme-button-style-1-color:                        $white                                              !default;
$theme-button-style-1-border-color:                 $theme-secondary-color                              !default;

    // - Wishlist
$theme-button-style-2-background:                   $white                                              !default;
$theme-button-style-2-color:                        $theme-secondary-color                              !default;
$theme-button-style-2-border-color:                 $white                                              !default;

    // - Outlined
$theme-button-style-3-background:                   $white                                              !default;
$theme-button-style-3-color:                        $theme-secondary-color                              !default;
$theme-button-style-3-border-color:                 $theme-secondary-color                              !default;

// + Header
// - Store settings
$theme-header-font-color:                           $theme-primary-font-color                           !default;
$theme-logo-max-width:                              100%                                                !default;

// - Icons
$theme-icon-indicator-background:                   $theme-accent-color                                 !default;
$theme-icon-indicator-color:                        $theme-accent-alternative-font-color                !default;

// - Search
$theme-search-bar-color:                            $theme-primary-font-color                           !default;
$theme-search-bar-background:                       $theme-body-background                              !default;
$theme-search-bar-slider-color:                     $theme-tertiary-color                               !default;
$theme-search-bar-slider-background:                $theme-body-background                              !default;
$theme-search-button-style:                         1                                                   !default;

// - Navigation
$theme-navigation-background:                       $theme-primary-color                                !default;
$theme-navigation-color:                            $theme-primary-alternative-font-color               !default;
$theme-navigation-shadow:                           none                                                !default;
$theme-navigation-desktop-heading-hover-background: $theme-navigation-background                        !default;
$theme-navigation-desktop-heading-hover-color:      $theme-navigation-color                             !default;
$theme-navigation-desktop-submenu-background:       $theme-navigation-background                        !default;
$theme-navigation-desktop-submenu-color:            $theme-navigation-color                             !default;
$theme-navigation-desktop-dropdown-background:      $theme-navigation-background                        !default;
$theme-navigation-desktop-item-arrow-color:         $theme-navigation-color                             !default;
$theme-navigation-mobile-heading-background:        $theme-navigation-background                        !default;

// + Carousel
$theme-carousel-color:                              $theme-primary-font-color                           !default;
$theme-carousel-dot-color:                          $theme-secondary-color                              !default;
$theme-carousel-button-style:                       1                                                   !default;
$theme-bottom-banner-button-style:                  1                                                   !default;

// + Tabs
$theme-tabs-color:                                  $theme-primary-color                                !default;
$theme-tabs-border-color:                           $theme-tertiary-color                               !default;
$theme-tabs-hihglight-color:                        $theme-tertiary-color                               !default;
$theme-tabs-hihglight-text:                         $theme-tertiary-alternative-font-color              !default;

// + Product card
$theme-card-color:                                  $theme-primary-font-color                           !default;
$theme-card-accent:                                 $theme-accent-color                                 !default;
$theme-card-accent-alternative:                     #231F20                                             !default;
$theme-card-flexpay-background:                     $theme-tertiary-color                               !default;
$theme-card-basket-style:                           1                                                   !default;
$theme-card-wishlist-style:                         2                                                   !default;

// + Minicart
$theme-minicart-font-color:                         $theme-primary-font-color                           !default;
$theme-minicart-title-border:                       rgba($theme-primary-color, 0.2)                     !default;
$theme-minicart-close-icon-color:                   $theme-primary-color                                !default;
$theme-minicart-delete-icon-color:                  $theme-primary-color                                !default;
$theme-minicart-update-button-background:           $theme-primary-color                                !default;
$theme-minicart-view-button-background:             $theme-primary-color                                !default;
$theme-minicart-checkout-button-style:              1                                                   !default;
$theme-minicart-qty-symbols:                        $theme-secondary-color                              !default;

// + Category
$theme-category-font-color:                         $theme-primary-font-color                           !default;
$theme-category-subcategory-chevron:                $theme-secondary-color                              !default;
$theme-category-sort-border-color:                  $theme-secondary-color                              !default;
$theme-category-sort-chevron-arr:                   $theme-secondary-color                              !default;
$theme-category-page-highlight:                     $theme-secondary-color                              !default;
$theme-category-prev-next-buton-style:              1                                                   !default;

$theme-category-clear-item-background:              $theme-primary-color                                !default;
$theme-category-clear-item-color:                   $theme-primary-alternative-font-color               !default;
$theme-category-clear-all-background:               $theme-primary-color                                !default;
$theme-category-clear-all-color:                    $theme-primary-alternative-font-color               !default;

// + Product page
$theme-product-font-color:                          $theme-primary-color                                !default;
$theme-product-image-border-color:                  $theme-primary-color                                !default;
$theme-product-border-color:                        $theme-primary-color                                !default;
$theme-product-stock-background:                    $theme-primary-color                                !default;
$theme-product-stock-color:                         $theme-primary-alternative-font-color               !default;
$theme-product-flexpay-indicator:                   $theme-accent-color                                 !default;
$theme-product-flexpay-slider:                      $theme-tertiary-color                               !default;
$theme-product-qty-selector:                        $theme-secondary-color                              !default;
$theme-product-basket-button-style:                 1                                                   !default;
$theme-product-wishlist-button-style:               2                                                   !default;
$theme-product-checkout-button-style:               3                                                   !default;

$theme-product-tabs-font-color:                     $theme-primary-font-color                           !default;
$theme-product-tabs-border-color:                   $theme-tertiary-color                               !default;
$theme-product-tabs-heighlight-background:          $theme-tertiary-color                               !default;
$theme-product-tabs-heighlight-color:               $theme-tertiary-alternative-font-color              !default;

// + Checkout
$theme-checkout-font-color:                         $theme-primary-color                                !default;
$theme-checkout-next-button-style:                  1                                                   !default;
$theme-checkout-cancel-button-style:                3                                                   !default;
$theme-checkout-current-address:                    $theme-accent-color                                 !default;
$theme-checkout-select-address-button-style:        3                                                   !default;
$theme-checkout-add-address-button-style:           3                                                   !default;
$theme-checkout-shipping-address-name:              $theme-tertiary-color                               !default;
$theme-checkout-flexpay-indicator:                  $theme-accent-color                                 !default;
$theme-checkout-order-summary-font-color:           $theme-primary-font-color                           !default;
$theme-checkout-modal-font-color:                   $theme-primary-font-color                           !default;
$theme-checkout-modal-title-background:             $theme-primary-color                                !default;
$theme-checkout-modal-title-color:                  $theme-primary-alternative-font-color               !default;
$theme-checkout-checkbox-background:                $theme-tertiary-color                               !default;
$theme-checkout-checkbox-color:                     $theme-tertiary-alternative-font-color              !default;
$theme-checkout-billing-border-color:               $theme-tertiary-color                               !default;
$theme-checkout-discount-button-style:              3                                                   !default;
$theme-checkout-place-order-button-style:           1                                                   !default;
$theme-checkout-billing-address-name:               $theme-tertiary-color                               !default;
$theme-checkout-update-button-style:                1                                                   !default;
$theme-checkout-print-button-style:                 3                                                   !default;
$theme-checkout-continue-shopping-button-style:     1                                                   !default;


$global-font-size: 100%;
$global-width: rem-calc(1270);
$global-limited-width: 1270px;


$foundation-palette: (
  primary: $theme-primary-color,
  secondary: $theme-secondary-color,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

$light-grey: #f6f6f6;
$error: #D02B2B;
$body-font-color: $theme-primary-font-color;
@include add-foundation-colors;
//custom theme colors

$body-font-family: $theme-font-family;
$light-text-gray: #898989;
$body-background: $theme-body-background;
$input-placeholder-color: $theme-primary-font-color;
$order-button-border-color: #979797;

// Global settings
$global-weight-skinny: 300;
$global-weight-normal: 400;
$global-weight-semibold: 500;
$global-weight-bold: 700;


//product
$product-margin: rem-calc(30);
$product-card-height: 310px;
$product-image-height: 150px;
$product-card-padding: 15px;
$product-details-height: $product-card-height - ($product-card-padding * 2) - $product-image-height;
$product-pricebox-height: $product-details-height - 20px - 40px; // 20 is distance from image to name and 40 is height of product name


// inputs
$input-height: 37px;
$input-border-width: 1px;
$input-border-color: #D4D4D4;

$input-label-size: 16;
$input-label-weight: $global-weight-normal;

$element-border: 1px solid $theme-product-border-color;
$tooltip-background-color: $white;
$tooltip-color: $theme-primary-font-color;
$tooltip-radius: 5px;
$tooltip-padding: rem-calc(16px);
$tooltip-max-width: rem-calc(220px);
$tooltip-font-size: rem-calc(12px);
$tooltip-pip-height: rem-calc(15px);
$tooltip-pip-width:rem-calc(8px);

// banner variables

$bannerTitleFontSize: rem-calc(22px);
$bannerTitleLineHeight: rem-calc(31px);
$bannerTitleWeight: $global-weight-semibold;

$bannerDescFontSize: rem-calc(20px);
$bannerDescLineHeight: rem-calc(32px);
$bannerDescWeight: $global-weight-skinny;

$bannerCtaFontSize: rem-calc(14px);
$bannerCtaLineHeight: rem-calc(17px);
$bannerCtaPadding:  rem-calc(10px 40px);
$bannerCtaWeight: $global-weight-semibold;

// price element varialbes

$pb-price-font-size: 21px;
$pb-price-special-font-size: 21px;
$pb-price-old-font-size: 14px;
$pb-price-normal-font-size: 14px;
$pb-price-line-heihgt: 25px;
$pb-price-old-line-heihgt: 23px;

$pb-old-normal-font-weight: $global-weight-skinny;
$pb-price-font-weight: $global-weight-semibold;

$pb-price-label-font-size: 0px;

// Links settings
$anchor-color: $theme-secondary-color;

// Lists settings
$list-style-type: none;
$list-side-margin: 0;

$tab-background-active: $theme-tabs-hihglight-color!important;
$tab-active-color: $theme-tabs-hihglight-text;
$tab-content-background: $theme-body-background;
$tab-content-border: none;
$accordion-content-background: $theme-body-background;
$accordion-content-border: none;
$accordion-title-font-size: rem-calc(14);
$accordion-item-color: $theme-primary-font-color;
$accordion-item-background-hover: $white;
$accordion-plusminus: false;
$accordion-plus-content: '>';
$accordion-minus-content: '>';

$offcanvas-exit-background: none;

$form-label-color: $theme-primary-font-color;
$select-triangle-color: $theme-primary-font-color;

// Table
$table-background-color: #fff;
$table-orders-head-font-color: #fff;
