//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance='collage-left'] {
    @media only screen and (min-width: 1200px) {
        &.left-aligned {
            .pagebuilder-slide-wrapper {
                .pagebuilder-overlay {
                    margin-left: calc((100vw - 79.375rem) / 2 - 30px);
                }
            }
        }

        &.right-aligned {
            .pagebuilder-slide-wrapper {
                .pagebuilder-overlay {
                    margin-right: calc((100vw - 79.375rem) / 2 - 30px);
                    margin-left: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        &.left-aligned {
            .pagebuilder-slide-wrapper {
                .pagebuilder-overlay {
                    margin-right: auto;
                }
            }
        }

        &.right-aligned {
            .pagebuilder-slide-wrapper {
                .pagebuilder-overlay {
                    margin-left: auto;
                }
            }
        }
    }
}
