$theme-print-invoice-logo-width: 300px;

[class*="sales-order-print"] {

    #offCanvasMinicart, #offCanvasNavigation {
        display: none;
    }

    .page-wrapper {

        .page-main {
            align-items: flex-start;
            background: $white;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 auto;
            align-content: flex-start;
            padding: 1rem;
            width: 21cm;
            min-height: auto!important;

            #contentarea {
                display: none;
            }

            .logo {
                max-width: $theme-print-invoice-logo-width;
                height: auto;
                font-size: 0;
                padding: 0 1rem;
                display: block;
                text-align: center;
                margin: 0 auto;
            }

            .messages {
                margin: 0;
                padding: 0;
            }

            .page-title-wrapper {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 1rem;

                .page-title {
                    @include font(14, 14, $global-weight-bold);
                }

                .order-status {
                    @include element-invisible;
                }

                .order-date {
                    @include font(12, 12, $global-weight-semibold);
                }
            }

            .columns {
                width: 100%;
                padding: 1rem;

                .column.main {
                    border-top: 1px solid #ccc;
                    padding-top: rem-calc(30)
                }
            }

            .order-details-items {

                .order-title {
                    @include font(20, 20, $global-weight-bold, normal, uppercase, $theme-font-family);
                    margin-bottom: rem-calc(30);
                }

                .actions-toolbar {
                    display: none;
                }

                caption {
                    @include font(18, 18, $global-weight-bold);

                    margin-bottom: rem-calc(30px);
                    text-align: left;
                    padding: 0;
                }

                .table-order-items {
                    margin-bottom: 0;

                    thead,
                    tbody,
                    tfoot {
                        border: none !important;
                        background: transparent;
                    }

                    thead,
                    tfoot {

                        th,
                        th * {
                            @include font(12, 12, $global-weight-semibold);
                            vertical-align: middle;
                        }
                    }

                    thead {

                        th {
                            vertical-align: top;
                            border-bottom: 2px solid #ccc;
                        }
                    }

                    tbody {

                        td,
                        td * {

                            @include font(12, 12, $global-weight-skinny);

                        }

                        td {
                            border-top: 1px solid #ccc;
                            padding-top: rem-calc(16);
                            padding-bottom: rem-calc(16);
                            vertical-align: top;
                        }
                    }

                    .col {
                        color: $theme-primary-font-color;
                    }

                    .qty {

                        .qty.summary {
                            display: block;
                            text-align: left;
                        }
                    }

                    .name,
                    .sku {
                        text-align: left;
                        word-break: break-word;
                    }

                    .name {
                        width: 25%;
                        padding-left: 0;

                        .product-item-name {
                            font-size: inherit;
                            font-weight: $global-weight-semibold;
                            line-height: inherit;
                            min-height: initial;
                            text-align: left;
                            display: inline;
                        }
                    }

                    .col:last-child {
                        padding-right: 0rem;
                    }

                    .cash {
                        white-space: nowrap;
                    }

                    tfoot {

                        th {
                            text-align: right;
                            border-top: 2px solid #ccc;
                            padding-right: 0;
                        }

                        span.mark,
                        span.amount {

                            @include font(12, 14, $global-weight-semibold);
                            text-align: right;
                            vertical-align: bottom;
                            display: inline-block;

                            * {
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                            }

                        }

                        span.mark {
                            @include breakpoint(small only) {
                                overflow-wrap: break-word;
                                max-width: 12ch;
                            }
                        }

                    }

                    .col {

                        * {

                            padding: 0;
                            margin: 0;

                        }

                    }

                    &-footer {
                        border-top: 1px solid #ccc;
                        margin-bottom: rem-calc(30px);
                        text-align: right;
                        background: transparent;

                        th {
                            text-align: ri;
                        }
                    }

                    .item-options,
                    .item.options {
                        margin-top: rem-calc(10px);

                        .item-option {
                            margin-bottom: rem-calc(5px);
                        }

                        strong,
                        span {
                            display: inline-block;
                        }

                        strong {
                            font-weight: $global-weight-semibold!important;
                            margin-right: 5px;
                            &::after {
                                content: ":";
                                display: inline-block;
                                font-weight: bold;
                            }
                        }

                    }

                    .items-qty {

                        li {
                            display: flex;
                            justify-content: normal;

                            .title {
                                margin-right: rem-calc(3px);
                            }
                        }
                    }
                }

                .actions-toolbar {

                    a {
                        font-size: 0;
                    }
                }
            }

            .block-order-details-view {
                margin-top: rem-calc(40);

                .block-title {
                    @include font(18, 18, $global-weight-bold);

                    margin-bottom: rem-calc(30px);
                    text-align: left;
                }

                .block-content {
                    flex-direction: column;
                    padding: 0;

                    .box {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: rem-calc(30px);

                        .box-title {
                            @include font(14, 14, $global-weight-bold);
                            margin-bottom: rem-calc(15px);
                            width: 100%;
                        }

                        .box-content {
                            @include font(12, 16, $global-weight-skinny);
                            @include vendor(box-shadow, none);
                            padding: 0;
                            width: 100%;

                            address {
                                font-style: normal;
                                min-height: auto;
                                font-weight: inherit;
                            }

                            br {
                                content: '';
                                width: 9px;
                                height: 18px;

                                &:before {
                                    content: ', ';
                                }
                            }
                        }
                    }

                    .box-order-billing-method,
                    .box-order-shipping-method {
                        display: none;
                    }
                }
            }

            .copyright {
                display: none;
            }
        }
    }
}

// individual documents

.sales-order-printinvoice {

    .page-wrapper {

        .page-main {

            .order-details-items {

                .table-order-items {

                    .qty {
                        text-align:  left;
                    }
                }
            }
        }
    }
}

.sales-order-printshipment {

    .page-wrapper {

        .page-main {

            .order-details-items {

                .table-order-items {

                    .name {
                        width: 50%;
                    }

                    .sku {
                        width: 35%;
                    }

                    .qty {
                        text-align:  left;
                        width: 15%;
                        @include breakpoint(small only) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.sales-order-printcreditmemo {

    .page-wrapper {

        .page-main {

            .order-details-items {

                .table-order-items {

                    .col {
                        letter-spacing: -0.3px;
                        padding: rem-calc(8px 5px);
                    }

                    .name {
                        width: 23%;
                        @include breakpoint(small only) {
                            padding-left: rem-calc(5) !important;
                        }
                    }

                    .item-option {
                        @include breakpoint(small only) {
                            padding-left: 0;
                        }
                    }

                    .sku {
                        min-width: 75px;
                    }

                    .qty {
                        text-align: left;
                        max-width: 75px;
                    }
                }
            }
        }
    }
}

@-moz-document url-prefix() {

    .sales-order-print .page-wrapper .page-main .block-order-details-view .block-content .box .box-content br {
        display: none;
    }
}

@include internet-explorer {
    .sales-order-print .page-wrapper .page-main .block-order-details-view .block-content .box .box-content br {
        display: none;
    }
}

@media screen and (max-width: rem-calc(640px)) {

    [class*="sales-order-print"] {

        .page-wrapper {

            .page-main {
                width: 100%;
                min-height: 100vh!important;

                .logo {
                    width: 100%;
                }

                .page-title-wrapper {
                    align-items: flex-start;
                    width: 100%;
                }

                .columns {
                    width: 100%;
                }

                .order-details-items {

                    .table-order-items {

                        thead,
                        tbody,
                        tfoot {
                            border: none !important;
                            background: transparent;
                        }

                        tfoot {

                            th,
                            th * {
                                @include font(12, 12, $global-weight-semibold);
                                vertical-align: middle;
                            }
                        }

                        thead {
                            display: none;
                        }

                        tbody {

                            tr {
                                display: block;
                            }

                            td {
                                display: inline-block;
                                width: 100%;
                                border: none;
                                padding-left: 0;
                                padding-bottom: 0;

                                &:first-child {
                                    border-top: 1px solid #ccc;
                                }

                                &:last-child {
                                    padding-bottom: 1rem;
                                }

                                &:before {
                                    content: attr(data-th) ":";
                                    display: inline-block;
                                    font-weight: bold;
                                    margin-right: rem-calc(5px);
                                }
                            }
                        }

                        .name {
                            width: 100%;
                            padding-left: 0;
                        }

                        .qty {

                            .qty.summary {
                                display: inline-block;
                            }

                            .items-qty {
                                display: inline-block;
                                vertical-align: top;
                            }
                        }


                        &-footer {
                            border-top: 1px solid #ccc;
                            margin-bottom: rem-calc(30);
                            text-align: right;
                            background: transparent;

                            th {
                                text-align: right;
                            }
                        }
                    }
                }

                .block-order-details-view {
                    .block-content {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media print {

    [class*="sales-order-print"] {

        .page-wrapper {

            .page main {
                padding: 1rem;
                width: 21cm;
                height: 29.7cm!important;
            }
        }

        .table-wrapper table>tfoot>tr:first-child {
            border-top: none !important;
        }

        a[href] {
            text-decoration: none;
            color: inherit;

            &:after {
                content: none !important;
            }
        }
    }
}
