.catalogsearch-result-index,
.catalog-category-view {

    .widget-product-carousel {
        &.slick-slider {
            @include breakpoint(large down) {
                padding-bottom: 2rem;
            }
        }
    }


	.product {

		&-items {
			@include flex-grid-row(nest);

			@include breakpoint(large only) {
				@include negative-margin(10);
			}

			@include breakpoint(medium only) {
				@include negative-margin(5);
			}

			@include breakpoint(small only) {
				margin: 0;
			}
		}

		&-item {
			@include flex-grid-column();
			@include flex-grid-size(12);

			@include breakpoint(medium) {
				@include flex-grid-column(6);
			}

			@include breakpoint(large) {
				@include flex-grid-size(4);
			}

			@include breakpoint(xlarge) {
				padding-left: $global-margin;

				&-info:hover {

					.product-item-actions {
						opacity: 1;
					}

					.product-item-name {
						opacity: 0;
					}
				}
			}

			@include breakpoint(large only) {
				padding-left: rem-calc(10px);
				padding-right: rem-calc(10px);
				margin-bottom: rem-calc(20px);
			}

			@include breakpoint(medium only) {
				padding-left: rem-calc(5px);
				padding-right: rem-calc(5px);
				margin-bottom: rem-calc(10px);
			}

			@include breakpoint(small only) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: rem-calc(10px);
			}
		}
	}
}
