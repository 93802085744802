
body.global-nav-active {
  .authorization-link {
    .list-item-link:before {
      filter: invert(100%);
    }
  }

  @include breakpoint(large down) {
    .global-nav-list {
      .authorization-link.list-item {
        .list-item-link.login-button {
          color: $theme-primary-color !important;
        }
      }

      .global-nav-list {
        .list-item {
          .list-item-link.login-button {
            color: $theme-primary-color !important;
          }
        }
      }
    }

    .navigation-off-canvas.nav-sections {
      .navigation {
        background: $white !important;
      }
    }

    .mobile-options {
      .global-nav-list {
        .list-item {
          border-bottom: rem-calc(1px) solid rgba($theme-navigation-color, 0.2) !important;
        }
      }
    }

    .navigation-off-canvas.nav-sections {
      .list-item {
        &:not(.active):not(.highlight) {

          &:hover {
            background: $white;

            a.list-item-link {
              color: $theme-primary-color;
            }
          }
        }
      }

      .navigation {
        li.level0.level-top {
          ul.submenu, .fullwidth-wrapper.ui-menu-item {
            background: $white !important;
          }

          .fullwidth-wrapper {
            background: $white !important;
          }
        }
      }
    }
  }
}
