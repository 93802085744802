$tooltipShadow: rgba(0,0,0,0.14);

$tooltipShadowProps: 0px 0px 9px 0px $tooltipShadow;
$tooltipShadowPropsRight: -5px 3px 9px 0px $tooltipShadow,  0px 0px 1px 0px $tooltipShadow;
$tooltipShadowPropsLeft: 5px 3px 9px 0px $tooltipShadow, 0px 0px 1px 0px $tooltipShadow;
$tooltipShadowPropsTop: 3px -5px 9px 0px $tooltipShadow,  0px 0px 1px 0px $tooltipShadow;
$tooltipShadowPropsBottom: 3px 5px 9px 0px $tooltipShadow, 0px 0px 1px 0px $tooltipShadow;

.tooltip {
    @include font(12, 14, $global-weight-skinny);
    @include vendor(box-shadow, $tooltipShadowProps);
    letter-spacing: rem-calc(-0.2px);

    &.right {
        @include vendor(box-shadow, $tooltipShadowPropsRight);
    }

    &.left {
        @include vendor(box-shadow, $tooltipShadowPropsLeft);
    }

    &.top {
        @include vendor(box-shadow, $tooltipShadowPropsTop);
    }

    &.bottom {
        @include vendor(box-shadow, $tooltipShadowPropsBottom);
    }

    &.right, &.left {

        &:before {
            top:50%;
            transform: translateY(-50%);
        }
    }

    &-round {
        @include border-rounded;
        background: $theme-product-flexpay-indicator;
        color: $white;
        height: rem-calc(20);
        width: rem-calc(20);
        display: inline-block!important;
    }
}
