.mobile-options {
	margin-top: $global-margin;
	padding: 0 $global-padding;

	.switcher {
		margin-bottom: rem-calc(10);

		button {
			font-weight: $global-weight-bold;
		}
    }
    
    .auth-block {
        display: none;
    }

    &-content {

        @include flex;
        @include flex-align($x: center, $y: middle);
        @include flex-direction(column);

        .auth-block {

            @include flex;
            width: 100%;

            @include breakpoint(medium) {
                width: 50%;
            }

            button {
                margin-bottom: 0;
                padding: rem-calc(8 0);
                text-align: center;
            }
        }

        button {

            @include disable-mouse-outline;
            color: $theme-primary-color;
            font-size: rem-calc(14);
            line-height: rem-calc(16);
            text-align: left;
            width: 100%;

            &:focus {
                outline-style: none;
            }

            span {
                margin-left: 0;
            }
        }
    }
}
