.minicart-off-canvas {
    max-width: rem-calc(405);
    width: 100%;

    .minicart-items-wrapper {

        height: 100%!important;
        left: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 1rem;
        position: absolute;
        top:0;
        width: calc(100% + 20px);
        padding-right: rem-calc(36);

        &::-webkit-scrollbar {
            display: none;
        }
        
        .product-item-details {
            .options {

                .toggle span {
                    display: flex;

                    &:after {
                        position: static;
                    }
                }
            }
        }
    }
}


.minicart {

    &-content-wrapper {
        @include flex;
        flex-flow: column;
        height: 96vh;

        @include breakpoint(xlarge) {
            height: 100vh;
        }

        @include breakpoint (small only) {
            height: 100vh;
        }
    }

    &-title {

        @include flex;
        border-bottom: 2px solid $theme-minicart-title-border;
        color: $theme-minicart-font-color;
        font-size: rem-calc(20);
        font-weight: $global-weight-skinny;
        letter-spacing: rem-calc(1.07);
        line-height: rem-calc(24);
        padding: $global-padding;

        h3 {
            font-size: rem-calc(20);
            font-weight: $global-weight-normal;
            margin-bottom: 0;
        }

        .items-total {

            font-size: rem-calc(20);
            font-weight: $global-weight-skinny;
            line-height: 1.3;
            margin-left: rem-calc(5);

            .count {
                margin-right: 0;
            }

            span {
                margin-right: rem-calc(-6);
            }
        }
    }

    &-content {
        @include flex;
        height: 99%;
        padding: $global-padding;
        width: 100%;
        position: relative;
        overflow: hidden;

        @include breakpoint(medium only) {
            height: 77%;
        }

        @include breakpoint(small down) {
            height: 62%;
        }

        .product-item {
            @include flex;
            border-bottom: 1px solid darken($white, 10%);
            flex: 1 1 auto!important;
            flex-flow: column;
            padding-left: 0!important;
            max-width: 100%!important;

            &:hover {
                .product-item-name {
                    opacity: 1 !important;
                }

                .product-item-details {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &-name {
                @include flex-align($x: left);
                color: $theme-minicart-font-color;
                font-size: rem-calc(18);
                font-weight: $global-weight-semibold;
                line-height: rem-calc(18);
                margin-bottom: $global-margin;
                min-height: auto;
                height: auto;
                text-align: left;

                a {
                    color: $theme-minicart-font-color!important;
                    font-weight: $global-weight-semibold;
                    display: block;
                    pointer-events: all;
                }
            }

            &-photo {
                @include flex;
                @include flex-align($x: center);
            }

            &-subinfo {
                @include flex;
                @include flex-align($x: justify, $y: middle);
                color: darken($white, 30%);
                font-size: rem-calc(15);
                font-weight: $global-weight-semibold;
                line-height: rem-calc(18);
            }

            &-details {
                margin-bottom: $global-margin;

                a.delete {
                    height: rem-calc(24);
                    width: rem-calc(24);
                    position: relative;
                    display: block;
                    margin-right: rem-calc(75px);
                    
                    &:after {
                        @include flex-center;
                        @include ird-icon($ird-icon-delete);
                        position: absolute;
                        top:0; 
                        left: 0;
                        width: 100%;
                        height: 100%;
                        font-size: rem-calc(20px);
                        color: $theme-minicart-delete-icon-color;
                    }

                    span {
                        font-size: 0;
                    }
                }

                .delete[disabled] {
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }

            .details-qty {
                @include flex;
                @include flex-align($x: justify, $y: middle);

                .update-cart-item {
                    @include border-rounded;
                    background: $theme-minicart-update-button-background;
                    color: $theme-primary-alternative-font-color;
                    cursor: pointer;
                    font-size: rem-calc(12);
                    padding: rem-calc(6 12);
                }

                .control {
                    display: flex;
                    align-items: center;

                    input[type="number"] {
                        text-align: center;
                        width: rem-calc(40px);
                        height: rem-calc(40px);
                        -moz-appearance: textfield;
                    }

                    input[type="number"]::-webkit-outer-spin-button, 
                    input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    .iredeem-icon {
                        color: $theme-minicart-qty-symbols;
                        cursor: pointer;
                        padding: rem-calc(5px);
                    }

                    .decrease {
                        margin-right: rem-calc(5px);
                    }

                    .increase {
                        margin-left: rem-calc(5px);
                    }
                }
            }

            .item-qty {
                height: auto;
                margin-bottom: 0;
                text-align: center;
                padding: rem-calc(4 6);
                width: rem-calc(32);
            }
        }
    }

    &-footer {
        @include flex;
        background: $white;
        flex-flow: column;
        padding: $global-padding;
        z-index: 999;

        .viewcart {
            @include flex;
            @include flex-align($x: center);
            @include border-rounded;
            background: $theme-minicart-view-button-background;
            color: $white;
            font-size: rem-calc(15);
            margin-bottom: $global-margin;
            padding: rem-calc(6 10);
            width: 100%;
        }

        .checkout {
            @include buttonStyle($theme-minicart-checkout-button-style);
            font-size: rem-calc(15);
            padding: rem-calc(10);
            width: 100%;

            @include breakpoint(small only) {
                margin-bottom: rem-calc(10);
            }
        }

        .subtotal {
            @include flex;
            @include flex-align($x: justify, $y: middle);
            color: $theme-minicart-font-color;
            font-size: rem-calc(18);
            margin-bottom: $global-margin * 2;
            text-align: right;

            .label {
                text-transform: uppercase;
                font-weight: $global-weight-semibold;
                text-align: left;
            }

            .price-container {
                font-weight: $global-weight-semibold;
            }
        }
    }

    &-items {
        @include flex;
        flex-direction: column;
        margin-bottom: 0;

        .product-item:last-of-type {
            margin-bottom: 0;
        }

        &-wrapper {
            height: 68vh!important;
            overflow-y: auto;
            width: 100%;
        }
    }
}

@-moz-document url-prefix() {

    .minicart-items-wrapper {
        @include breakpoint(large){
            padding-right: 1rem;
        }
    }
}
