.page-header {
    background: $theme-header-background;
    width: 100%;

	.panel.wrapper {
		border-bottom: 1px solid $light-gray;
    }


    .header {

        &-top-nav,
        &-middle {
            @include flex-grid-row();
            @include flex-align($x: justify, $y: middle);
        }

		&-logo {

			@include breakpoint(small) {
				width: 60%;
			}

			@include breakpoint(medium) {
                width: 100%;
                max-width: rem-calc(350px);
            }

            @include breakpoint(large) {
                max-width: rem-calc(442px);
            }

            img {
                max-width: $theme-logo-max-width;
            }
		}

        &-top-nav {
            @include flex-grid-row(collapse);
			padding: rem-calc(10 0);

            &_left {
                @include flex;
                @include flex-grid-column(12);
                align-items: center;
                color: $theme-header-font-color;
                font-family: $theme-font-family;
                font-size: rem-calc(12);
                font-weight: $global-weight-semibold;
                line-height: rem-calc(16);

                @include breakpoint(large down) {
                    display: none;
                }

                @include breakpoint(xlarge) {

                    @include element-invisible-off;
                    @include flex-grid-column(6);
                }

                @include breakpoint(medium down) {
                    display: none;
                }
            }

            &_right {
                @include flex;
                @include flex-grid-column(12);
                @include flex-align($x: right, $y: middle);
                font-size: rem-calc(14);

                @include breakpoint(xlarge) {
                    @include flex;
                    @include flex-grid-column(6);
                    @include flex-align($x:right, $y: middle);
                }
            }

            .greet {

                .mobile-separator {
                    @include element-invisible;

                    @include breakpoint(medium) {
                        @include element-invisible-off;
                    }
                }

                .tablet-separator {
                    @include element-invisible;

                    @include breakpoint(xlarge) {
                        @include element-invisible-off;
                    }
                }
            }

            .right-block {
                @include flex;
                @include flex-align($y:middle);

                .authorization-link {
                    @include element-invisible;

                    @include breakpoint(xlarge) {
                        @include element-invisible-off;
                    }

					a {
                        font-weight: 500;
						color: $theme-header-font-color;
					}
                }
            }

            .hamburger-menu {
                @include hamburger($color: $theme-header-font-color, $width: rem-calc(24));
                margin-left: calc($global-margin / 2);
                text-indent: rem-calc(-9999);

                @include breakpoint(xlarge) {
                    @include element-invisible;
                }

				&.nav-toggle {
					@include breakpoint(xlarge) {
						display: none;
					}
				}
            }
        }

        &-middle {

			&-wrapper {
				border-bottom: 1px solid $light-gray;
				padding: rem-calc(12 0);
			}
            padding: 0 $global-margin;
        }

        &-right {
            @include flex;
        }

        &-user-links {
            @include flex;
            margin-bottom: 0;

            .account-link,
            .wishlist-link {
                height: rem-calc(36);
                width: rem-calc(36);
                margin-right: rem-calc(24);
                background-size: rem-calc(36 36);
                background-position: center center;
                background-repeat: no-repeat;
                font-size: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                @include breakpoint(small only) {
                    margin-right: rem-calc(8px);
                }
            }

            .account-link {
                &:before {
                    @include ird-icon($ird-icon-account);
                    color: $theme-primary-font-color;
                    font-size: rem-calc(28px);
                }
            }

            .wishlist-link {
                &:before {
                    @include ird-icon($ird-icon-heart);
                    color: $theme-primary-font-color;
                    font-size: rem-calc(28px);
                }

                .counter {
                    @include flex;
                    @include flex-align($x:center);
                    @include border-rounded;
                    background: $theme-icon-indicator-background;
                    color: $theme-icon-indicator-color;
                    font-size: rem-calc(12);
                    font-weight: $global-weight-bold;
                    height: rem-calc(20);
                    line-height: rem-calc(20);
                    width: rem-calc(20);
                    text-indent: 0;
                    position: absolute;
                    right: rem-calc(-7px);
                    top: rem-calc(-1px);
                }
            }
        }

         .showcart {
            @include flex;
            height: rem-calc(36);
            width: rem-calc(36);
            font-size: 0;
            justify-content: center;
            align-items: center;
            position: relative;

            &:before {
                @include ird-icon($ird-icon-basket);
                color: $theme-primary-font-color;
                font-size: rem-calc(28px);
            }

            .text {
                display: block;
            }

            .counter {
                position: absolute;
                right: rem-calc(-7px);
                top: rem-calc(-1px);
            }

            .counter-number {
                @include flex;
                @include flex-align($x:center);
                @include border-rounded;
                background: $theme-icon-indicator-background;
                color: $theme-icon-indicator-color;
                font-size: rem-calc(12);
                font-weight: $global-weight-bold;
                height: rem-calc(20);
                line-height: rem-calc(20);
                width: rem-calc(20);
                text-indent: 0;
            }
        }

        &.links {
            font-size: rem-calc(14);
            margin-bottom: 0;
        }
    }

    .skip {
        @include element-invisible();
    }
}

.header-options {

    .switcher {
        position: relative;
        margin-right: rem-calc(15px);

        .actions.dropdown {
            position: relative;
            display: flex;
            align-items: center;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @include breakpoint(large down) {
                    width: 100%;
                    padding: 0;
                }

                &:after {
                    @include ird-icon($ird-icon-chevron-down, 9px);
                    @include flex-center;
                    color: $theme-header-font-color;
                    margin-left: rem-calc(5);
                    height: rem-calc(12);
                    width: rem-calc(12);
                    transition: all 0.15s ease;

                    @include breakpoint(large down) {
                        color: $white;
                    }
                }

                span {
                    @include font(12, 17, $global-weight-semibold);
                    cursor: pointer;
                }

                &.hover {
                    &:after {
                        transform: rotate(-180deg);
                    }
                }
            }

            #switcher-store-trigger {

                @include breakpoint(large down) {

                    img {
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: translateY(0%);
                        margin-right: rem-calc(5px);
                    }
                }
            }
        }

        &.switcher-store {
            ul li a {
                display: flex;
                align-items: center;
            }
        }

        &.is-empty {

            .actions.dropdown {

                button{
                    cursor: pointer;
                    pointer-events: none;

                    &:after {
                        @include element-invisible;
                    }
                }
            }
        }
    }

    .dropdown-pane {
        background: $white;
        width: auto;
        left: 0!important;
        top: 100%!important;
        margin-top: rem-calc(10px);
        opacity: 0;
        display: block;
        pointer-events: none;
        visibility: initial;
        transform-origin: top left;
        padding: 0;
        border: none;

        @include breakpoint(xlarge) {
            animation: dropdown-slide-fade-down 0.15s ease-out forwards;
        }

        @include breakpoint(large down) {
            animation: dropdown-slide-fade-down-mobile 0.15s ease-out forwards;
        }

        ul {
            border: 1px solid #cacaca;
        }

        .switcher-option {
            overflow: hidden;


            &:first-child a {
                padding-top: rem-calc(16px);
            }

            &:last-child a {
                padding-bottom: rem-calc(16px);
            }

            a {
                @include font(12, 12, $global-weight-semibold);

                color: $theme-header-font-color;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: nowrap;
                position: relative;
                padding: rem-calc(8px 16px 8px);

                span {
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    display: block;
                }
            }
        }

        ul {
            margin-bottom: 0;
        }

        &.is-open {
            pointer-events: all;

            @include breakpoint(xlarge) {
                animation: dropdown-slide-fade-up 0.15s ease-out forwards;
            }

            @include breakpoint(large down) {
                animation: dropdown-slide-fade-up-mobile 0.15s ease-out forwards;
            }
        }
    }
}

.header-top-nav_right {

    .welcome {
        margin-right: rem-calc(10);

        > span {
            @include font(14, 19, $global-weight-skinny);
            color: $theme-header-font-color;

            span {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }

            .firstname, .points, .tier {
                font-weight: $global-weight-bold;
            }
        }
    }
}
