.bundle-actions {
  .action.primary.customize {
    width: auto;
    font-size: rem-calc(12);
    margin-top: rem-calc(10);
    padding: rem-calc(12 13);
    @include buttonStyle($theme-product-basket-button-style);
  }
}

.bundle-options-container {
  background: $white;
  padding: rem-calc(24 0);
  padding-bottom: rem-calc(36);
}

.bundle-options-container form {
  @include flex-grid-row();

  @include breakpoint(large) {
    flex-flow: nowrap;
  }


  .bundle-options-wrapper {
    @include flex-grid-column;
    @include flex-grid-size(12);

    @include breakpoint(large) {
      @include flex-grid-size(6);
    }
  }

  .block-bundle-summary {
    @include flex-grid-column;
    @include flex-grid-size(12);

    @include breakpoint(large) {
      @include flex-grid-size(6);
    }

    @include breakpoint(xlarge) {
      @include flex-grid-size(5);
    }
  }
}

.bundle-options-container {
  .fieldset {
    border: 0;
    margin: 0;
    padding: $global-margin 0;
  }

  .legend.title {
    font-size: 1.5rem;
    border: 0;
    padding: 0;
  }

}

.bundle-options-wrapper {
  float: left;
  width: 57%;

  .back span {
    font-size: small;
    color: $theme-button-style-1-background;
    font-weight: $global-weight-semibold;
    padding-bottom: 0.5rem;
  }

  .option {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  p.required {
    font-size: small;
    color: $error;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .field {
    @include flex-align($y: middle);
    flex-flow: row;

    input[type='number'] {
      margin: rem-calc(0 0);
      text-align: center;
      width: rem-calc(40);
      padding: 0;
    }
  }

  .fieldset-bundle-options .label span {
    font-weight: $global-weight-semibold;
  }

  select {
    color: $theme-primary-color;
    font-size: medium;
    height: auto;
  }
}

.block-bundle-summary {
  margin-left: auto;
  margin-right: 0;
  width: 40%;
  position: initial;

  hr {
    border-bottom: 1px solid $theme-primary-color;
  }

  .title {
    font-size: 1rem;
    border: 0;
    padding: 0;
  }

  .box-tocart {

    .actions {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.5rem;
    }

    .tocart {
      @include buttonStyle($theme-product-basket-button-style);
      font-size: rem-calc(12);
      margin-top: rem-calc(10);
      padding: rem-calc(12 13);
      width: 100%;

      @include breakpoint(large) {
        margin: rem-calc(0 8);
        width: auto;
      }
    }

    .tocheckout {
      @include buttonStyle($theme-product-checkout-button-style);
      cursor: pointer;
      font-size: rem-calc(12);
      margin-top: $global-margin;
      padding: rem-calc(12 13);
      width: 100%;

      @include breakpoint(large) {
        margin: rem-calc(0 8);
        width: auto;
      }
    }

    .field {

      @include flex-align($y: middle);
      flex-flow: row;

      input[type='number'] {
        margin: rem-calc(0 8);
        text-align: center;
        width: rem-calc(40);
        padding: 0;
      }

      #qty-error {
        @include element-invisible;
      }

      .label {
        font-size: rem-calc(13);
        font-weight: $global-weight-skinny;
        margin-right: rem-calc(8);
      }

      .control {
        @include flex;
        @include flex-align($y: middle);

        input[type='number'] {
          -moz-appearance: textfield;
        }

        /* Webkit browsers like Safari and Chrome */
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .increase,
      .decrease {
        color: $theme-product-qty-selector;
        cursor: pointer;

        img {
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
    }

    .stock {
      @include flex;
      @include flex-align($y: middle);

      background: $theme-product-stock-background;
      color: $theme-product-stock-color;
      font-size: rem-calc(12);
      padding: rem-calc(3 8);
    }
  }


  .price-box .price-container .price-wrapper .price {
    text-align: left;
    padding-bottom: 2rem;
  }
}
