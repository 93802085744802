.amgdprcookie-modal-template {
    @include breakpoint(small down) {
        min-width: 100% !important;
    }
    .action-close {
        display: none;
    }
}

.amgdprcookie-groups-container {
    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    line-height: $global-lineheight;
}

.amgdprcookie-text-container p {
    font-size: rem-calc(16px);
}

.amgdprcookie-cookie-container {
    .amgdprcookie-text {
        font-size: rem-calc(14px) !important;
        word-break: break-word;
    }
    .amgdprcookie-options {
        margin-top: 0px !important;
        color: $anchor-color !important;
    }
    .amgdprcookie-toggle-cookie {
        .amgdprcookie-input:checked:not([disabled])+.amgdprcookie-label {
            background: $theme-secondary-color !important;
        }
    }
    &.-modal p {
        padding-left: rem-calc(25px);
        font-size: rem-calc(14px);
    }
}

.amgdprcookie-bar-container {
    .amgdprcookie-policy {
        font-size: rem-calc(16px);
        @include breakpoint(medium down) {
            margin-bottom: 0px !important;
        }
    }
    .action-close {
        display: none;
    }
}

.amgdprcookie-buttons-block,
.amgdprcookie-groups-modal {
    button {
        @include border-rounded;
        @include font(14, 17, $global-weight-semibold);
        text-transform: initial !important;
    }
}

.amgdprcookie-buttons-block {
    button {
        cursor: pointer;
    }

    &.-settings {
        padding-top: 30px;
        .amgdprcookie-done {
            padding: rem-calc(9px 40px);
        }
    }

    .amgdprcookie-button:focus {
        box-shadow: none!important;
    }
    .amgdprcookie-button.-decline {
        border: 1px solid $theme-secondary-color;
    }
}

.amgdprcookie-groups-modal {
    &.-table {
        .modal-inner-wrap {
            @include breakpoint(medium down) {
                min-width: 100% !important;
            }
            @include breakpoint(small down) {
                margin-top: 1rem;
            }
        }
    }
    .amgdprcookie-table {
        th.amgdprcookie-title {
            @include breakpoint(small down) {
                font-size: rem-calc(11px);
                font-weight: 500;
            }
        }
    }
    .amgdprcookie-done {
        background-color: $theme-secondary-color !important;
        cursor: pointer;

        &:hover {
            background: darken($theme-secondary-color, 5%) !important;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
    .modal-content > div {
        max-height: 40rem;
    }
    .modal-footer {
        @include breakpoint(small down) {
            margin-top: 3.2rem;
            z-index: 9;
            position: relative;
            top: 50px;
        }
    }
}

@include breakpoint(medium down) {
    .amgdprcookie-groups-container {
        height: auto !important;
    }
    .amgdprcookie-policy-container {
        position: relative !important;
    }
    .amgdprcookie-row .amgdprcookie-cell:nth-child(2) {
        overflow-wrap: break-word;
    }
    .amgdprcookie-row .amgdprcookie-cell.desc {
        overflow-wrap: break-word;
    }
}

.amgdprjs-bar-template {
    position: fixed !important;
    width: 100%;
    &.-popup {
        height: 0;
        @include breakpoint(xlarge) {
            top: 30% !important;
        }
    }
    @include breakpoint(medium down) {
        &.-popup {
            top: 5% !important;
            .amgdprcookie-bar-container {
                padding: 20px !important;
            }
        }
    }
}

.amgdprcookie-form-container {
    .amgdprcookie-cookie-container {
        margin: 0 !important;
    }
}
