// General page layout (applicable to all layouts)

.cookie-status-message {
    display: none;
}

.pagebuilder-column-line {
    @include breakpoint(medium down) {
        flex-wrap: wrap;
    }
}

.page-wrapper {
    position: relative;
    z-index: 0;
    height: 100%;
}

.page-main {
    @include internet-explorer  {
        display: block;
    }

    .main {
        overflow: visible;
    }

    .page-title-wrapper {
        @include breakpoint(medium) {
            padding: rem-calc(15);
        }

        @include breakpoint(small only) {
            padding: rem-calc(15px 10px);
        }
    }
}

/* Mobile (style-m.css) */
@media only screen and (max-width: 768px) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

/* Desktop (style-l.css) */
@media only screen and (min-width: 768px) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}
