.navigation-off-canvas {
	.navigation {

		> ul {

			.megamenu {
				position: static;

				a.ui-state-active.level-top,
				a.ui-state-focus.level-top {
					background: $theme-navigation-mobile-heading-background;
				//	opened nav menu item background color
				}
			}
		}
	}

	.nav-toggle {
		display: block;
	}

	li.level-top>a.level-top {

		span.ui-menu-icon.ui-icon {
			display: block;
			height: 100%;
			margin-right: 0;
			transition: all 0.15s linear;
			width: rem-calc(40px);
			position: absolute;
			right: 0;
			top: 0;
			z-index: 100;

			&:after {
				@include flex-center;
				@include absolute-full;
				@include ird-icon($ird-icon-chevron-down);
				color: $theme-navigation-color;
			}
		}

		&.ui-state-active {

			span.ui-menu-icon.ui-icon {
				transform: rotate(-180deg);
			}
		}
		
		&.ui-state-focus {
			.ui-icon-carat-1-e {
				&.opened {
					transform: rotate(-180deg);
				}
			}
		}
	}

	.level1 a span.ui-menu-icon.ui-icon {
		transform: rotate(-180deg);
	}

	.level1.has-active .level2.active>a {
		font-weight: $global-weight-bold;
		@include vendor(box-shadow, none);
	}

	.navigation {

		.level0 {

			.submenu {
				border: none;

				.columns-group {

					.submenu {

						a {
							&:before {
								left: rem-calc(15px);
							}

							&:hover {

								&:before {
									left: rem-clac(20px);
								}
							}
						}
					}
				}
			}
		}

		.megamenu.level-top-fullwidth {

			.level0.fullwidth {

				.fullwidth-wrapper {
					position: relative;
					padding: 0;
					width: 100% !important;

					.fullwidth-wrapper-inner {
						flex-wrap: wrap;
						width: 100% !important;

						.columns-group {
							width: 100% !important;
							padding: rem-calc(0);
							position: relative;
						}
					}
				}
			}
		}
	}

	.fullwidth-wrapper {
		width: rem-calc(375) !important;
	}

	.fullwidth-wrapper-inner {
		width: rem-calc(360) !important;
	}

	.header-options {

		.switcher {

			.actions.dropdown button span {
				color: $theme-navigation-color;
			}
		}
	}

	.nav-sections-items {
		position: relative;
		z-index: 1;

		&:before,
		&:after {
			content: '';
			display: table;
		}

		&:after {
			clear: both;
		}
	}

	&.nav-sections {
		background: $theme-navigation-background;
		height: 100%;
		overflow: auto;

		.offCanvas-header {
			padding: 0;

			.offCanvas-title {
				color: $theme-navigation-color;
				padding: rem-calc(15px 0px 15px 15px);
			}

			.close-button {
				user-select: none;
				-webkit-touch-callout: none;
				width: rem-calc(43px);
				right: 0;
			}
		}

		.close-button {
			color: $theme-navigation-color;
		}

		.header.links a,
		.header.links a:hover {
			color: $theme-navigation-background;
			text-decoration: none;
			display: block;
			font-weight: $global-weight-bold;
			padding: 0.8rem 20px;
		}

		.header.links .header.links {
			border: 0;
		}

		.mobile-options {
			.authorization-link {
				a.login-button {
					background-color: $white;
				}
			}
		}

		.nav-before-open {
			height: 100%;
			overflow-x: hidden;
			width: 100%;
		}

		.navigation {
			box-sizing: border-box;
			padding: 0;

			.parent .level-top {
				position: relative;
				display: block;
				text-decoration: none;
			}

			[role="menuitem"] {
				-webkit-tap-highlight-color: transparent !important;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					margin: 0;

					a {
						display: block;
						padding-top: 10px;
						padding-right: 0;
						padding-bottom: 10px;
						padding-left: 15px;
						-webkit-tap-highlight-color: rgba($theme-navigation-color, 0.12);
						@include vendor(user-select, none);
						-webkit-touch-callout: none;
					}
				}
			}

			a,
			a:hover {
				color: $theme-navigation-color;
				text-decoration: none;
			}

			.level0 {
				border-top: 1px solid #d1d1d1;
				font-size: $global-font-size;

				.submenu {
					@include vendor(box-shadow, unset);
				}
			}

			.level0>.level-top {
				font-weight: $global-weight-bold;
				padding: 8px 40px 8px 15px;
				text-transform: uppercase;
				word-wrap: break-word;
				border-bottom: initial;
			}

			.level0.active .all-category .ui-state-focus {
				color: $theme-navigation-color;
				display: inline-block;
				//@include vendor(box-shadow, inset 6px 0px 0px 0px $theme-navigation-color);
			}

			.level0>.level1 {
				font-weight: 600;
			}

			.level0.active>a:not(.ui-state-active),
			.level0.has-active>a:not(.ui-state-active) {
				color: $theme-navigation-color;
				//@include vendor(box-shadow, inset 6px 0px 0px 0px $theme-navigation-color);
			}

			li.level0:last-child {
				border-bottom: 1px solid #d1d1d1;
			}

			.submenu {

				>li {
					word-wrap: break-word;
				}

				&:not(:first-child) {
					font-weight: $global-weight-normal;
					line-height: 1.3;
					left: auto !important;
					overflow-x: hidden;
					padding: 0;
					position: relative;
					top: auto !important;
					transition: left 0.3s ease-out;

					>li:last-child {
						margin-bottom: 0;
					}

					ul {
						display: block;
						padding-left: 0;

						>li {
							margin: 0;
							width: 100%;
							position: relative;
						}
					}
				}
			}

			.submenu:not(:first-child) ul>li a {
				color: $theme-navigation-desktop-submenu-color;// $theme-navigation-color;
				// submenu item font color
				line-height: normal;
				padding: rem-calc(5px 0 5px 25px);
			}

			.submenu:not(:first-child) {

				ul {

					>li {

						>a {
							padding: 0;
							position: relative;
							padding-left: rem-calc(15px);

							>span:last-child {
								padding: rem-calc(10px 0 10px 15px);
								display: block;
								width: 100%;
								margin-bottom: 0;
							}
						}
					}

					li.level1 {
						// submenu headings background color (2nd child)
						background: $theme-navigation-mobile-heading-background;

						>a {
							padding-left: 0;
						}
					}
				}

				&.expanded {
					padding-right: 0;
					top: 0 !important;
					display: block !important;
					width: 100%;
					margin-bottom: 8px !important;
				}

				.level1.active>a,
				.level1.has-active>a {
					color: $theme-navigation-color;
					//@include vendor(box-shadow, inset 6px 0px 0px 0px $theme-navigation-color);
				}

				.level2.active>a {

					&:before {
						left: rem-calc(25px);
					}

					span {
						font-weight: $global-weight-semibold;
					}
				}
			}
		}

		.switcher {
			font-size: 1rem;
			font-weight: $global-weight-bold;
			padding: .8rem;
			margin-right: 0;
			border-color: $theme-navigation-color;

			.switcher-options {
				justify-content: center;
				width: 100%;
				flex-wrap: wrap;

				.button {
					padding: 0;
					width: 100%;
					user-select: none;
					-webkit-touch-callout: none;
					-webkit-tap-highlight-color: transparent;

					* {
						user-select: none;
						-webkit-touch-callout: none;
						color: $white;
						@include font(12, 17, $global-weight-semibold);
						cursor: pointer;
					}
				}
			}

			.label {
				display: block;
				margin-bottom: 5px;
			}
		}

		.switcher-trigger strong {
			position: relative;
			display: block;
			text-decoration: none;

			&:after {
				position: absolute;
				right: -40px;
				top: -16px;
			}
		}

		.switcher-dropdown {
			left: auto !important;
			transform: translateX(0);
			width: 100%;
			margin: 0;
			position: relative !important;
			padding: 0;
			animation: none;
			height: 0;

			&.is-open {
				padding: 0;
				margin-top: 13px;
				opacity: 1;
				height: auto;
				animation: none;
			}
		}

		.header.links {
			margin: 0;
			padding: 0;
			list-style: none none;

			li {
				margin: 0;
				font-size: $global-font-size;

				&.greet.welcome {
					border-top: 1px solid #d1d1d1;
					font-weight: $global-weight-bold;
					padding: 0.8rem 20px;
				}

				>a {
					border-top: 1px solid #d1d1d1;
				}
			}
		}

		.switcher-trigger strong:after {
			font-size: 1.6rem;
			right: -25px;
			top: -2px;
		}

		.section-item-content .switcher-store .switcher-options {

			.switcher-dropdown {
				display: none;
			}

			&.active .switcher-dropdown {
				display: block;
			}
		}

		.nav-sections-item-title a.nav-sections-item-switch {
			text-transform: uppercase;
		}

		.header.content .nav-toggle {
			margin-top: 12px;
		}

		.nav-sections-items {
			.nav-sections-item-title:not(.active) {
				display: none !important;
			}

			.nav-sections-item-content {
				display: none !important;

				&[aria-hidden="false"] {
					display: block !important;
				}
			}

			.section-item-content .navigation .megamenu.level0 {
				width: 100%;
			}
		}
	}
}

.page-wrapper {

	.logo {
		z-index: 1;
	}

	.page-header {
		margin-bottom: 0px;
		border: none;

		.panel.header {
			max-width: 100% !important;
			width: 100% !important;

			.header_right {
				padding-right: 0 !important;
			}
		}
	}
}

.nav-sections {

	.level0.has-active>a:not(.ui-state-active) {
		border-left: 0 none !important;
	}
}
