// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Contained row appearance styles
//  _______________________________

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1280px;

    [data-element='inner'] {
        box-sizing: border-box;
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: 768px) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important;
        }
    }
}
