
// animate the way header dropdown is shown
@keyframes dropdown-slide-fade-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
// animate the way header dropdown is hidden
@keyframes dropdown-slide-fade-down {
    0% {
        opacity: 1;
        transform: translateY(0px);
        height: auto;
    }

    90% {
        opacity: 0;
        transform: translateY(20px);
        height: auto;
    }
    100% {
        height: 0;
    }
}


// animate the way header dropdown is shown
@keyframes dropdown-slide-fade-up-mobile {
    0% {
        opacity: 0;
        left: 50%;
        transform: translate(-50%, 20px);
    }

    100% {
        opacity: 1;
        left: 50%;
        transform: translate(-50%, 0px);
    }
}
// animate the way header dropdown is hidden
@keyframes dropdown-slide-fade-down-mobile {
    0% {
        opacity: 1;
        left: 50%;
        transform: translate(-50%, 0px);
        height: auto;
    }

    90% {
        opacity: 0;
        transform: translate(-50%, 20px);
        height: auto;
    }
    100% {
        height: 0;
        left: 50%;
    }
}