// @import '../utils/variables';
.orders {
    &-history,
    &-recent {
        width: 100%;
        padding: 0 $global-padding;

        thead {
            background: $white;
            // border: 1px solid $input-border-color;
        }

        th {
            font-size: rem-calc(14);
            padding: 0.7rem;

            @include breakpoint(medium only) {
                word-break: break-all;
            }
        }

        td {
            font-size: rem-calc(14);
            font-weight: $global-weight-normal;
            padding: 0.7rem;

            &.cash {
                white-space: nowrap;
            }

            &:last-of-type {
                border-right: none;
            }

            &.sku {
                @include breakpoint(medium only) {
                    word-break: break-all;
                }
            }

            &.points {
                span.price {
                    white-space: nowrap;
                }
            }

            &.actions {
                a {
                    display: inline-block;
                }
            }
        }
    }

    &-recent-desktop,
    &-history-desktop {
        @include element-invisible;

        @include breakpoint(large) {
            @include element-invisible-off;
            width: 100% !important;
            padding: 0 1rem !important;
        }
    }

    &-history-mobile {
        @include breakpoint(large) {
            @include element-invisible;
        }

        @include breakpoint(medium down) {
            @include flex-grid-row;
            width: 100%;
            padding-left: 0px;
        }

        ul {
            @include flex-grid-column(12);

            @include breakpoint(medium only) {
                @include flex-grid-column(6);
                padding: rem-calc(0 8px);
                padding-left: 0 !important;
            }

            @include breakpoint(small only) {
                padding: 0;
            }
        }

        li {
            font-size: rem-calc(14);
            font-weight: $global-weight-skinny;
            background: $white;
            padding: rem-calc(8px 16px);
            display: flex;

            span {
                font-weight: $global-weight-skinny;
                &:first-child {
                    flex-basis: 50%;
                }
                &:last-child {
                    width: 50%;
                }
            }

            &.col.product-sku {
                border-bottom: 1px solid $input-border-color;
            }

            &.col.flex-pay-cash {
                border-bottom: 1px solid $input-border-color;
            }

            &:nth-child(1) {
                background: $theme-primary-color;
                color: $white;
            }

            &:nth-child(2) {
                background: $theme-primary-color;
                color: $white;
            }

            &:last-of-type {
                padding-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;

                > span {
                    display: flex;
                }
            }

            .action {
                margin: rem-calc(0 8px);

                @include breakpoint(small only) {
                    flex-grow: 1;
                }

                &.view {
                    @include button-style-1;

                    @include breakpoint(large) {
                        padding-left: rem-calc(20px);
                        padding-right: rem-calc(20px);
                    }

                    @include breakpoint(medium down) {
                        padding-left: rem-calc(5px);
                        padding-right: rem-calc(5px);
                        flex-grow: 1;
                    }
                }

                &.order {
                    @include button-style-3;

                    @include breakpoint(large) {
                        padding-left: rem-calc(20px);
                        padding-right: rem-calc(20px);
                    }

                    @include breakpoint(medium down) {
                        padding-left: rem-calc(5px);
                        padding-right: rem-calc(5px);
                        flex-grow: 1;
                    }
                }
            }
        }
    }
    &-recent-mobile {
        @include breakpoint(large) {
            @include element-invisible;
        }

        @include breakpoint(medium down) {
            @include flex-grid-row;
            width: 100%;
        }

        @include breakpoint(medium only) {
            padding: rem-calc(0);
        }

        ul {
            @include flex-grid-column(12);

            @include breakpoint(medium only) {
                @include flex-grid-column(6);
                &:nth-child(even) {
                    padding-left: rem-calc(15);
                    padding-right: rem-calc(15);
                }
                &:nth-child(odd) {
                    padding-left: rem-calc(0);
                }
            }

            @include breakpoint(small only) {
                padding: 0;
            }
        }

        li {
            font-size: rem-calc(14);
            font-weight: $global-weight-skinny;
            background: $white;
            padding: rem-calc(8px 16px);
            display: flex;

            .action.view {
                @include breakpoint(medium down) {
                    margin-left: rem-calc(-25);
                }
            }

            span {
                font-weight: $global-weight-skinny;
                &:first-child {
                    flex-basis: 50%;
                }
                &:last-child {
                    width: 50%;
                }
            }

            &.col.product-sku {
                border-bottom: 1px solid $input-border-color;
            }

            &.col.flex-pay-cash {
                border-bottom: 1px solid $input-border-color;
            }

            &:last-of-type {
                padding-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;

                > span {
                    display: flex;
                }
            }
        }
    }
}

.account {
    .page-main {
        .columns {
            @include breakpoint(large) {
                padding: 0;
            }
        }
    }

    .main {
        .block {
            &-title {
                @include flex;
                @include flex-align($x: justify, $y: bottom);
                font-size: rem-calc(20);
                font-weight: $global-weight-normal;
                text-transform: uppercase;
                width: 100%;

                @include breakpoint(small only) {
                    font-size: rem-calc(18px);
                }

                strong {
                    font-weight: inherit;
                }

                &.order {
                    padding-top: 1rem;
                }

                p {
                    text-transform: uppercase;
                    margin-bottom: 0;
                    line-height: 1;
                    @include breakpoint(large) {
                        padding-left: rem-calc(16);
                    }
                }

                .action {
                    color: $theme-primary-font-color;
                    font-size: rem-calc(12);
                    font-weight: $global-weight-skinny;
                    text-decoration: underline;
                }
            }

            &-content {
                @include flex-grid-row();
                padding: rem-calc(15 0);

                @include breakpoint(medium) {
                    margin-left: rem-calc(0);
                    margin-right: rem-calc(-15);
                }
            }

            &-dashboard {
                &-info {
                    .block-title {
                        padding-left: rem-calc(15);
                        @include breakpoint(small only) {
                            padding-left: 0;
                        }
                        @include breakpoint(medium only) {
                            padding-left: 0;
                        }
                    }
                    .box-information {
                        @include breakpoint(medium only) {
                            padding-left: 0px;
                        }
                        @include breakpoint(small only) {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                }
                &-addresses,
                &-orders {
                    margin-bottom: $global-margin;
                    .block-title {
                        padding-left: rem-calc(15);
                        @include breakpoint(small only) {
                            padding-left: 0;
                        }
                        @include breakpoint(medium only) {
                            padding-left: 0;
                        }
                        p {
                            @include breakpoint(retina) {
                                padding-left: 0;
                            }
                            @include breakpoint(large) {
                                padding-left: 0;
                            }
                        }
                    }
                }
                &-addresses {
                    .box-billing-address {
                        @include breakpoint(medium only) {
                            padding-left: 0px;
                        }
                    }
                }
            }

            &-addresses-list,
            &-order-details-view {
                .block-content {
                    @include breakpoint(medium) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: rem-calc(40);
            width: 100%;

            @include breakpoint(small only) {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            > .primary,
            > .secondary {
                @include breakpoint(small only) {
                    width: 100%;
                }
            }

            > .primary {
                @include breakpoint(medium) {
                    margin-right: rem-calc(20px);
                }
            }

            > .secondary {
                @include breakpoint(large) {
                    padding-left: rem-calc(15);
                }
                @include breakpoint(retina) {
                    padding-left: rem-calc(15);
                }
                @include breakpoint(medium down) {
                    padding-left: 0;
                }
            }

            .action {
                &.add,
                &.submit {
                    @include button-style-1;
                    margin-bottom: rem-calc(20);
                }

                &.back {
                    @include button-style-3;
                }

                @include breakpoint(small only) {
                    .primary,
                    .back {
                        width: 100%;
                    }
                }

                @include breakpoint(medium) {
                    &.primary {
                        margin-right: rem-calc(20);
                    }
                }
            }
        }
    }

    &-nav {
        background: $white;
        @include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
        max-width: rem-calc(260);

        @include breakpoint(medium down) {
            max-width: 100%;
        }

        .item {
            > a,
            > strong,
            > span:not(.delimiter) {
                @include font(14, 20, $global-weight-bold);
                color: $light-text-gray;
                display: block;
                padding: rem-calc(14 24);

                strong,
                span {
                    padding: 0;
                }
            }

            &.current {
                border-left: 6px solid $theme-tertiary-color;

                > strong,
                > span:not(.delimiter) {
                    padding: rem-calc(14 24 14 18);
                    pointer-events: none;
                }
            }
        }
    }

    .form-address-edit {
        @include flex-grid-row;
        justify-content: space-between;
        padding: 0 1rem;

        @include breakpoint(medium down) {
            padding: 0;
        }

        .fieldset {
            @include flex-grid-column(12);
            margin: rem-calc(0 0 20 0);

            background: $white;

            @include breakpoint(large) {
                @include flex-grid-column(49%);
            }

            select {
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
                background-image: none;
            }
        }

        label {
            @include font(
                $input-label-size,
                $input-label-size,
                $input-label-weight
            );
            margin-bottom: rem-calc(7px);
        }

        .field.choice {
            margin-top: rem-calc(10);
        }

        .message {
            margin-bottom: 1rem;
        }
    }

    .box {
        &-information,
        &-newsletter,
        &-billing-address,
        &-shipping-address,
        &-address-billing,
        &-address-shipping {
            @include flex-grid-column(12);

            @include breakpoint(medium) {
                @include flex-grid-column(6);
            }
        }

        &-addresses-default {
            @include flex-grid-column(12);
            padding: 0;
        }

        &-address-billing,
        &-address-shipping {
            @include breakpoint(medium only) {
                padding: rem-calc(8px 16px);
            }

            @include breakpoint(small only) {
                padding: rem-calc(8px 0px);
            }
        }

        &-billing-address,
        &-shipping-address {
            @include breakpoint(small only) {
                padding: 0;
            }
        }

        &-title {
            @include flex;
            @include font(21, 25, $global-weight-normal);
            margin-bottom: $global-margin;

            strong {
                font-weight: inherit;
            }
        }

        &-content {
            background: $white;
            @include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
            padding: $global-padding;
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &-actions {
            @include flex;

            .action {
                @include button-style-3;
                padding-left: rem-calc(20px);
                padding-right: rem-calc(20px);

                @include breakpoint(medium only) {
                    padding: rem-calc(10px);
                }
            }

            .action:first-child {
                margin-right: calc($global-margin / 2);
            }

            .action:last-child {
                margin-right: 0;
            }
        }

        &-billing-address {
            @include breakpoint(small only) {
                margin-bottom: rem-calc(15px);
            }

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }
    }

    address {
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: $global-weight-normal;
        margin-bottom: calc($global-margin / 2);
        min-height: rem-calc(190);
        flex-grow: 1;

        &:first-line {
            line-height: 3;
        }
    }
}

.account.customer-address-index {
    .main {
        .block-addresses-list .block-content {
            margin-left: rem-calc(-15);
            margin-right: rem-calc(-15);
            width: calc(100% + 30px);

            @include breakpoint(medium down) {
                margin-left: rem-calc(-8);
                margin-right: rem-calc(-8);
                width: calc(100% + 16px);

                .addresses {
                    width: 100%;

                    .item {
                        @include flex-grid-column(6, 0);
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }
            }

            @include breakpoint(small only) {
                .addresses .item {
                    @include flex-grid-column(12);
                }
            }
        }
    }
}

.customer-address-index {
    .box-addresses-default {
        @include breakpoint(large) {
            @include flex-grid-column(12, 0);
        }
        @include breakpoint(large down) {
            padding: 0;
        }

        .block-content {
            @include breakpoint(medium only) {
                padding: rem-calc(15px 8px);
                margin-left: rem-calc(-15);

                .box {
                    padding: rem-calc(0 8px);
                }
            }
            @include breakpoint(large) {
                margin-left: rem-calc(-15);
            }
        }
    }
}

.block-addresses-list {
    @include flex-grid-row();
    padding: $global-padding 0;

    @include breakpoint(large down) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .block-content {
        width: 100%;

        .empty {
            @include message(info);
            margin: 0 auto;
            @include breakpoint(large) {
                max-width: 97%;
            }
            @include breakpoint(retina only) {
                max-width: 96%;
            }
            @include breakpoint(medium only) {
                max-width: 98%;
            }
            @include breakpoint(small only) {
                max-width: 96%;
            }
        }

        @include breakpoint(medium only) {
            padding-left: rem-calc(8px);

            .box {
                padding: rem-calc(0 8px);
            }
        }
    }

    .addresses {
        margin-top: rem-calc(-15px);
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .item {
            @include flex-grid-column(12);
            padding: rem-calc(15px);

            @include breakpoint(large) {
                @include flex-grid-column(6);
            }

            @include breakpoint(medium down) {
                padding: rem-calc(8px 16px);
            }
        }
    }
}

.account[class*="sales-order"]:not([class*="print"]):not([class*="history"]) {
    .columns {
        @include breakpoint(large) {
            padding: 0;
        }
    }

    .page-main {
        padding-top: rem-calc(50px);
        padding-bottom: rem-calc(50px);

        @include breakpoint(medium down) {
            padding-top: rem-calc(15px);
            padding-bottom: rem-calc(15px);
        }
    }

    .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        align-items: flex-end;

        .page-title {
            color: $theme-primary-font-color;
            display: flex;
            margin-right: rem-calc(7px);
            letter-spacing: -0.2px;
            margin-bottom: 0;

            @include breakpoint(large) {
                @include font(30, 30, $global-weight-normal);
                width: auto;
                flex: 0 0 auto;
            }
            @include breakpoint(medium down) {
                margin-right: 0;
                @include font(20, 20, $global-weight-normal);
            }
        }

        .actions-toolbar {
            @include flex;
            justify-content: flex-end;
            width: auto;
            flex-grow: 1;
            margin-bottom: 0;

            @include breakpoint(medium down) {
                order: 4;
                justify-content: space-between;
                width: 100%;
                margin-bottom: rem-calc(20px);
                margin-top: rem-calc(10px);
            }

            .actions {
                @include breakpoint(medium down) {
                    width: 100%;
                    display: block;
                    text-align: right;
                }

                .action {
                    @include font(16, 19, $global-weight-normal);
                    color: $theme-secondary-color;

                    &:not(:first-child) {
                        @include breakpoint(large) {
                            padding-left: rem-calc(7);
                            margin-left: rem-calc(7);
                            border-left: 1px solid $theme-secondary-color;
                        }
                        @include breakpoint(medium down) {
                            padding-left: rem-calc(7);
                            margin-left: rem-calc(4);
                            border-left: 1px solid $theme-secondary-color;
                        }
                    }

                    &:hover,
                    &:focus {
                        color: $theme-tertiary-color;
                    }
                }
            }
        }
    }

    .order {
        &-date {
            margin-bottom: rem-calc(15);
            margin-top: rem-calc(15);
            width: 100%;

            @include breakpoint(medium down) {
                order: 2;
                margin-bottom: rem-calc(10);
                margin-top: rem-calc(10);
            }

            .label {
                @include element-invisible;
            }

            .date {
                @include font(16, 16, $global-weight-normal);
                color: $black;
            }
        }

        &-status {
            @include font(16, 19, $global-weight-normal);
            margin-bottom: 0;
            border-left: 1px solid $order-button-border-color;
            width: auto;
            padding: rem-calc(0 7);

            @include breakpoint(medium down) {
                order: 3;
                border-left: none;
                padding-left: 0;
            }
        }

        &-links {
            display: flex;
            justify-content: flex-start;
            margin-bottom: rem-calc(15px);
            width: 100%;

            @include breakpoint(small only) {
                margin-bottom: rem-calc(10px);
            }

            .item {
                border: 1px solid $order-button-border-color;

                @include breakpoint(small only) {
                    flex-grow: 1;
                }

                a,
                strong {
                    @include font(14, 14, $global-weight-skinny);
                    color: $theme-primary-font-color;
                    display: block;
                    padding: rem-calc(15px 20px);
                    text-align: center;
                    white-space: nowrap;

                    @include breakpoint(medium) {
                        min-width: rem-calc(160px);
                    }
                    @include breakpoint(small only) {
                        padding: rem-calc(15px 8px);
                    }
                }

                &.current {
                    border-color: $theme-tertiary-color;
                    background: $theme-tertiary-color;

                    strong {
                        color: $white;
                    }
                }

                &:not(:first-child) {
                    margin-left: -1px;
                }
            }
        }

        &-actions-toolbar {
            .actions {
                @include flex;
                @include flex-align($x: justify);
                font-size: rem-calc(14);
                font-weight: $global-weight-skinny;

                @include breakpoint(large) {
                    padding: 0 !important;
                }
            }
        }

        &-details-item {
            .actions-toolbar {
                @include breakpoint(large) {
                    padding: 0 !important;
                }
            }
        }

        &-details-items {
            display: flex;
            flex-wrap: wrap;

            > div {
                width: 100%;
            }

            @include breakpoint(large) {
                padding: 0;
            }

            .actions-toolbar:first-of-type {
                order: 100;
                margin: rem-calc(0 0 15px 0);

                @include breakpoint(small only) {
                    margin: rem-calc(15px 0 30px 0);
                }
            }

            .actions-toolbar:last-of-type {
                display: none;
            }

            .actions-toolbar {
                @include breakpoint(large) {
                    padding: 0 !important;
                }

                .action.print {
                    @include button-style-1;
                    min-width: rem-calc(246px);

                    @include breakpoint(small only) {
                        width: 100%;
                    }
                }
            }

            .order-title {
                margin-bottom: rem-calc(10);

                strong,
                a {
                    @include font(14, 17, $global-weight-normal);
                }

                strong {
                    @include breakpoint(small only) {
                        display: block;
                    }
                }

                a {
                    @include breakpoint(small only) {
                        display: inline-block;
                    }
                }

                a + a {
                    padding-left: 7px;
                    margin-left: 7px;
                    border-left: 1px solid $theme-secondary-color;
                }

                a.track {
                    @include element-invisible;
                }
            }
        }
    }

    .table-wrapper {
        .table-order-items {
            table-layout: auto;
            @include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));

            caption {
                display: none;
            }

            thead,
            tbody,
            tfoot {
                background: $white;
            }

            thead {
                border: none;
                border-bottom: 1px solid $light-grey;

                th {
                    padding: rem-calc(15 8 21);
                    vertical-align: top;

                    @include breakpoint(xlarge) {
                        @include font(15, 15, $global-weight-normal);
                    }

                    @include breakpoint(large down) {
                        @include font(13, 13, $global-weight-normal);
                    }

                    &:first-child,
                    &:last-child {
                        padding: rem-calc(15 15 21);
                    }
                }

                @include breakpoint(small only) {
                    display: none;
                }
            }

            tfoot {
                border: none;
                border-top: 1px solid $light-grey;

                th {
                    padding: rem-calc(15);

                    @include breakpoint(small only) {
                        display: block;
                        width: 100%;
                    }
                }
            }

            tbody {
                border: none;

                @include breakpoint(small only) {
                    &:not(:last-of-type) {
                        border-bottom: 1px solid $light-grey;
                    }
                }

                tr {
                    @include breakpoint(small only) {
                        display: block;
                    }
                }

                td {
                    @include font(12, 14, $global-weight-normal);
                    vertical-align: top;

                    @include breakpoint(medium) {
                        padding: rem-calc(15 8);
                        &:first-child,
                        &:last-child {
                            padding: rem-calc(15);
                        }
                    }

                    @include breakpoint(small only) {
                        display: flex;
                        width: 100%;
                        padding: rem-calc(8);
                        flex-wrap: wrap;

                        &:first-child {
                            padding-top: rem-calc(15px);
                        }
                        &:last-child {
                            padding-bottom: rem-calc(15px);
                        }

                        &:before {
                            content: attr(data-th);
                            @include font(15, 15, $global-weight-normal);
                            width: 50%;
                        }

                        > * {
                            width: 50%;
                        }
                    }
                }

                .product-item-name {
                    @include font(12, 14, $global-weight-normal);
                    height: auto;
                    min-height: initial;
                    margin-top: 0;
                    top: 0;
                    text-align: left;
                    -webkit-line-clamp: initial;
                    overflow: visible;
                    display: block;
                    color: $theme-primary-font-color;

                    @include breakpoint(small only) {
                        width: 50%;
                    }
                }

                .item-options {
                    margin-bottom: 0;
                    margin-top: rem-calc(5px);

                    @include breakpoint(small only) {
                        margin-bottom: rem-calc(10);
                        width: 50%;
                        position: relative;
                        left: 50%;
                    }

                    .item-option {
                        display: block;
                        margin-bottom: rem-calc(3px);

                        strong,
                        span {
                            @include font(12, 15, $global-weight-normal);
                        }
                        strong:first-child,
                        span:first-child {
                            &:after {
                                content: ":";
                            }
                        }
                    }
                }

                .items-qty {
                    margin-bottom: 0;
                }

                .sku {
                    word-break: break-all;
                }
            }

            .totals-container {
                display: flex;
                justify-content: space-between;

                span,
                strong {
                    @include font(12, 14, $global-weight-bold);
                }

                span.mark {
                    @include breakpoint(small only) {
                        overflow-wrap: break-word;
                        max-width: 12ch;
                    }
                }
            }

            .col.name {
                width: 25%;
                @include breakpoint(large) {
                    text-align: left;
                }
                @include breakpoint(medium only) {
                    width: 20%;
                }
                @include breakpoint(small only) {
                    width: 100%;
                }
            }

            &.invoice,
            &.shipment,
            &.creditmemo {
                .qty {
                    @include breakpoint(medium) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .block-order-details-view {
        .block-title {
            @include element-invisible;
        }

        .block-content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            justify-content: space-between;

            @include breakpoint(small only) {
                // padding: rem-calc(0 15px);
                flex-direction: column-reverse;
            }
        }

        .box {
            background: $white;
            @include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
            width: 50%;
            max-width: rem-calc(422px);
            padding: rem-calc(20px);
            min-height: rem-calc(220px);

            @include breakpoint(large down) {
                max-width: 49%;
            }

            @include breakpoint(small only) {
                width: 100%;
                max-width: 100%;

                &:not(:first-of-type) {
                    margin-bottom: rem-calc(15px);
                }
            }

            &-title {
                margin-bottom: rem-calc(15);
            }
            &-content {
                box-shadow: none;
                padding: 0;
                height: auto;

                address {
                    min-height: auto;

                    &:first-line {
                        font-weight: $global-weight-semibold;
                        line-height: 3;
                    }
                }
            }
        }
    }

    .message {
        margin-bottom: 1rem;
    }

    .column {
        > .actions-toolbar {
            @include element-invisible;
        }
    }
}

.account.sales-order-history {
    .main {
        .block-title {
            padding-top: 0;

            select {
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
                background-image: none;
                padding-right: 0px;
            }
        }

        > .message {
            margin-bottom: rem-calc(20px);
            @include breakpoint(retina) {
                margin: 0 auto 1.25rem auto;
                max-width: 97%;
            }
            @include breakpoint(medium down) {
                max-width: 100%;
            }
            @include breakpoint(large) {
                margin: 0 auto 1.25rem auto;
                max-width: 97%;
            }
        }
        .actions-toolbar {
            @include breakpoint(retina) {
                padding-left: rem-calc(0);
            }
            @include breakpoint(large) {
                padding-left: rem-calc(0);
            }
            @include breakpoint(medium down) {
                padding-left: 0;
            }
        }
    }
}

#my-auctions-table {
    width: 100%;
    padding: 0 $global-padding;
    @include breakpoint(small only) {
        display: none;
    }
    @include breakpoint(medium only) {
        display: none;
    }
    thead {
        background: $white;
        border-bottom: 1px solid #e1e1e1;
    }

    th {
        font-size: rem-calc(12);
        padding: rem-calc(8px);

        @include breakpoint(medium only) {
            word-break: break-all;
        }
    }

    td {
        border-right: 1px solid #e1e1e1;
        font-size: rem-calc(14);
        font-weight: $global-weight-skinny;
        padding: rem-calc(8);
    }
}

.columns .pager {
    display: flex;
    float: right;
}

.block-content {
    @include breakpoint(large) {
        #my-auctions-list {
            display: none;
        }
    }
    @include breakpoint(medium only) {
        #my-auctions-list {
            min-width: 98%;
            margin-left: 0;
        }
    }
    @include breakpoint(small only) {
        #my-auctions-list {
            min-width: 100%;
            padding-right: rem-calc(3);
        }
    }
}

.table-wrapper.auctions-recent.desktop {
    background: $white;
    // box-shadow: 0 0 8px 0 rgba(0,0,0,0.14);
    width: 100%;

    @include breakpoint(medium only) {
        display: none;
    }
    @include breakpoint(small only) {
        display: none;
    }
}

#my-auctions-table {
    margin: 0 auto;

    thead {
        border-bottom: 1px solid #e1e1e1;
    }

    th {
        padding: 0.5rem;
        font-size: rem-calc(14);
    }

    td {
        font-size: rem-calc(14);
        font-weight: 300;
        padding: rem-calc(11);
        &:last-of-type {
            border-right: none;
        }
    }

    tbody tr:nth-child(even) {
        border-bottom: 0;
    }

    td.col.time-left {
        min-width: 130px;
    }
    td.col.product-name {
        width: 100px;
    }
}

.auction-history-mobile {
    ul li span:first-child {
        color: $theme-primary-font-color;
        line-height: rem-calc(20);
        font-weight: $global-weight-normal;
    }
    ul,
    li {
        font-size: 0.875rem;
        font-weight: $global-weight-skinny;
        background: $white;
        padding: 0.5rem 0.5rem;
    }
}

.block-dashboard-auctions {
    .block-content {
        @include breakpoint(large) {
            margin-right: 0 !important;
        }
    }
}

.orders-history {
    .my-orders-table {
        -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
        -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
        padding-bottom: rem-calc(20);
        thead {
            background-color: $theme-primary-color;
            color: $table-orders-head-font-color;
            font-weight: $global-weight-normal;
            padding: rem-calc(10);
            th.col.order-date {
                width: 50%;
            }
        }
        thead th,
        thead td,
        tfoot th,
        tfoot td {
            font-weight: $global-weight-normal;
        }

        td {
            .action {
                margin: rem-calc(0 8px);

                @include breakpoint(small only) {
                    flex-grow: 1;
                }

                &.view {
                    @include button-style-1;
                    min-width: 35%;

                    @include breakpoint(large) {
                        padding-left: rem-calc(20px);
                        padding-right: rem-calc(20px);
                    }

                    @include breakpoint(medium down) {
                        padding-left: rem-calc(5px);
                        padding-right: rem-calc(5px);
                        flex-grow: 1;
                    }
                }

                &.order {
                    @include button-style-3;
                    min-width: 35%;

                    @include breakpoint(large) {
                        padding-left: rem-calc(20px);
                        padding-right: rem-calc(20px);
                    }

                    @include breakpoint(medium down) {
                        padding-left: rem-calc(5px);
                        padding-right: rem-calc(5px);
                        flex-grow: 1;
                    }
                }
            }
        }
        td.col.actions {
            text-align: right;
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background: $white;
                }
            }
        }
        .table-order-items:nth-child(even) {
            border-top: 1px solid #e3e3e3e3;
            border-bottom: 1px solid #e3e3e3;
        }
    }
}

// .columns .pager {
//     float: none;
// }

.auction-auction-history {
    .pager {
        float: right;
    }

    select {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
        background-image: none;
        padding-right: 0px;
    }
}

//rtl direction
html {
    &:lang(ar) {
        .sales-order-history {
            .pager {
                padding-left: 15px;

                @include breakpoint(medium down) {
                    padding-left: 0;
                }
            }
        }
    }
}
