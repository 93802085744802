// fonts varialbes

$fontPath: '../fonts/';


@font-face {
    font-family: 'GothamExtraItalic';
    src: url('#{$fontPath}Gotham-ExtraLightItalic.eot');
    src: url('#{$fontPath}Gotham-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-ExtraLightItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-ExtraLightItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-ExtraLightItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-ExtraLightItalic.svg#Gotham-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'GothamUltraItalic';
    src: url('#{$fontPath}Gotham-UltraItalic.eot');
    src: url('#{$fontPath}Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-UltraItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-UltraItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-UltraItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-UltraItalic.svg#Gotham-UltraItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'GothamBookItalic';
    src: url('#{$fontPath}Gotham-BookItalic.eot');
    src: url('#{$fontPath}Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-BookItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-BookItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-BookItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'GothamUltra';
    src: url('#{$fontPath}Gotham-Ultra.eot');
    src: url('#{$fontPath}Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Ultra.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Ultra.woff') format('woff'),
        url('#{$fontPath}Gotham-Ultra.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Ultra.svg#Gotham-Ultra') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-Light.eot');
    src: url('#{$fontPath}Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Light.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Light.woff') format('woff'),
        url('#{$fontPath}Gotham-Light.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-Book.eot');
    src: url('#{$fontPath}Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Book.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Book.woff') format('woff'),
        url('#{$fontPath}Gotham-Book.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-LightItalic.eot');
    src: url('#{$fontPath}Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-LightItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-LightItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-LightItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-BoldItalic.eot');
    src: url('#{$fontPath}Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-BoldItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-BoldItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-BoldItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-Thin.eot');
    src: url('#{$fontPath}Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Thin.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Thin.woff') format('woff'),
        url('#{$fontPath}Gotham-Thin.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Thin.svg#Gotham-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-Medium.eot');
    src: url('#{$fontPath}Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Medium.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Medium.woff') format('woff'),
        url('#{$fontPath}Gotham-Medium.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-Bold.eot');
    src: url('#{$fontPath}Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-Bold.woff2') format('woff2'),
        url('#{$fontPath}Gotham-Bold.woff') format('woff'),
        url('#{$fontPath}Gotham-Bold.ttf') format('truetype'),
        url('#{$fontPath}Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-ExtraLight.eot');
    src: url('#{$fontPath}Gotham-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-ExtraLight.woff2') format('woff2'),
        url('#{$fontPath}Gotham-ExtraLight.woff') format('woff'),
        url('#{$fontPath}Gotham-ExtraLight.ttf') format('truetype'),
        url('#{$fontPath}Gotham-ExtraLight.svg#Gotham-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-ThinItalic.eot');
    src: url('#{$fontPath}Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-ThinItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-ThinItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-ThinItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-ThinItalic.svg#Gotham-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$fontPath}Gotham-MediumItalic.eot');
    src: url('#{$fontPath}Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}Gotham-MediumItalic.woff2') format('woff2'),
        url('#{$fontPath}Gotham-MediumItalic.woff') format('woff'),
        url('#{$fontPath}Gotham-MediumItalic.ttf') format('truetype'),
        url('#{$fontPath}Gotham-MediumItalic.svg#Gotham-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}


// Latam
@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-Bold.eot');
    src: url('#{$fontPath}LatamSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-Bold.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-Bold.woff') format('woff'),
        url('#{$fontPath}LatamSans-Bold.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-Bold.svg#LatamSans-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-RegularItalic.eot');
    src: url('#{$fontPath}LatamSans-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-RegularItalic.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-RegularItalic.woff') format('woff'),
        url('#{$fontPath}LatamSans-RegularItalic.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-RegularItalic.svg#LatamSans-RegularItalic') format('svg');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-Black.eot');
    src: url('#{$fontPath}LatamSans-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-Black.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-Black.woff') format('woff'),
        url('#{$fontPath}LatamSans-Black.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-Black.svg#LatamSans-Black') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-BoldItalic.eot');
    src: url('#{$fontPath}LatamSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-BoldItalic.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-BoldItalic.woff') format('woff'),
        url('#{$fontPath}LatamSans-BoldItalic.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-BoldItalic.svg#LatamSans-BoldItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-LightItalic.eot');
    src: url('#{$fontPath}LatamSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-LightItalic.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-LightItalic.woff') format('woff'),
        url('#{$fontPath}LatamSans-LightItalic.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-LightItalic.svg#LatamSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-Light.eot');
    src: url('#{$fontPath}LatamSans-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-Light.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-Light.woff') format('woff'),
        url('#{$fontPath}LatamSans-Light.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-Light.svg#LatamSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-BlackItalic.eot');
    src: url('#{$fontPath}LatamSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-BlackItalic.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-BlackItalic.woff') format('woff'),
        url('#{$fontPath}LatamSans-BlackItalic.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-BlackItalic.svg#LatamSans-BlackItalic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Latam Sans';
    src: url('#{$fontPath}LatamSans-Regular.eot');
    src: url('#{$fontPath}LatamSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}LatamSans-Regular.woff2') format('woff2'),
        url('#{$fontPath}LatamSans-Regular.woff') format('woff'),
        url('#{$fontPath}LatamSans-Regular.ttf') format('truetype'),
        url('#{$fontPath}LatamSans-Regular.svg#LatamSans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

// iconfont

@font-face {
    font-family: "IredeemIcons";
    src:url("#{$fontPath}iredeem.eot");
    src:url("#{$fontPath}iredeem.eot?#iefix") format("embedded-opentype"),
      url("#{$fontPath}iredeem.woff") format("woff"),
      url("#{$fontPath}iredeem.ttf") format("truetype"),
      url("#{$fontPath}iredeem.svg#iredeem") format("svg");
    font-weight: normal;
    font-style: normal;
  
  }

  // import icon fonts
  @import 'icons';
@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('#{$fontPath}Nunito-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

//Etihad fonts
@font-face {
    font-family: 'Etihad';
    src: url('#{$fontPath}EtihadAltis-Medium_V3.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Etihad';
    src: url('#{$fontPath}EtihadAltis-Text_V3.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Etihad';
    src: url('#{$fontPath}EtihadAltis-Book_V3.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

//FAB fonts 
@font-face {
    font-family: 'FSMatthew';
    src: url('#{$fontPath}FSMatthew-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'FSMatthew';
    src: url('#{$fontPath}FSMatthew.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'FSMatthew';
    src: url('#{$fontPath}FSMatthew-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

//Cba fonts
@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-Bold.eot');
    src: url('#{$fontPath}CBASansWeb-Bold.ttf') format('truetype'),
    url('#{$fontPath}CBASans-Bold.otf') format('otf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'cba';
    src: url('#{$fontPath}CBASansWeb-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: 'CBASansWeb';
    src: url('#{$fontPath}CBASansWeb-Regular.eot');
    src: url('#{$fontPath}CBASansWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}CBASansWeb-Regular.woff2') format('woff2'),
    url('#{$fontPath}CBASansWeb-Regular.woff') format('woff'),
    url('#{$fontPath}CBASansWeb-Regular.ttf') format('truetype'),
    url('#{$fontPath}CBASansWeb-Regular.svg#CBASansWeb-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}