// ========== TYPOGRAPHY ========== 
$theme-font-family:                     'Latam Sans', 'Gotham', -apple-system, "Helvetica Neue", sans-serif;

// ========== COLOR ========== 
$white:                                 #ffffff;

$theme-primary-color:                   #303030;
$theme-secondary-color:                 #1B0088;
$theme-tertiary-color:                  #150067;
$theme-accent-color:                    #2D34CE;

$theme-primary-font-color:              $theme-primary-color        !default;
$theme-primary-alternative-font-color:  $white                      !default;
$theme-secondary-alternative-font-color:#bfbfbf                   !default;
$theme-tertiary-alternative-font-color: $white                      !default;
$theme-accent-alternative-font-color:   $white                      !default;

$theme-header-background:               $white;
$theme-body-background:                 #EEEEEE;
$theme-footer-background:               $theme-secondary-color;
$theme-footer-color:                    $white;


// ========== CCOMPONENTS ==========

// - Navigation
$theme-navigation-shadow:               0px 10px 15px rgba(100, 100, 100, .1);

// + Buttons
    // - Primary
$theme-button-style-1-background:       #ED1650;
$theme-button-style-1-color:            $white;
$theme-button-style-1-border-color:     #ED1650;

    // - Wishlist
$theme-button-style-2-background:       $white;
$theme-button-style-2-color:            $theme-secondary-color;
$theme-button-style-2-border-color:     $white;

    // - Outlined
$theme-button-style-3-background:       $white;
$theme-button-style-3-color:            $theme-secondary-color;
$theme-button-style-3-border-color:     $theme-secondary-color;

$theme-logo-max-width:                  230px;

$theme-navigation-background:           $white;
$theme-navigation-color:                $theme-primary-color;