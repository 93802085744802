.close-button {
    color: $theme-minicart-close-icon-color;

    &:hover, &:focus, &:active {
        color: $theme-minicart-close-icon-color;
    }

    &.medium {
        height: rem-calc(24);
        width: rem-calc(24);
    }
}
