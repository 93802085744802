.cms-index-index, .cms-page-view {

    .page-main {
        > *:not(.block-static-block) {
            @include flex-grid-row();
            align-items: flex-start;

            > .row {
                width: 100%;
            }

            .main {
                @include flex-grid-column(12, 0);
                padding-bottom: rem-calc(16);
            }
        
            main > .columns {
                width: 100%;
            }
        }
        .columns {
            width: 100%;
        }
    }

    .page-title-wrapper {
        @include element-invisible;
    }

    .product {

        &-items {
            @include flex-grid-row();
        }

        &-item {
            @include flex-grid-column(12, 0);

            @include breakpoint(xlarge) {
                padding-left: $product-margin;
            }

            @include breakpoint(xlarge down) {
                padding-left: 0;
            }

            @include breakpoint(xlarge) {
                @include flex-grid-column(3, 0);

                padding-left: $product-margin;

                &-info:hover {
        
                    .product-item-actions {
                        opacity: 1;
                        pointer-events: all;
                    }
        
                    .product-item-name {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .accordion {
        background: transparent;
        width: 100%;

        &-item {
            margin-bottom: 1rem;

            &.is-active {

                .accordion-title {
                    background: $theme-tertiary-color;
                    color: $white;

                    &:before {
                        @include flex-center;
                        @include ird-icon($ird-icon-chevron-link, 12px);
                        color: $white;
                    }
                }
            }
        }

        &-title {
            background: $white;

            &:before {
				@include flex-center;
				@include ird-icon($ird-icon-chevron-right, 12px);
                color: $theme-primary-font-color;
                #{$global-right}: 1rem;
                height: rem-calc(18);
                margin-top: -0.5rem;
                position: absolute;
                top: 50%;
                width: rem-calc(16);
              }
        }

        &-content {
            padding: rem-calc(16 0);
        }
    }

    .tabs, 
    .tabs-content, 
    .accordion, 
    .accordion-content {
        width: 100%;
    }

    .accordion-content {
        border: none;
        background-color: transparent;
        color: $theme-primary-font-color;
    }

    .slick-dots {
        @include breakpoint(large down) {
            width: 100%;
        }
    }

    .product-items.slick-slider {

        @include breakpoint(large down) {
            display: block;
            padding-bottom: 2rem;
        }
    }
 
    .tabs-content {

        .tabs-panel[aria-hidden=true] {
            display: block;
            height: 0;
            overflow-y: hidden;
            padding: 0;
            width: 100%; 
        }

        .tabs-panel {

            .slick-track {
                min-width: rem-calc(200000px);
            } 
        }
    }

    .accordion-item:not(.is-active) {
        
        .accordion-content {
            display: block!important;
            height: 0;
            overflow-y: hidden;
            padding: 0;
            width: 100%;
        }
    }

    .products-grid {
        margin-bottom: 0 - $product-margin;
        position: realtive;

        @include breakpoint(xlarge) {
            margin-left: 0 - $product-margin;
        }
    }
}

.homepage-categories {
    @include flex-grid-row();
    @include flex-grid-column(12, 0);
    flex-direction: column;
    margin-bottom: rem-calc(-$product-margin);

    .slick-list {

        @include breakpoint(large down) {
            margin-right: 0;

            .slick-slide {
                margin-right: 0.5rem;
                margin-left: 0;
                padding: rem-calc(3px);
            }
        }

        @include breakpoint(large only) {
            margin-right: 0;

            .slick-slide {
                margin-right: 0;
                margin-left: 0;

                > div {
                    white-space: nowrap;
                    margin-right: -0.5rem;

                    .product-item {
                        margin-right: 0.5rem;
                        width: calc((100% - 2rem) / 4)!important;
                    }
                }
            }
        }

        @include breakpoint(medium down) {
            margin-right: -0.5rem;
        }

        .slick-track {

            @include breakpoint(large) {
                transform: translate3d(0px, 0px, 0px)!important;
            }
        }
    }
}

.homepage-main-carousel {
    display: flex;
    position: relative;
    overflow: hidden;

    &.slick-initialized {
        flex-direction: column;
    }

    &.slick-initialized.slick-slider {
        margin-bottom: 0;
    }

    .banner-item {
        min-width: 100%;
    }

    .slick-slide {
        margin: 0;
        line-height: 1;
        font-size: 0;

        > div {
            font-size: 0;
        } 
    }
}
