.product-item {
    margin-bottom: $product-margin;

    @include breakpoint (large down) {
        margin-bottom: 0.5rem;
    }

    &-info {
        @include flex;
        align-items: center;
        background: $white;
        flex-flow: column;
        height: rem-calc($product-card-height)!important;
        padding: rem-calc($product-card-padding);
        position: relative;
        transition: all 0.3s linear;
        @include vendor(box-shadow, 0px 0px 0px 0px rgba(0,0,0,0.14));

        &:hover {
            @include vendor(box-shadow, 0px 0px 18px 0px rgba(0,0,0,0.14));
        }

        > .product-new {
            @include font(16, 45, $global-weight-semibold);
            position: absolute;
            background: $theme-card-accent;
            top:rem-calc(15px);
            left:rem-calc(-3px);
            display: block;
            border-top-right-radius: rem-calc(100px);
            border-bottom-right-radius: rem-calc(100px);
            color: $white;
            width: rem-calc(90px);
            text-align: center;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-bottom: rem-calc(3px) solid $theme-card-accent;
                border-left: rem-calc(3px) solid transparent;
                position: absolute;
                left: 0;
                top: rem-calc(-3px);
            }
        }

        .special-offer  {
            position: absolute;
            top: rem-calc(-3px);
            left: rem-calc(-3px);
            width: rem-calc(70px);
            height: rem-calc(70px);
            z-index: 1;
            pointer-events: none;
            overflow: hidden;

            svg {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left:0;

                .ribbon-shape {
                    fill: $theme-card-accent;
                }

                .ribbon-border {
                    fill: darken( $theme-card-accent, 10%);
                    stroke: darken( $theme-card-accent, 10%);
                    stroke-width: rem-calc(0.5px);
                }

                .corner {
                    fill: darken( $theme-card-accent, 10%);
                }

                .ribbon-shadow {
                    fill: rgba(51,51,51,0.15);
                }
            }

            .ribbon-label {
                @include font(10, 10, 500, normal, uppercase);
                color: $white;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                margin-top: rem-calc(-10px);
                margin-left: rem-calc(-10px);

                &.medium-font {
                    @include font(9, 9, 600, normal, uppercase);
                }

                &.small-font {
                    @include font(8, 8, 600, normal, uppercase);
                }
            }
        }

        .special-offer-new {
            position: absolute;
            top: rem-calc(17px);
            left: rem-calc(-5px);
            width: auto;
            height: rem-calc(70px);
            z-index: 1;
            pointer-events: none;

            .triangle {
                width: 0;
                height: 0;
                border-bottom: 4px solid $theme-card-accent;
                border-left: 4px solid transparent;
                position: absolute;
                top: -10px;
                left: 0%;
                z-index: 0;
            }

            .product-new-badge {
                font-weight: 500;
                padding: rem-calc(8 25);
                background-color: $theme-card-accent;
                color: $white;
                border-top-right-radius: 50px 50px;
                border-bottom-right-radius: 50px 50px;
            }
        }

        .flexpay {
            @include font(11, 14, $global-weight-semibold, normal, uppercase);
            min-width: rem-calc(88px);
            color: $white;
            text-align: center;
            text-shadow: 0 2px 0 rgba(0,0,0,0.2);
          background: $theme-card-flexpay-background;
            border-top-left-radius: rem-calc(16px);
            border-bottom-left-radius: rem-calc(16px);
            align-self: flex-end;
            margin-right: rem-calc(-$product-card-padding);
            margin-bottom: rem-calc(-16px);
            padding: rem-calc(1px 10px);
        }

    }

    &-photo {
        display: block;
        height: rem-calc($product-image-height);
        position: relative;
        width: 100%;
        text-align: center;

        img {
            height: 100%;
            position: relative;
            width: auto;
            display: inline-block;
            font-size: rem-calc(10px);
        }
    }


    &-details {
        height: $product-details-height;
        padding: rem-calc(0px 5px 0px);

        .price-box .normal-price {

            .price-container {
                justify-content: center;

                .price-label {
                    font-size: rem-calc(14px);
                    line-height: 1;
                }
            }
        }
    }

    // overflow ellipsis text
    $pnLines: 2;
    $pnLineHeight: 1.25;
    $pnFontSize: 0.875rem;
    $pnHeight: $pnLineHeight * $pnLines * $pnFontSize;

    &-name {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $pnLines;
        color: $theme-card-color;
        display: -webkit-box;
        font-size: $pnFontSize;
        font-weight: $global-weight-semibold;
        height: rem-calc($pnHeight);
        line-height: $pnLineHeight;
        max-width: 100%;
        min-height: rem-calc(35);
        margin-bottom: rem-calc(5px);
        opacity: 1;
        overflow: hidden;
		text-align: center;
        text-overflow: ellipsis;
        width: 100%;
        margin-top: rem-calc(20px);
        position: relative;
        top: rem-calc(-2px);
        white-space: normal;

        @include internet-explorer {
            display: block;
        }

        a {
            color: inherit;
            display: block;
        }
    }

    &-details {
        position: relative;
        width: 100%;
        height: calc(100% - #{$product-image-height});

        @include breakpoint(large only) {
            height: calc(100% - 110px);
        }

        .price-box .normal-price {

            .price-container {
                justify-content: center;

                .price-label {
                    font-size: rem-calc(18);
                }
            }
        }
    }

    &-actions {
        @include flex;
        @include flex-align($x: center);
		left: 0;
		position: absolute;
        background: $white;
        bottom: 0;
        flex-direction: column;
        min-height: rem-calc(104);
        opacity: 0;
        pointer-events: none;
        padding: rem-calc(0 16);
        width: 100%;

        .tocart {
            @include flex();
            @include flex-align($x:center);
            @include buttonStyle($theme-card-basket-style);
            font-size: rem-calc(15);
            padding: rem-calc(10 0);
            width: 100%;
        }

        .towishlist  {
            @include buttonStyle($theme-card-wishlist-style);
            align-content: center;
            font-size: rem-calc(15);
            margin: 0 auto;
            padding: rem-calc(10 0);
            text-align: center;
            position: relative;
            width: 100%;
            margin-top: rem-calc(10px);

            &:before {
                @include flex-center;
                @include ird-icon($ird-icon-heart, 14px);
                color: $theme-button-style-2-color;
                width: rem-calc(25px);
                height: 100%;
            }
        }

        .wishlist {
            order: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-top: rem-calc(10px);
            white-space: nowrap;

            @include internet-explorer {
                @include negative-margin(5);
            }

            > button[data-action="add-to-wishlist"] {
                color: $theme-button-style-2-color;
                font-size: rem-calc(15);
                font-weight: $global-weight-semibold;
                margin-right: rem-calc(5px);
                padding: rem-calc(6 10 6 5);
                width: auto;
                z-index: 11;
                position: relative;
                @include flex-center;
                border-right: 1px solid rgba($theme-card-color, 0.75);

                @include internet-explorer {
                    font-size: 14px;
                }

                white-space: nowrap;
                letter-spacing: rem-calc(-0.2px);

                &:before {
                    @include flex-center;
                    @include ird-icon($ird-icon-heart, 14px);
                    color: $theme-button-style-2-color;
                    width: rem-calc(15px);
                    height: 100%;
                    margin-right: rem-calc(5px);
                }
            }

            .action.toggle.change {
                cursor: pointer;
                font-size: 0;
                height: rem-calc(19px);
                transition: all 0.15s linear;
                width: rem-calc(19px);
                z-index: 11;
                position: relative;

                &:after {
                    @include flex-center;
                    @include ird-icon($ird-icon-chevron-down, 12px);
                    @include absolute-full;
                }
                &.active {
                    transform: rotate(180deg);
                }

            }

            .items {
                background: $white;
                @include vendor(box-shadow, 0px 0px 18px 0px rgba(0, 0, 0, 0.13));
                left: 50%;
                margin: 0;
                padding: rem-calc(45px 0px 0px);
                position: absolute;
                top:0;
                transform: translateX(-50%);
                width: rem-calc(260px);
                z-index: 10;
                opacity: 1;
                height: auto;
                transition: all 0.15s ease;

                &[aria-hidden="true"] {
                    display: none;
                }

                .item {
                    @include font(14, 21, $global-weight-skinny);
                    cursor: pointer;

                    > span {
                        display: block;
                        padding: rem-calc(8px 15px);
                    }

                    &:hover {
                        background: rgba($theme-card-color, 0.14);
                    }

                    .action.new {
                        border-top: 1px solid rgba($theme-card-color, 0.14);

                        &:before {
                            content: "+";
                            display: inline-block;
                            margin-right: rem-calc(5);
                        }
                    }
                }
            }
        }

        .actions-secondary {
            @include flex;
            @include flex-align($x: center);
        }
    }

    .price-box {

        span {
            display: block;
        }
    }

    .price-box.price-final_price {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        height: 100%;
        max-height: $product-pricebox-height;
    }

    .product-item-prices-container {
        position: relative;
        width: 100%;
        height: $product-pricebox-height;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;

        .old-price {
            margin-top: rem-calc(0);
        }

        &.exclusive-price {

            .price-box.price-final_price {
                flex-direction: row-reverse;
                justify-content: center;
                align-items: flex-end;
                height: auto;
                position: relative;
                margin-top: rem-calc(3px);

                .price {
                    @include font(14, 17, $global-weight-skinny);
                    opacity: 0.75;
                    margin: rem-calc(0 3);

                    @include breakpoint(large only) {
                        font-size: rem-calc(12px);
                    }
                }

                .special-price .price {
                    font-weight: $global-weight-semibold;
                }
            }

            .exclusive-price-container  {
                position: relative;
                bottom: auto;
            }
        }

        &.exclusive-to {

            .price-box .special-price .price-container .price-wrapper .price {
                color: $theme-card-accent;
            }
        }

        .exclusive-price-container {
            width: 100%;
            text-align: center;
            color: $theme-card-accent;
            display: block;
            @include font(14, 16, $global-weight-skinny);
            position: absolute;
            bottom: 30px;
            left: 0;

            @include breakpoint(large only) {
                font-size: rem-calc(12px);
            }

            .price {
                display: block;
                margin-top: rem-calc(5px);
                @include font(21, 25, $global-weight-semibold);
            }
        }
    }

    @include breakpoint(xlarge) {

        &-info:hover {

            .product-item-details {
                opacity: 0;
                pointer-events: none;
            }

            .product-item-actions {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .price-box .price-container .price-wrapper .price {
        @include breakpoint(large only) {
            font-size: rem-calc(16px);
        }
    }
}

.product {

    &.options {
        flex-direction: column;
        border-bottom: none!important;

        .toggle {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            cursor: pointer;

            span {
                @include font(12, 22, $global-weight-normal);
                @include vendor(user-select, none);
                display: flex;
                position: relative;
                padding-right: rem-calc(22px);

                &:after {
                    @include ird-icon($ird-icon-chevron-down);
                    @include flex-center;
                    font-size: rem-calc(8px);
                    width: rem-calc(22px);
                    height: rem-calc(22px);
                    text-align: center;
                    transition: all 0.15s ease-in-out;
                    position: static;
                    top:0;
                    right: 0;
                }
            }
        }

        .content {
            display: none;

            .subtitle {
                @include font(12, 14, $global-weight-semibold);
                display: block;
                margin-bottom: rem-calc(3px);
            }

            .item-options {
                display: block;

                .item-option {
                    line-height: 1;

                    strong, span {
                        @include font(12, 14, $global-weight-normal);
                    }

                    strong {
                        font-weight: $global-weight-semibold;
                        margin-right: rem-calc(3px);
                    }
                }
            }

        }

        &.active {
            .toggle span:after {
                transform: rotate(180deg);
            }
        }
    }
}

body.has-touch {
    .products-grid.grid {
        .product-item:hover {

            .product-item-details {
                opacity: 1;
                pointer-events: all;
            }

            .product-item-actions {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

.product-item-info {
    .product-item-details {
        .auctioned-product-block {
            text-align: center;
        }
    }
}

//add to basket button pagebuilder
.add-to-basket-buttons {
    .pagebuilder-button-primary {
        color: $theme-button-style-1-color;
        font-weight: $global-weight-semibold;
    }

    .pagebuilder-button-secondary {
        @include buttonStyle($theme-product-checkout-button-style);
        color: $theme-button-style-2-color;
        font-weight: $global-weight-semibold;
    }

    .pagebuilder-button-link {
        color: $theme-tertiary-color;
        font-weight: $global-weight-semibold;
        text-decoration: underline;
        font-size: rem-calc(14px);
    }
}
