/**
 * Get relative image url
 * @param  {string} $filename Filename
 * @return {string}           File path relative to {theme}/web/images/
 */
 @function get-image($filename) {

	@return url('../images/#{$filename}');

}

$prototype-text-overflow: ellipsis !default;

/// Truncating the text, elipsis by default.
/// @param {String} $overflow [$prototype-text-overflow] Text Truncate
@mixin text-truncate(
  $overflow: $prototype-text-overflow
) {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: $overflow; 
  white-space: nowrap !important;
}