.checkout-payment-method {

	.payment-method {
		border-bottom: 1px solid darken($white, 15%);

		&._active {

			.payment-method-content {
				display: block;
				overflow: hidden;
			}
		}

		&-title {
			padding: $global-padding 0;
		}

		&-content {
			display: none;

			.primary {
				@include flex;
				@include flex-align($x: right);
				margin: rem-calc(16 0);

				@include breakpoint(small only) {
					padding-left: rem-calc(16px);
				}

				.action {
					@include buttonStyle($theme-checkout-place-order-button-style);

					@include breakpoint(small only) {
						width: 100%;
					}

					&-update {
						margin-right: rem-calc(10);
						@include buttonStyle($theme-checkout-update-button-style);
					}

					&-cancel {
						@include buttonStyle($theme-checkout-cancel-button-style);
					}

					&.disabled {
						pointer-events: none;
						opacity: 0.5;
					}
				}
			}
		}
	}

	input[type='radio'] {
		margin: 0 0 0 2px;
	}

	.checkout-billing-address {
		margin-left: $global-margin;
	}
}
