// Component variables
$theme-global-nav-top-bar-shadow: 0px 1px 1px 0px rgba($theme-primary-color, 0.05);
$theme-global-nav-top-bar-highlight: $theme-secondary-color;
$theme-global-nav-background: $white;
$theme-global-nav-font-color: $theme-primary-font-color;
$theme-global-nav-font-family: $theme-font-family;
$shadow-color: rgb(211, 211, 211);
$nav-active-link: lighten($theme-primary-color, 15%);

body.global-nav-active {

  .header-middle {
    @include breakpoint(large down) {
      div[data-content-type="row"], .header-right {
        display: none;
      }
    }

    .header-logo {
      @include breakpoint(xlarge up) {
        display: none;
      }
    }
  }

  div[data-content-type="row"] {
    margin: 0 !important;
  }

  .right-block.global-nav-links {
    .header.links.global-nav-list {
      @include breakpoint(large down) {
        display: none;
      }
    }
  }

  .header-options {
    .logo {
      @include breakpoint(large down) {
        display: none;
      }
    }
  }

  .header-top-nav_left {
    .global-header-logo {
      display: block;
    }
  }

  .page-header {
    .header-top-nav_right {
      @include breakpoint(xlarge) {
        max-width: 30%;
      }
    }
  }

  .header-right {
    @include breakpoint(xlarge) {
      width: 25.2%;
    }

    .account-link {
      display: none;
    }

    .header-user-links {
      @include breakpoint(large down) {
        display: none;
      }
    }
  }

  .navigation {

    .megamenu.level-top {
      a.level-top {
        font-weight: bold;
      }
    }

    @include breakpoint(xlarge) {
      background: lighten($theme-primary-color, 11%);

      & > ul, .fullwidth-wrapper-inner {
        justify-content: space-around;
      }

      .megamenu.level-top-fullwidth, .megamenu.level-top-sectioned {
        .level0.submenu {
          background: lighten($theme-primary-color, 11%);

          .fullwidth-wrapper {
            background-color: lighten($theme-primary-color, 11%);
            border-top: none;
          }
        }

        a.level-top:hover {
          box-shadow: none;

          &:after {
            background: lighten($theme-primary-color, 11%);
          }
        }
      }

      .level0 {
        .submenu {
          background: lighten($theme-primary-color, 11%);
        }
      }
    }

    @include breakpoint(large down) {
      display: none !important;

      &.active {
        display: block !important;
      }
    }
  }

  .header-wrapper-globalnav, .header-top-nav {
    padding: 0;
  }

  .global-nav {
    background: $theme-global-nav-background;
    color: $theme-global-nav-font-color;
    font-family: $theme-global-nav-font-family;

    // style the container
    &-container {
      max-width: $global-width;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(large down) {
        flex-direction: column;
      }
    }

    &-links,
    &-menu {

      @include breakpoint(large down) {
        display: block;
        width: 100%;
      }
    }

    &-links {

      .list-item {

        &-link {
          display: block;
          @include font(14, 20, $global-weight-skinny, normal, initial, $theme-global-nav-font-family);
          color: $theme-global-nav-font-color;

          @include breakpoint(xlarge) {
            padding: rem-calc(30px 14px);
          }
        }

        &.highlight.list-item {
          @include breakpoint(xlarge) {
            background: $theme-global-nav-top-bar-highlight;
          }

          a.list-item-link {
            font-weight: $global-weight-bold;

            @include breakpoint(xlarge) {
              padding: rem-calc(30px 20px);
            }
          }
        }
      }
    }

    &-menu {

      .list-item {
        border: none;

        @include breakpoint(xlarge) {
          border-left: rem-calc(1px) solid rgba($theme-primary-color, 0.1);
          border-right: rem-calc(1px) solid rgba($theme-primary-color, 0.1);

          &:not(:first-child) {
            margin-left: rem-calc(-1px);
          }
        }

        &-link {
          display: block;
          @include font(15, 20, $global-weight-semibold, normal, initial, $theme-global-nav-font-family);

          @include breakpoint(xlarge) {
            color: $theme-global-nav-font-color;
            padding: rem-calc(30px 60px);
          }
        }

        &.active {
          background: $theme-primary-color;
          border-bottom: none;
          @include breakpoint(large) {
            box-shadow: 0 2px 2px 0px #02020230;
            z-index: 1;
          }

          @include breakpoint(large down) {
            background: lighten($theme-primary-color, 5%);
          }

          .list-item-link {
            color: $white;
          }
        }
      }

      ul.global-nav-list {
        li.list-item {
          a.list-item-link {
            font-weight: $global-weight-bold;
          }
        }
      }
    }

    // top bar
    &-top-bar {
      border-bottom: rem-calc(1px) solid rgba($theme-primary-color, 0.1);
      @include vendor(box-shadow, $theme-global-nav-top-bar-shadow);
    }

    &-middle-bar {
      border-bottom: rem-calc(1px) solid rgba($theme-primary-color, 0.1);
    }

    // logo
    &-logo {
      max-width: rem-calc(450px);
      width: 100%;

      a, img {
        display: block;
        width: 100%;
      }
    }

    // general style for global nav lists
    &-list {
      display: flex;
      align-items: center;
      margin: 0;

      @include breakpoint(large down) {
        flex-direction: column;
        width: 100%;
      }

      .list-item {
        display: block;
        transition: all 0.25s ease-in;

        &:not(.active):not(.highlight) {

          &:hover {
            background: $theme-primary-color;

            a.list-item-link {
              color: $white;
            }
          }
        }

        @include breakpoint(large down) {
          border: none;
          border-bottom: rem-calc(1px) solid rgba($theme-navigation-color, 0.2);
          width: 100%;
          text-align: unset;

          &-link {
            color: $theme-navigation-color;
            padding: rem-calc(30px 40px);
            font-weight: $global-weight-bold;
          }
        }
      }
    }

    &-account-details {
      background: $theme-global-nav-background;
      color: $theme-global-nav-font-color;

      @include breakpoint(large down) {
        width: 100%;
        background: $theme-primary-color;
        padding: 0;
      }

      .tablet-separator {
        margin: 0 1.25rem;
      }

      span {
        @include font(16, 17, $global-weight-skinny);

        @include breakpoint(large down) {
          color: $white;
        }

        &.points-container {
          float: left;
          margin-right: 0.25rem;

          .points {
            line-height: 1.7rem;
          }
        }

        @include breakpoint(large) {
          line-height: 1.8rem;
        }
      }

      .points, .currencySymbol {
        @include font(18, 18, $global-weight-bold);
        text-transform: lowercase;
      }

      .separator {
        margin-left: rem-calc(60px);
        margin-right: rem-calc(60px);
      }
    }
  }

  .panel.wrapper {
    -webkit-box-shadow: 0 2px 2px 0 $shadow-color;
    -moz-box-shadow: 0 2px 2px 0 $shadow-color;
    -ms-box-shadow: 0 2px 2px 0 $shadow-color;
    -o-box-shadow: 0 2px 2px 0 $shadow-color;
    box-shadow: 0 2px 2px 0 $shadow-color;
    border-bottom: none;


    @include breakpoint(large down) {
      display: inline-block;
      float: left;
      box-shadow: none;
      border-bottom: none;
      padding: 20px 20px 10px 20px;

      .header-top-nav_right {
        padding: 0;
      }

      a.hamburger-menu.nav-toggle {
        margin-left: 0px;
      }
    }
  }

  .header.content {
    .header-wrapper-globalnav {

      -webkit-box-shadow: 0 2px 2px 0 $shadow-color;
      -moz-box-shadow: 0 2px 2px 0 $shadow-color;
      -ms-box-shadow: 0 2px 2px 0 $shadow-color;
      -o-box-shadow: 0 2px 2px 0 $shadow-color;
      box-shadow: 0 2px 2px 0 $shadow-color;
      border-bottom: none;

      @include breakpoint(large down) {
        padding: .75rem 0 !important;
      }
    }
  }


  .points-wrapper {
    @include breakpoint(large down) {
      background-color: #fff;
      padding: 0.8rem 0rem 0.8rem 2.5rem;
      display: flex;
      flex-wrap: wrap;

      .points-container {
        order: 2;
        margin-right: 5px;
      }
      .currencySymbol {
        order: 3;
        text-transform: lowercase;
      }
      .tier {
        order: 1;
        width: 100%;
      }

      .tablet-separator {
        display: none;
      }
    }
  }


  .mobile-options {
    @include breakpoint(large down) {
      padding: 0px;
      margin-top: 0;
      .global-nav-list {
        div[data-content-type='row'] {
          width: 100%;
        }

        .list-item {
          border: none;
          border-bottom-color: currentcolor;
          border-bottom-style: none;
          border-bottom-width: medium;
          border-bottom: .0625rem solid rgba(255, 255, 255, 0.2);
          width: 100%;

          .list-item-link {
            background: #4F4C4D;
            margin: 0px;
            text-align: left;
            text-transform: capitalize;
            padding: 1.875rem 2.5rem;
            border: none;
            color: #fff !important;

            &:hover {

              padding: 1.875rem 2.5rem;
            }
          }
        }


        .list-item.nav.active {
          border-bottom: none;
        }
      }
    }
  }

  .navigation-off-canvas.nav-sections {
    @include breakpoint(large down) {
      // background: $theme-primary-color;
      .navigation {
        background: lighten($theme-primary-color, 5%);


        li.level0.level-top {
          border-top: none;
          padding: 0;

          a.level-top {
            padding: rem-calc(15px 30px 15px 50px);

            &.ui-state-active {
              background: $nav-active-link;
            }
          }

          a {
            padding: 0;

            span {
              text-transform: capitalize;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          ul.submenu, .fullwidth-wrapper.ui-menu-item {
            border-top: 0;
            background: lighten($theme-primary-color, 5%);
            box-shadow: unset !important;
          }

          ul.submenu {
            li.all-category {
              a {
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 1.6px;
                padding: rem-calc(20px 0 10px 50px);
              }
            }

            .fullwidth-wrapper {
              background: lighten($theme-primary-color, 5%);

              .fullwidth-wrapper-inner {
                li.megamenu.level1 {
                  a.ui-corner-all {
                    span:last-child {
                      padding-left: rem-calc(50px);
                    }
                  }

                  ul.level1.submenu {
                    li.megamenu.level2 {
                      a.ui-corner-all {
                        &:before {
                          left: 3.1rem;
                          content: '.';
                          font-size: 50px;
                          margin-top: -22px;

                          @-moz-document url-prefix() {
                            margin-top: -40px;
                          }
                        }

                        span:last-child {
                          padding-left: rem-calc(70px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }

      .offCanvas-header {
        .offCanvas-title {
          padding-left: 2.5rem;
        }
      }
    }
  }

  .greet {
    @include breakpoint(large down) {
      display: none;
    }
  }

  .logged-in {
    .greet {
      list-style: none;
      @include breakpoint(large down) {
        display: block;
        padding: 1.8rem 0rem 1.8rem 2.5rem;
      }
    }
  }

  .authorization-link {
    .list-item-link:before {
      content: "";
      background-image: url('.../../../images/iconLoggedOn.png');
      width: 20px;
      height: 20px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      float: left;
      margin-right: 10px;

      @include breakpoint(large down) {
        filter: invert(100%);
        position: relative;
        top: -4px;
      }
    }
  }

  .points-slider {
    @include breakpoint(large) {
      padding-bottom: 2px;
    }

    .ranger-slider {
      #searchSlider {
        #minPoints, #maxPoints {
          border: .160rem solid #fff;
          height: 2rem;
          width: 2rem;
          background: #ccc;
        }

        .slider-fill {
          background-color: #ccc;
        }
      }
    }

    .points-values {
      color: $white;
      min-width: 10rem;
    }
  }

  .delivery-country {
    border: none;
    float: right;
    margin-right: 5%;

    @include breakpoint(640 down) {
      margin-right: 5%;
    }

    .button {
      &::before {
        content: '';
        display: block;
        height: 36px;
        width: 36px;
        background: url(.../../../images/icon_delivery@2x.png) no-repeat;
        background-size: contain;
        background-position-y: 0px !important;
        @include breakpoint(640 down) {
          width: 30px;
          height: 26px;
          background-position-y: 8px;
        }
      }
    }

    #dropdown-delivery {
      background: $theme-primary-color;

      ul {
        li {
          a {
            color: $white;
          }
        }
      }
    }
  }

  .page-header {
    .header {
      .showcart {
        @include breakpoint(640 down) {
          width: 1.5rem;
        }

        &::before {
          color: $white;
          content: '';
          width: 36px;
          height: 36px;
          background: url(.../../../images/icon_basket@2x.png) no-repeat;
          background-size: 100%;
          @include breakpoint(640 down) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .globalnav-header-right-links {
    li {
      list-style-type: none;
      float: right;
      margin: 0 5%;
      display: flex;

      a {
        height: 2.25rem;
        width: 2.25rem;
        margin-right: 0;
        background-size: 2.25rem 2.25rem;
        background-position: center center;
        background-repeat: no-repeat;
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include breakpoint(640 down) {
          width: 1.5rem;
        }

        &:before {
          content: '';
          display: block;
          height: 36px;
          width: 36px;
          background: url(.../../../images/icon_delivery@2x.png) no-repeat;
          background-size: contain;
          background-position-y: 0px !important;
          color: $theme-primary-font-color;
          font-size: rem-calc(28px);

          @include breakpoint(640 down) {
            font-size: 1.5rem;
          }
          @include breakpoint(640) {
            font-size: 1.75rem;
          }
        }
      }
    }
  }


  .block-search-parent {
    background: $theme-primary-color;

    .block-search {
      background: $theme-primary-color;


      .block-search-wrapper {
        background: $theme-primary-color;
        position: static;

        .block-title {
          color: $white;
        }

        .block-content {
          #search_mini_form {
            float: left;


            .field {
              min-width: 11rem;
            }


            .points-slider {

              .ranger-slider {
                @include breakpoint(large) {
                  width: 15rem;
                }
                @include breakpoint(large) {
                  margin-left: 1%;
                }
              }
            }

            @include breakpoint(large) {
              .actions {
                margin-top: -2px;
              }
            }
          }

          #minicartOffCanvasToggle {
            float: right;
            margin-left: 5%;

            @include breakpoint(640 down) {
              margin-left: 5%;
            }
          }

          .header-user-links {
            float: right;
            margin: 0 5%;

            @include breakpoint(640 down) {
              margin: 0 5%;
            }

            .wishlist-link {
              margin-right: 0px;

              @include breakpoint(640 down) {
                width: 1.5rem;
              }

              &:before {
                content: '';
                width: 36px;
                height: 36px;
                display: block;
                color: $white;
                background: url(.../../../images/icon_favourite@2x.png) no-repeat;
                background-size: 100%;
                @include breakpoint(640 down) {
                  font-size: 1.5rem;
                }
                @include breakpoint(640) {
                  font-size: 1.75rem;
                }
              }

              .counter {
                @include flex;
                @include flex-align($x: center);
                @include border-rounded;
                background: $theme-icon-indicator-background;
                color: $theme-icon-indicator-color;
                font-size: rem-calc(12);
                font-weight: $global-weight-bold;
                height: rem-calc(20);
                line-height: rem-calc(20);
                width: rem-calc(20);
                text-indent: 0;
                position: absolute;
                right: rem-calc(-7px);
                top: rem-calc(-1px);
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(medium down) {
    .block-search-parent {
      .block.block-search {
        overflow: visible;

        &.is-mobile-active {
          .block-search-wrapper {
            position: fixed;

            .block-title {
              button.close-button {
                span {
                  color: $white;
                }
              }
            }

            .block-content {
              .globalnav-header-right-links {
                display: none !important;
              }

              #search_mini_form {
                width: 100% !important;

                .points-slider, .actions {
                  display: flex;
                  visibility: visible !important;
                }
              }
            }
          }
        }

        .block-search-wrapper {
          .block.block-content {
            #search_mini_form {
              width: 55%;
              transition: none;
              max-height: 51px;

              .field.search {
                width: 100%;
              }

              .points-slider, .actions {
                visibility: hidden;
                transition: none;

                & * {
                  transition: none;
                }
              }
            }

            .globalnav-header-right-links {
              display: flex;
              float: right;
              flex-direction: row-reverse;
              width: 45%;
              position: relative;
              z-index: 999;
              transition: none;
            }
          }
        }
      }
    }
  }
  @include breakpoint(large) {
    .block-search-wrapper {
      #search_mini_form {
        width: 66%;
      }

      .globalnav-header-right-links {
        width: 13%;
        float: right;
      }
    }
  }

  #offCanvasNavigation {
    box-shadow: none;
    @include breakpoint(400 down) {
      padding-right: 10%;
      background: linear-gradient(to right, $theme-primary-color 90%, rgba(255, 0, 0, 0) 10%);
    }

    &.is-open {
      .close-button {
        height: 60px;
        background: $theme-primary-color;
        top: 0;
        border-bottom: 2px solid darken($white, 25%);

        span {
          vertical-align: text-top;
        }
      }

      &.logged-in {
        .offCanvas-header {
          border-bottom: none;

          .close-button {
            border-bottom: none;
            height: 75px;
          }
        }
      }
    }

    .offCanvas-header {
      .offCanvas-title {
        background: $theme-primary-color;
      }
    }

    #offCanvasNavigationContainer {

      .global-nav-menu {
        background: $theme-primary-color;

        .global-nav-list {
          h3 {
            font-size: 1.1875rem;
          }
        }
      }

      .points-wrapper {
        .points, .currencySymbol {
          font-weight: 700;
        }
      }
    }

    .navigation.active {
      h3.mobile-nav-head {
        color: #fff;
        width: 100%;
        font-size: 1.1875rem;
        padding: .9375rem 1.875rem .9375rem 3.125rem;
        background: #706d6e;
        margin: 0px;
        font-weight: 700;

        &.active {
          background: lighten($theme-primary-color, 5%);
        }
      }
    }

    .mobile-options {
      background: $theme-primary-color;

      .global-nav-list {
        .list-item {
          background: $theme-primary-color;

          a {
            font-weight: 500;
          }

          &.authorization-link {
            .login-button {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .mobile-nav-head {
    @include breakpoint(xlarge) {
      display: none;
    }
  }
}

.header-top-nav_left {
  .global-header-logo {
    display: none;
  }
}