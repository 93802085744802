.modal {

	&-slide,
	&-popup {
		bottom: 0;
		left: 0;
		min-width: 0;
		position: fixed;
		right: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: visibility 0s .3s, opacity .3s ease;
		transition: visibility 0s .3s, opacity .3s ease;

		&._show {
			visibility: visible;
			opacity: 1;
			@include vendor(transition, opacity .3s ease);

			.modal-inner-wrap {
				@include vendor(transform, translate(0, 0));
			}

		}

		.modal-inner-wrap {
			background-color: $white;
			@include vendor(box-shadow, 0 0 12px 2px rgba(0, 0, 0, 0.35));
			opacity: 1;
			pointer-events: auto;
		}
	}

	&-slide {
		left: rem-calc(44px);
		z-index: 900;

		&._show {

			.modal-inner-wrap {
				@include vendor(transform,  translateX(0))
			}
		}

		.modal-inner-wrap {
			height: 100%;
			overflow-y: auto;
			position: static;
			@include vendor(transform, translateX(100%));
			@include vendor(transition, transform 0.3s ease-in-out);
			width: auto;
		}

		&._inner-scroll {

			.modal-inner-wrap {
				overflow-y: visible;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
			}

			.modal-header,
			.modal-footer {
				-webkit-flex-grow: 0;
				flex-grow: 0;
				-webkit-flex-shrink: 0;
				flex-shrink: 0;
			}

			.modal-content {
				overflow-y: auto;
			}

			.modal-footer {
				margin-top: auto;
			}

		}

		.modal-header {
			padding-bottom: 2.1rem;
			padding-top: 2.1rem;
		}

	}

	&-popup {
		z-index: 900;
		left: 0;
		overflow-y: auto;

		&._show {

			.modal-inner-wrap {
				@include vendor(transform,  translateY(0))
			}

		}

		.modal-inner-wrap {
			margin: 5rem auto;
			width: 90%;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			box-sizing: border-box;
			height: auto;
			left: 0;
			position: absolute;
			right: 0;
			@include vendor(transform, translateY(-200%));
			@include vendor(transition, -webkit-transform 0.2s ease);
			max-width: rem-calc(500);
		}

		&._inner-scroll {
			overflow-y: visible;

			.modal-inner-wrap {
				max-height: 70%;
			}

			.modal-content {
				overflow-y: auto;
			}

		}

		.fieldset {
			border: 0;
			margin: 0;
			padding: 0;
		}

		.modal-header,
		.modal-footer {
			-webkit-flex-grow: 0;
			flex-grow: 0;
			-webkit-flex-shrink: 0;
			flex-shrink: 0;
		}

		.modal-footer {
			margin-top: auto;

			.action-save-address {
				@include button-style-1;
				margin-right: rem-calc(20);
				padding: rem-calc(8 16);
			}

			.action-hide-popup {
				@include button-style-3;
				padding: rem-calc(8 16);
			}

		}

		.modal-footer-actions {
			text-align: right;
		}

	}

	&-custom,
	&-popup,
	&-slide {

		.modal-header {
			min-height: rem-calc(60px);
			position: relative;
			background: $theme-primary-color;

			@include breakpoint(small only) {
				padding: rem-calc(20px 60px 20px $global-padding);
			}

			@include breakpoint(medium) {
				padding: rem-calc(20px 60px 20px 60px);
			}

			.action-close {

				@include vendor(box-sizing, border-box);
				border: 0;
				cursor: pointer;
				display: block;
				font-size: 0;
				height: 100%;
				line-height: inherit;
				margin: 0;
				position: absolute;
				right: 0;
				text-decoration: none;
				top:0;
				width: rem-calc(60px);
				padding: 0;
				@include vendor(box-shadow, none);
				@include vendor(text-shadow, none);

				&.disabled, &[disabled] {
					cursor: not-allowed;
					pointer-events: none;
				}

				&:focus, 
				&:active, 
				&:hover {
					border: none;
				}

				> span {
					@include element-invisible;
				}

				&:after {

					@include flex;
					@include flex-center;
					@include absolute-full;
					@include ird-icon($ird-icon-close, 24px);
					color: $white;
				}
			}
		}

		.modal-title {
			color: $white;
			font-weight: $global-weight-semibold;
			font-size: rem-calc(20px);
			margin-bottom: 0;
			min-height: 1em;
			word-wrap: break-word;
			text-align: center;

			@include breakpoint(small only) {
				font-size: rem-calc(18px);
			}
		}

		.modal-content {
			padding: rem-calc($global-padding);

			@include breakpoint(medium) {
				padding: rem-calc(30px);
			}

			> div {
				@include font(14, 20, 400);

				@include breakpoint(medium) {
					max-height: rem-calc(360px);
					overflow-x: hidden;
					overflow-y: auto;
					padding-right: rem-calc(10px);

					&::-webkit-scrollbar-track {
						background-color: darken($theme-body-background, 5%);
						width: rem-calc(8px);
						border-radius: rem-calc(8px);
					 }
					 
					 &::-webkit-scrollbar {
						width: rem-calc(8px);
						border-radius: rem-calc(8px);
						background-color: darken($theme-body-background, 15%);
					 }
					 
					 &::-webkit-scrollbar-thumb {
						background-color: darken($theme-body-background, 15%);
						width: rem-calc(8px);
						border-radius: rem-calc(8px);
					 }
				}
			}
		}

		.modal-footer {
			display: flex;
			flex-wrap: nowrap;

			@include breakpoint(small only) {
				padding: rem-calc(20px $global-padding);
			}

			@include breakpoint(medium) {
				padding: rem-calc(30px);
				padding-top: 0;
			}

			button {
				flex-grow: 1;
				padding: rem-calc(9px 40px);
				margin-right: rem-calc(20px);

				&:last-child {
					margin-right: 0;
				}

				span {
					line-height: rem-calc(17px);
				}
			}
		}
	}
}

fieldset[disabled] [class*="modal-"] .action-close {
	cursor: not-allowed;
	pointer-events: none;
}

.modal-slide {

	.action-close {
		padding: 2.1rem 3rem;
	}

	.page-main-actions {
		margin-bottom: -12.9rem;
		margin-top: 2.1rem;
	}
}

.modals-overlay {
	background-color: rgba($theme-primary-color, 0.55);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

body._has-modal-custom .modal-custom-overlay {
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 899;
}

.ie9, .ie10, .ie11 {

	.modal-popup._inner-scroll {
		overflow-y: auto;

		.modal-inner-wrap {
			max-height: none;
		}
	}
}
