

.off-canvas {
    background: $white;
}

.position-right {
    transform: translateX(100%);

    &.is-transition-push {
		@include vendor(box-shadow, 13px 5px 20px 13px rgba(10, 10, 10, 0.25))
    }
}

.position-left {
	transform: translateX(-100%);
	max-width: rem-calc(405);
	width: 100%;

	&.is-transition-push {
		@include vendor(box-shadow, 10px 0 20px -13px rgba(10, 10, 10, 0.25))
	}
}

.offCanvas {

	&-header {
		border-bottom: 2px solid darken($white, 25%);
		color: $theme-minicart-font-color;
		font-size: rem-calc(20);
		font-weight: $global-weight-semibold;
		text-transform: uppercase;
		padding: $global-padding;

		h3 {
			font-size: rem-calc(20);
			font-weight: $global-weight-semibold;
			margin-bottom: 0;
		}
	}

	&-content {
		padding: $global-margin;
	}
}
