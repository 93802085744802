.mage {

	&-error:not(select):not(input):not(textarea) {
		background: rgba($alert-color, 0.15);
		border: 1px solid $alert-color;
		font-size: rem-calc(12);
		font-weight: $global-weight-semibold;
		padding: rem-calc(8 16);
	}
}

select + .mage-error,
input + .mage-error {
	margin-bottom: rem-calc(15px);
}

.page.messages {
	@include flex-grid-row(); 

	div {
		width: 100%
	}
}

.messages {

	&:empty {
		margin-bottom: 0;  
	}

	&-wrapper {
		@include flex-grid-column(12); 
	}
}

.message {

	@include notification-style;

	&-success {
		background-color: rgba($success-color, 0.15);
		border: 1px solid $success-color;

		&:after {
			@include ird-icon($ird-icon-success);
			color: $success-color;
		}
	}

	&-error {
		background-color: rgba($alert-color, 0.15);
		border: 1px solid $alert-color;

		&:after {
			@include ird-icon($ird-icon-error);
			color: $alert-color;
		}
	}

	&.notice,
	&-notice,
	&-warning,
	&.info  {
		background-color: rgba($warning-color, 0.15);
		border: 1px solid $warning-color;

		&:after {
			@include ird-icon($ird-icon-warning);
			color: $warning-color;
		}
	}
}

.mobile-notice {
	font-size: rem-calc(12);
	font-weight: $global-weight-skinny;

	@include breakpoint(1025px) {
		@include element-invisible;
	}
}
