.cta-banner {
    @include flex;
    @include flex-grid-column(12);
    flex-flow: column;
    height: auto;
    position: relative;
    padding-top: rem-calc(15px);
    padding-bottom: rem-calc(15px);

    .image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    img {
        display: block;
        height: auto;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top:50%;
        transform: translate(-50%,-50%);
        width:auto;
        z-index: 1;
        max-width: none;
    }

    &_content {
        background: $white;
        height: 100%;
        left: 25%;
        max-width: rem-calc(287);
        padding: rem-calc(24 15 34 20);
        position: relative;
        width: 100%;
        z-index: 2;

        @include breakpoint(large down) {
            margin-top: rem-calc(15px);
            margin-bottom: rem-calc(15px);
            left: 0;
            top: auto;
        }

        .cta-link {
            @include buttonStyle($theme-bottom-banner-button-style);
            max-width: rem-calc(230);
            width: 100%;
        }
    }

    .banner-description {
        color: $theme-carousel-color;
        margin-bottom: rem-calc(25);
        width: 100%;
        @include font(32, 38, $global-weight-skinny);
    }

    .banner-title {
        @include font(22, 31, $global-weight-semibold);
        color: $theme-carousel-color;
        font-family: $theme-font-family;
        margin-bottom: rem-calc(20);
        width: 100%;
    }

}

.homepage-bottom-banner {

    .cta-banner_content {
        @include breakpoint(small only) {
            margin-left: auto;
            margin-right: auto;
        }

        .banner-title {
            @include font(17, 30, $global-weight-semibold);
        }

        .banner-description {
            @include font(24, 38, $global-weight-skinny);
        }
    }

    &.has-offset-box {
        max-height: rem-calc(253px);
        margin-bottom: rem-calc(8px);
        padding-top: 0;
        padding-bottom: 0;

        .cta-banner_content {
            top: rem-calc(15px);
        }
    }

}

.block-banners-wrapper {
	@include flex-grid-row();
    max-width: 100%;
}

.block-banners {
	@include flex-grid-column(12, 0);
}

// banner items

.homepage-main-carousel {
    .banner-item-content {
        padding: rem-calc(30px);
    }
}

.banner-items {
    margin-bottom: rem-calc(30px);
    .banner-item {
        min-height: rem-calc(320px);
    }

    &.banner-boxed {
        max-width: rem-calc($global-limited-width);
        padding: rem-calc(0 15px);
        margin-left: auto;
        margin-right: auto;

        .container {
            @include breakpoint(large) {
                padding: rem-calc(0 30px);
            }
        }

    }

    &.keep-image-ratio {
        min-height: auto!important;

        .banner-item {
            padding: 0;
            height: auto!important;

            .banner-image {
                left: auto;
                top:auto;
                right: auto;
                bottom: auto;
                min-height: initial;
                min-width: initial;
                position: relative;
                transform: none;
                width:100%;
                height: auto;
                z-index: 0;
                max-width: 100%;
            }
        }

        .banner-item-content {
            display: none!important;
        }
    }

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
        height: 100%;
    }


    .banner-item {
        padding: rem-calc(30px 0 30px);
        position: relative;
        overflow-y: visible;
        overflow-x: hidden;
        height: 100%;

        .banner-image {
            display: block;
            height: auto;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top:50%;
            transform: translate(-50%,-50%);
            width:auto;
            z-index: 0;
            max-width: none;
        }

        .container {
            max-width: rem-calc($global-width);
            margin: 0 auto;
            @include flex-grid-column();
            display: flex;
            height: 100%;
            align-items: center;
        }

        .banner-item-content {
            font-family: $theme-font-family;
            background: $white;
            min-height: rem-calc(254px);
            position: relative;
            z-index: 1;
            max-width: rem-calc(286px);

            @include breakpoint(small down){
                margin: auto;
            }

            .cta-title {

                width: 100%;
                color:$theme-carousel-color;
                font-family: inherit;
                font-size: 	$bannerTitleFontSize;
                font-weight: $bannerTitleWeight;
                line-height: $bannerTitleLineHeight;
                margin-bottom: rem-calc(27px);
            }

            .cta-description {
                color: $theme-carousel-color;
                font-family: inherit;
                font-size: $bannerDescFontSize;
                font-weight: $bannerDescWeight;
                line-height: $bannerDescLineHeight;
                margin-bottom: rem-calc(42px);
            }

            .cta-link {
                @include buttonStyle($theme-carousel-button-style);
                display: inline-block;
                font-family: inherit;
                font-size: $bannerCtaFontSize;
                font-weight: $bannerCtaWeight;
                line-height: $bannerCtaLineHeight;
                text-align: center;
                width: 100%;
            }
        }
    }

    .slick-dots {
        padding-bottom:rem-calc(10px);
    }
}

.block-banners {
    .banner-items {
        .banner-item-content {
            background: transparent;
            max-width: 100%;
        }
    }
}
