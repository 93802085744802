.cart-container {
    @include flex-grid-row();
    @include negative-margin;

    .cart-summary-wrapper {
        @include flex-grid-column(12);

        @include breakpoint(large) {
            @include flex-grid-size(4);
            @include flex-order(2);
        }

        @include breakpoint(xlarge) {
            @include flex-grid-size(3);
        }
        @include breakpoint(medium down) {
            margin-bottom: 1rem;
        }
    }

    .form-cart {
        @include flex-grid-column(12);

        @include breakpoint(large) {
            @include flex-grid-size(8);
            @include flex-order(1);
        }

        @include breakpoint(xlarge) {
            @include flex-grid-size(9);
        }
    }
}

.checkout-cart-index {

    .columns {
        @include breakpoint(small only) {
            padding: 0;
        }
    }

    .page-main .main {
        overflow: visible;
    }

    .table-wrapper {

        .items thead {
            border: none;
            background: $theme-body-background;

            @include breakpoint(small only) {
                display: none;
            }

            tr {
                white-space: nowrap;
            }

            th {
                @include font(14, 21, $global-weight-semibold);

                * {
                    font-weight: inherit;
                }
            }
        }

        td {
            vertical-align: middle;
            @include font(14, 21, $global-weight-skinny);
        }

        .input-text {

            &.qty {
                text-align: center;
                width: rem-calc(60);
                margin: 0;
                -moz-appearance: textfield;
            }
        }

        .items tbody {
            border: none;

            tr:not(.item-actions) {

                @include breakpoint(small only) {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            td {
                @include breakpoint(small only) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border: 1px solid $light-grey;
                    margin-top: -1px;

                    &:first-child {
                        flex-wrap: wrap;

                        &:before {
                            width: 100%;
                        }
                    }

                    &:before {
                        content: attr(data-th) ":";
                        display: block;
                        margin-right: rem-calc(10);
                        @include font(14, 21, $global-weight-semibold);
                    }
                }
            }
            tr.item-actions {
                td {
                    @include breakpoint(small only) {
                        &:before {
                            content: attr(data-th) "";
                        }
                    }
                }
            }
        }
    }

    .cart-totals {
        font-size: rem-calc(14);
        font-weight: $global-weight-semibold;

        tbody {
            background: transparent;

            tr {
                background: inherit;
            }
        }

        th, td {
            vertical-align: middle;
            @include font(14, 21, $global-weight-skinny);
            border-bottom: 1px solid darken($light-grey, 5%);
            padding: rem-calc(10);
            * {
                font-weight: inherit;
            }

            &:first-child {
                font-weight: $global-weight-normal;
            }
        }

        th {
            text-align: left;
            padding-left: 0;

            @include breakpoint(large) {
                width:42%;
            }
        }

        td {
            text-align: right;
            padding-right: 0;

            @include breakpoint(large) {
                width:58%;
            }
        }
    }
    #cart-totals {
        table.data.table.totals {
            @include breakpoint(large) {
                display: flex;
                flex-flow: column;
                tr.totals {
                    display: flex;
                }
            }
            tr.totals {
                @include breakpoint(small only) {
                    th.mark {
                        max-width: 12ch;
                    }
                }
                span.title {
                    overflow-wrap: break-word;
                }
            }
        }
    }
    .cart-summary {
        padding: rem-calc(15);
        background: $theme-body-background;
        height: 100%;

        .summary {
            border-bottom: 1px solid darken(#f6f6f6, 15%);
            display: flex;
            margin-bottom: rem-calc(15);
            padding-bottom: rem-calc(10);
            width: 100%;
        }

        .discount {
            padding-bottom: $global-margin;

            .content {
                display: block!important;
                width: 100%;
            }

            .coupon {
                display: flex;
                flex-wrap: wrap;
                margin-top: rem-calc(10);

                .mage-error {
                    margin: rem-calc(8px 0);
                }

            }

            .field {
                flex-grow: 1;
            }

            .actions-toolbar {
                flex-basis: rem-calc(250);
                margin-left: rem-calc(20);

                @include breakpoint(medium only) {
                    flex-basis: rem-calc(200);
                }

                @include breakpoint(large) {
                    flex-grow: 1;
                    margin-left: 0;
                }

                @include breakpoint(small only) {
                    width: 100%;
                    margin-left: 0;
                    flex-basis: 100%;

                    .primary {
                        width: 100%;
                    }
                }

                .action {
                    width: 100%;
                }
            }
        }

        .coupon {
            border: 0;
            padding: 0;

            .apply {
                @include button-style-1;
            }

            .cancel {
                @include button-style-3;
            }
        }

        .checkout-methods-items {

            .checkout {
                @include button-style-1;

                width: 100%;
            }
            .multicheckout {
                @if($theme-secondary-color == #007dba) {
                    @include cta(transparent, underline, $theme-secondary-color, transparent, 40);
                } @else {
                    @include cta(transparent, underline, $theme-secondary-color, transparent, 0);
                }


                width: 100%;
            }
        }
    }

    .form-cart {

        .actions {
            display: flex;
            align-items: center;

            @include breakpoint(medium) {
                justify-content: space-between;
            }

            @include breakpoint(small only) {
                justify-content: center;
                flex-wrap: wrap-reverse;
            }

            .primary, .secondary {
                @include breakpoint(small only) {
                    width: 100%;
                }
            }
        }

        .clear,
        .update {
            @include button-style-3;
            @include breakpoint(large down) {
                padding-left: rem-calc(10px);
                padding-right: rem-calc(10px);
            }
        }

        .continue {
            @include button-style-1;

            @include breakpoint(large only) {
                padding-left: rem-calc(10px);
                padding-right: rem-calc(10px);
            }

            @include breakpoint(medium only) {
                padding-left: rem-calc(10px);
                padding-right: rem-calc(10px);
            }
        }

        .action {

            @include breakpoint(small only) {
                width: 100%;
                margin: rem-calc(10 0);
            }
        }

        .item-actions {
            background: transparent;

            @include breakpoint(small only) {
                margin-bottom: 20px;
                display: block;
                width: 100%;
            }

            td {
                border-bottom: 1px solid $light-grey;

                @include breakpoint(small only) {
                    display: block;
                    width:100%;
                    border: 1px solid $light-grey;
                    margin-top: -1px;
                }
            }

            .actions-toolbar {
                display: flex;
                width: 100%;

                .action {
                    @include interactive-link;
                    width: auto;
                    margin: 0;
                }

                .action-towishlist {
                    @include interactive-link;
                    margin-right: auto;
                }

                .action-delete {
                    margin-left: rem-calc(20px);
                    display: inline-block;
                }

            }

        }

        .term-condition {
            color: $theme-tertiary-color;
            font-size: rem-calc(12);
            @include interactive-link;
        }

        .col.item {
            @include breakpoint(medium) {
                display: flex;
                align-items: center;
            }

            .product-item {
                &-details {
                    padding-top: 0;
                    display: inline-block;
                    @include breakpoint(small only) {
                        height: auto;
                        width: auto;
                    }
                }

                &-name {
                    text-align: left;
                    height: auto;
                    min-height: auto;
                }

                &-photo {
                    display: inline-block;
                    margin-right: rem-calc(10);
                    width: rem-calc(130);
                    height: rem-calc(130);
                    position: relative;
                    flex-shrink: 0;

                    @include breakpoint(large down) {
                        width: rem-calc(100);
                        height: rem-calc(100);
                    }

                    img {
                        min-height: 100%;
                        max-height: rem-calc(100);
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: auto;
                    }
                }
            }
        }

        .col.qty {

            label {
                @include element-invisible;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .col.subtotal {
            @include breakpoint(medium) {
                padding-right: 0;
            }
        }

        .col.cash {
            @include breakpoint(medium) {
                padding-left: 0;
            }
        }

        .col:not(.item) {

            @include breakpoint(medium) {
                width: 1%;
                white-space: nowrap;
            }
        }
    }

    .modals-wrapper {

        .modal-popup .modal-inner-wrap {
            max-height: 90%;
            margin-top: 5%;
        }

        .modal-header {
            padding-left: rem-calc(60);
            padding-right: rem-calc(60);
            text-align: center;
        }

        .modal-footer {
            display: flex;
            flex-wrap: wrap;

            button {
                flex-grow: 1;
                cursor: pointer;
            }

        }

    }
}

.ie11 {
    .checkout-cart-index {
        .items tbody {
            .col.item {
                @include breakpoint(medium) {
                    display: table-cell;
                }
                .product-item {
                    &-details {
                        width: calc(100% - 150px);
                    }
                    &-photo {
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

.multishipping-checkout-addresses {

    .multicheckout.form {

        .title {
            @include font(16, 24, $global-weight-semibold);
            margin-bottom: rem-calc(20);
        }

        caption {
            @include element-invisible;
        }

        table {

            .col {

                vertical-align: middle;

                &.product {
                    width: 35%;
                }

                &.qty {

                    label {
                        @include element-invisible;
                    }

                    input {
                        width: rem-calc(60);
                        text-align: center;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }

                &.address {

                    label {
                        @include element-invisible;
                    }

                    select {
                        margin-bottom: 0;
                    }
                }

                &.actions {

                    a {
                        white-space: nowrap;
                    }
                }
            }
        }

        tbody {

            .product-item-name {
                @include font(14, 14, $global-weight-semibold);
                text-align: left;
                min-height: auto;
                height: auto;
                overflow: visible;
            }

            .item-options {
                margin-bottom: 0;

                @include breakpoint(small only) {
                    margin-bottom: rem-calc(10);
                }
            }

            .item-option {
                display: block;
                padding-left: rem-calc(10);

                .item-option-label {
                    @include font(14, 14, $global-weight-semibold);

                    &:after {
                        content: ':';
                        display: inline-block;
                        margin-right: 5px;
                    }
                }

                .item-option-value {
                    @include font(14, 14, $global-weight-skinny);
                }
            }
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;

            .primary {

                @include breakpoint(small only) {
                    width: 100%;
                }
            }

            .secondary {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;

                @include breakpoint(small only) {
                    order: -1;
                }
            }
        }

        .actions-toolbar {

            .primary,
            .secondary {

                .action {

                    @include breakpoint(large down) {
                        padding-right: rem-calc(10);
                        padding-left: rem-calc(10);
                    }
                }
            }

            .primary {

                .back {
                    @include breakpoint(medium down) {
                        @include element-invisible;
                    }
                }
            }

            .mobile-action {
                @include breakpoint(large) {
                    @include element-invisible;
                }

                @include breakpoint(medium down) {
                    @include element-invisible-off;
                    width: 100%;

                    .action {
                        width: 100%;
                        margin-top: rem-clac(10);
                    }
                }
            }
        }

        .action {

            &.continue {
                @include button-style-1;

            }

            &.update, &.add {
                @include button-style-3;
            }

            &.back {
                @include cta(transparent, underline, $theme-secondary-color, transparent);
            }

            @include breakpoint(medium) {
                &:not(:first-child) {
                    margin-left: rem-calc(10);
                }
            }
            @include breakpoint(small only) {
                width: 100%;
                margin: rem-calc(10px 0);
            }
        }
    }

    .table-wrapper {

        .items thead {

            @include breakpoint(small only) {
                display: none;
            }

            tr {
                white-space: nowrap;
            }


            th {
                @include font(14, 21, $global-weight-semibold);

                * {
                    font-weight: inherit;
                }
            }
        }

        td {
            vertical-align: middle;
            @include font(14, 21, $global-weight-skinny);
        }

        .input-text {

            &.qty {
                text-align: center;
                width: rem-calc(60);
                margin: 0;
            }

        }

        .items tbody {

            @include breakpoint(small only) {
                border: none;
            }

            tr:not(.item-actions) {

                @include breakpoint(small only) {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: rem-calc(20);
                    background: transparent;
                }
            }

            td {

                @include breakpoint(small only) {

                    width: 100%;
                    display: flex;
                    align-items: center;
                    border: 1px solid $light-grey;
                    margin-top: -1px;

                    &:first-child {
                        flex-wrap: wrap;
                        width: 100%;

                        &:before {
                            width: 100%;
                        }
                    }

                    &:before {
                        content: attr(data-th) ":";
                        display: block;
                        white-space: nowrap;
                        margin-right: rem-calc(10);
                        @include font(14, 21, $global-weight-semibold);
                    }
                }
            }
        }
    }
}

.multishipping-checkout-addresses,
.multishipping-checkout-address-newshipping {
    background: $white;

    .page-main {
        @include flex-grid-row;
        @include font(14, 21, $global-weight-skinny);

        align-content: flex-start;

        @include breakpoint(small only) {
            @include flex-grid-column(12);
        }
    }

    .columns .column {
        padding: 0;

        @include breakpoint(small only) {
            margin: rem-calc(0 -10);
        }
    }
}

.multishipping-checkout-address-newshipping {

    .form-address-edit {
        @include flex-grid-row;

        @include breakpoint(large) {
            justify-content: space-between;
        }

        fieldset {
            @include flex-grid-column(12);

            @include breakpoint(large) {
                @include flex-grid-column(49%);
            }
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: rem-calc(20);
            width: 100%;

            .primary,
            .secondary {

                @include breakpoint(small only) {
                    width: 100%;
                }

            }

            .action {

                &.submit {
                    @include button-style-1;
                }

                &.back {
                    @include button-style-2;
                    @include breakpoint(medium) {
                        margin-left: rem-calc(10);
                    }
                }

                @include breakpoint(small only) {
                    width: 100%;
                    margin: rem-calc(10 0);
                }
            }
        }
    }
}
