/// Rounded square utility or rectangle utility (all corners): Rounds all corners to an element by default to make a pill shape.
@mixin border-rounded {
    border-radius: 5000px !important;
  }


  //   Rounded iredeem buttons .  Ex @inclue cta; will include basic blue, rounded button
@mixin cta(
    $background: $theme-secondary-color,
    $underlineHover: none,
    $color: $theme-secondary-alternative-font-color,
    $border-color: $theme-secondary-color,
    $padding-left-right: 40
  ) {

  @include border-rounded;
  @include font(14, 17, $global-weight-semibold);

  align-items: center;
  background: $background;
  border: 1px solid $border-color;
  color: $color;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-width: rem-calc(100);
  padding: rem-calc(9 $padding-left-right);
  text-align: center;

  &:hover {
    @if($underlineHover== underline) {
      text-decoration: $underlineHover;
    } @else {
      background: darken($background, 5%);
    }
  }
  &:active {
    @if($underlineHover == underline) {
      text-decoration: none;
    } @else {
      background: darken($background, 10%);
    }
  }

  @include breakpoint(small only) {
    font-size: rem-calc(12px);
  }
}

@mixin button-style-1 {
  @include cta(
    $theme-button-style-1-background,
    none,
    $theme-button-style-1-color,
    $theme-button-style-1-border-color
  );
}

@mixin button-style-2 {
  @include cta(
    $theme-button-style-2-background,
    none,
    $theme-button-style-2-color,
    $theme-button-style-2-border-color
  );
}

@mixin button-style-3 {
  @include cta(
    $theme-button-style-3-background,
    none,
    $theme-button-style-3-color,
    $theme-button-style-3-border-color
  );
}

@mixin buttonStyle($style: null) {

  @if $style == 1 {
    @include button-style-1;
  }
  @if $style == 2 {
    @include button-style-2;
  }
  @if $style == 3 {
    @include button-style-3;
  }

}

@mixin interactive-link {

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }
}

// Gotham font mixin
@mixin font($size: 16, $lineHeight: 20, $weight: $global-weight-semibold, $style: normal, $transform: initial, $family: $theme-font-family) {
  font-size: rem-calc($size);
  line-height: rem-calc($lineHeight);
  font-weight: $weight;
  font-style: $style;
  text-transform: $transform;
  font-family: $family;
}

@mixin font-email($size: 16, $lineHeight: 20, $weight: $global-weight-semibold, $style: normal, $transform: initial, $family: $theme-font-family) {
  
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
  font-style: $style;
  text-transform: $transform;
  font-family: $family;
  
}

// mixin for targeting IE in scss

@mixin internet-explorer {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// mixin for targeting firefox
@mixin firefox {
  @media screen and (min--moz-device-pixel-ratio:0) {
    @content;
  }  
}  

//notifications 

@mixin notification-style {
  font-weight: 500;
  font-style: normal;
  
	margin-top: calc($global-margin / 2);
	margin-bottom: calc($global-margin / 2);
  padding: rem-calc(12 $global-padding 12 39);
  position: relative;
  width: 100%;

  &:after {
    @include flex-center;
    position: absolute;
    top:0;
    left:0;
    width: rem-calc(39px);
    height: 100%;
    max-height: rem-caclc(50px);
    font-size: rem-calc(22px);
  }

} 

@mixin message($type: info) {

  @include notification-style;

  @if ($type == error) {

		background-color: rgba($alert-color, 0.15);
    border: 1px solid $alert-color;

    &:after {
      @include ird-icon($ird-icon-error);
      color: $alert-color;
    }

  } @else if($type == success) {

		background-color: rgba($success-color, 0.15);
    border: 1px solid $success-color;

    &:after {
      @include ird-icon($ird-icon-success);
      color: $success-color;
    }

  } @else if ($type == info) {

		background-color: rgba($warning-color, 0.15);
    border: 1px solid $warning-color;
    
    &:after {
      @include ird-icon($ird-icon-warning);
      color: $warning-color;
    }

  }

}

@mixin negative-margin($valueTabletUp: 15, $valueMobile: 10) {
  width: auto;
  @include breakpoint(medium) {
    margin-left: rem-calc(-$valueTabletUp);
    margin-right: rem-calc(-$valueTabletUp);
  }
  @include breakpoint(small only) {
    margin-left: rem-calc(-$valueMobile);
    margin-right: rem-calc(-$valueMobile); 
  }  
}

@mixin horizontal-padding($valueTabletUp: 15, $valueMobile: 10) {
  @include breakpoint(medium) {
    padding-left: rem-calc(-$valueTabletUp);
    padding-right: rem-calc(-$valueTabletUp);
  }
  @include breakpoint(small only) {
    padding-left: rem-calc(-$valueMobile);
    padding-right: rem-calc(-$valueMobile); 
  }  
}

@mixin vendor($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center; 
}

@mixin absolute-full {
  position:absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin is-required {
    &:after {
      content: '*';
      position: relative;
      top: 0;
      left: rem-calc(3px);
      color: inherit;
      font-size: inherit;
  }
}
