$fotorama-image-max-width: rem-calc(700) !default; // Same as etc/view.xml
$fotorama-nav-thumbnail-width-small: rem-calc(80) !default;
$fotorama-nav-thumbnail-width-large: rem-calc(160) !default;
$fotorama-nav-thumbnail-gutter: rem-calc(48) !default;

.fotorama {

	.gallery-placeholder & {

		@include breakpoint(xlarge) {
			max-width: $fotorama-image-max-width + $fotorama-nav-thumbnail-width-small + $fotorama-nav-thumbnail-gutter;
		}

		@include breakpoint(xxlarge) {
			max-width: $fotorama-image-max-width + $fotorama-nav-thumbnail-width-large + $fotorama-nav-thumbnail-gutter;
		}

	}

	// Gallery Wrapper
	.fotorama__wrap {

		@include breakpoint(xlarge) {
			margin-left: $fotorama-nav-thumbnail-width-small + $fotorama-nav-thumbnail-gutter;
		}

		@include breakpoint(xxlarge) {
			margin-left: rem-calc(144);
		}

	}

	// Navigation Wrapper
	.fotorama__nav-wrap {

		&.fotorama__nav-wrap--vertical {
			left: ($fotorama-nav-thumbnail-gutter * -1) + 1;
			transform: translate(-100%, 0);
		}
	}

	// Navigation
	.fotorama__nav {

		// Navigation (thumbnails)
		&--thumbs {

			@include breakpoint(large only) {
				height: initial !important; // Override inline styles
			}
		}
	}

	.fotorama__stage {
		left: 0 !important;
	}

	&__arr {
		@include element-invisible;
	}

	// Navigation Item
	&__nav__frame {

		// Navigation Item (dot)
		&--dot {

			&.fotorama__active {

				.fotorama__dot {
					background-color: $theme-primary-color;
					border-color: $theme-primary-color;
					transform: scale(2);
					transition: background-color 250ms, border-color 250ms, transform 250ms;
				}
			}
		}

		// Navigation Item (thumb)
		&--thumb {
			opacity: .5;
			transform: scale(.85);
			transition: opacity 250ms, transform 500ms;

			&.fotorama__active {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	.fotorama__thumb__arr {
		@include element-invisible; // Override inline styles
	}

	// Active thumb highlight
	.fotorama__thumb-border {
		border-color: $theme-product-image-border-color;
	}

	&__caption {
		@include element-invisible;
	}
}
