.gdpr-cookie-policy-modal-wrapper {
  .modal-header {
    display: none;
  }

  .modal-footer {
    display: none;
  }

  .modal-content {
    padding: 0 0 10px;
    overflow: visible !important;
  }

  .gdpr-cookie-policy-language-switcher {
    padding: 10px 5px 0 15px;
    display: inline-block;
    width: 100%;
    .switcher {
      position: relative;
      margin-right: rem-calc(15px);

      .actions.dropdown {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 0 auto;
          @include breakpoint(large down) {
            width: 100%;
            padding: 0;
          }

          &:after {
            @include ird-icon($ird-icon-chevron-down, 9px);
            @include flex-center;
            color: #FFF8C7;;
            margin-left: rem-calc(5);
            height: rem-calc(12);
            width: rem-calc(12);
            transition: all 0.15s ease;

            @include breakpoint(large down) {
              color: $white;
            }
          }

          span {
            @include font(12, 17, $global-weight-semibold);
            color: #FFF8C7;
            cursor: pointer;
          }

          &.hover {
            &:after {
              transform: rotate(-180deg);
            }
          }
        }

        #switcher-store-trigger {

          @include breakpoint(large down) {

            img {
              position: relative;
              top: auto;
              left: auto;
              transform: translateY(0%);
              margin-right: rem-calc(5px);
            }
          }
        }
      }

      &.switcher-store {
        ul li a {
          display: flex;
          align-items: center;
        }
      }

      &.is-empty {

        .actions.dropdown {

          button{
            cursor: pointer;
            pointer-events: none;

            &:after {
              @include element-invisible;
            }
          }
        }
      }
    }

    .dropdown-pane {
      background: $white;
      width: auto;
      left: 0!important;
      top: 100%!important;
      margin-top: rem-calc(10px);
      opacity: 0;
      display: block;
      pointer-events: none;
      visibility: initial;
      transform-origin: top left;
      padding: 0;
      border: none;

      @include breakpoint(xlarge) {
        animation: dropdown-slide-fade-down 0.15s ease-out forwards;
      }

      @include breakpoint(large down) {
        animation: dropdown-slide-fade-down-mobile 0.15s ease-out forwards;
      }

      ul {
        border: 1px solid #cacaca;
      }

      .switcher-option {
        overflow: hidden;


        &:first-child a {
          padding-top: rem-calc(16px);
        }

        &:last-child a {
          padding-bottom: rem-calc(16px);
        }

        a {
          @include font(12, 12, $global-weight-semibold);

          color: $theme-header-font-color;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
          position: relative;
          padding: rem-calc(8px 16px 8px);

          span {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            display: block;
          }
        }
      }

      ul {
        margin-bottom: 0;
      }

      &.is-open {
        pointer-events: all;

        @include breakpoint(xlarge) {
          animation: dropdown-slide-fade-up 0.15s ease-out forwards;
        }

        @include breakpoint(large down) {
          animation: dropdown-slide-fade-up-mobile 0.15s ease-out forwards;
        }
      }
    }
  }
  .switcher.language {
    border: none;
  }

  #dropdown-language-gdpr-modal {
    position: absolute;
    z-index: 999;

  }

  #gdpr-cookie-policy-modal {
    overflow:visible
  }

  #gdpr-cookie-policy-modal .switcher-dropdown.is-open {
    position: absolute;
    z-index: 999;
    bottom: 30px;
    display: block;
    max-width: 20%;
    margin: 0px auto;
    top: auto !important;
    left: 44% !important;
  }


  .gdpr-cookie-policy-modal {
    padding-top: 5px;
    overflow: visible;

    .modal-inner-wrap {
      bottom: 0px;
      margin: 0;
      width: 100%;
      max-width: none;
      text-align: center;
      background-color: #2b2b2b;
      color: #FFF8C7;

      @include breakpoint(medium down) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    a {
      color: #F90;
      z-index: 99999999;
    }
  }

  .gdpr-modal-overlay {
    z-index: 901;
    background-color: #2b2b2b;
    opacity: 0.8;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .gdpr-cookie-policy-modal-clickable-page {
    top: 90%;

    .modal-inner-wrap {
      bottom: 0px;
      margin: 0;
      width: 100%;
      max-width: none;
      text-align: center;
      background-color: #2b2b2b;
      color: #FFF8C7;
      max-height: 500px;
    }

    a {
      color: #F90;
      z-index: 9999999;
    }
  }
}
