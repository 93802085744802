.iredeem-icon, 
.iredeem-icon:before {
  font-family: "IredeemIcons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// icons variables

// account
$ird-icon-account: "\e000";
$ird-icon-account-boxed: "\e003";

// basket 
$ird-icon-basket: "\e00c";
$ird-icon-basket-boxed: "\e00f";

// wishlist
$ird-icon-wishlist-boxed: "\e019";

// delete, close
$ird-icon-close: "\e001";
$ird-icon-delete: "\e002";


$ird-menu-link: "\2219";
// symbols
$ird-icon-error: "\e006";
$ird-icon-plus: "\e00e";
$ird-icon-minus: "\e00d";
$ird-icon-dropdown: "\e005";
$ird-icon-heart: "\e009";
$ird-icon-warning: "\e016";
$ird-icon-success: "\e012";
$ird-icon-van: "\e015";
$ird-icon-menu: "\e00a";
$ird-icon-search: "\e011";

// direction
$ird-icon-arrow-up: "\e00b";
$ird-icon-arrow-down: "\e004";
$ird-icon-arrow-left: "\e007";
$ird-icon-arrow-right: "\e008";

$ird-icon-chevron-left: "\e013";
$ird-icon-chevron-down: "\e010";
$ird-icon-chevron-right: "\e017";
$ird-icon-chevron-up: "\e018";

$ird-icon-chevron-link: "\e014";

// create icon mixin
@mixin ird-icon($icon, $font-size: 16px) {
    content: $icon;
    font-family: "IredeemIcons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    font-size: rem-calc($font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// mixin end


// Icons as classes. Use iredeem-icon as class plus one of the following
.icon-account:before {
  content: "\e000";
}
.icon-close:before {
  content: "\e001";
}
.icon-delete:before {
  content: "\e002";
}
.icon-account-boxed:before {
  content: "\e003";
}
.icon-arrow-down:before {
  content: "\e004";
}
.icon-dropdown:before {
  content: "\e005";
}
.icon-error:before {
  content: "\e006";
}
.icon-arrow-left:before {
  content: "\e007";
}
.icon-arrow-right:before {
  content: "\e008";
}
.icon-heart:before {
  content: "\e009";
}
.icon-menu:before {
  content: "\e00a";
}
.icon-arrow-up:before {
  content: "\e00b";
}
.icon-basket:before {
  content: "\e00c";
}
.icon-minus:before {
  content: "\e00d";
}
.icon-plus:before {
  content: "\e00e";
}
.icon-basket-boxed:before {
  content: "\e00f";
}
.icon-chevron-down:before {
  content: "\e010";
}
.icon-search:before {
  content: "\e011";
}
.icon-success:before {
  content: "\e012";
}
.icon-chevron-left:before {
  content: "\e013";
}
.icon-chevron-link:before {
  content: "\e014";
}
.icon-van:before {
  content: "\e015";
}
.icon-warning:before {
  content: "\e016";
}
.icon-chevron-right:before {
  content: "\e017";
}
.icon-chevron-up:before {
  content: "\e018";
}
.icon-wishlist-boxed:before {
  content: "\e019";
}
