html, body {
    height: 100%;
}

.light-font {
    font-weight: $global-weight-skinny;
}

.mobile-hidden {

    @include breakpoint(small only) {
        @include element-invisible;
    }
}

.show-for-sr {
    @include element-invisible;
}

.tocompare {
	@include element-invisible;
}

*:focus {
    outline: none!important;
}

body._has-modal {
    overflow: hidden;
}

// Alignment
.alignment-left {
    text-align: left;
}

.alignment-right {
    text-align: right;
}

.alignment-center {
    text-align: center;
}

// Colors
.primary-h-color {
    color: $theme-primary-color;
}

.secondary-h-color {
    color: $theme-secondary-color;
}

.tertiary-h-color {
    color: $theme-tertiary-color;
}

.accent-h-color {
    color: $theme-accent-color;
}

// Style checkbox

.field.choice, .wishlist-toolbar-select, .checkout-agreement {
    position: relative;

    label {
        @include font(14, 14, $global-weight-skinny);
        margin-left: 0;
        position: relative;
        display: flex!important;
        justify-content: flex-start;
        align-items: center;

        &:before {
            content: '';
            @include flex-center;
            width: rem-calc(20px);
            height: rem-calc(20px);
            margin-right: rem-calc(15);
            border: 1px solid $theme-checkout-checkbox-background;
            background-color: $white;
            position: relative;
            border-radius: 4px;
            flex-shrink: 0;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            opacity: 0;
            transform: scale(0.8);
            background: $theme-checkout-checkbox-background;
            border-radius: 4px;
            pointer-events: 0;
            z-index: -1;
            flex-shrink: 0;
        }
    }

    [type="checkbox"] {
        width: 0px;
        height: 0px;
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;

        &:checked + label {

            &:before {
                background-color: $theme-checkout-checkbox-background;
                content: "\e012";
                color: $theme-checkout-checkbox-color;
                font-family: "IredeemIcons" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                line-height: 1;
                font-size: rem-calc(12px);
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &:after {
                transform: scale(1.2);
                animation: checkbox 0.4s linear forwards;
            }
        }
    }

    [type="radio"] {
        width: 0px;
        height: 0px;
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;

        + label {
            &:before,
            &:after {
                border-radius: 50%;
            }
        }

        &:checked + label {

            &:before {
                background-color: $white;
                box-shadow: inset 0 0 0 5px $theme-checkout-checkbox-background;
            }

            &:after {
                transform: scale(1.2);
                animation: checkbox 0.4s linear forwards;
            }
        }
    }
}

@keyframes checkbox {
    0% {opacity: 0; transform: scale(1);}
    30% {opacity: 0.5; transform: scale(1.3);}
    100% {opacity: 0; transform: scale(1.8);}
}

.field.choice + .field.choice {
    margin-top: rem-calc(15);
}

// Action buttons should have cursor pointer

button.action {
    cursor: pointer;
}

.field._error {
    input {
        border-color: $error;
    }

    label {
        color: $error;
    }
}
input.qty-error {
    border-color: $error;
}

label {
    @include font(14, 17, $global-weight-skinny);
}

ul, ul li {
    list-style-type: none;
}

// loading mask

$circleBig: 100px;
$circleSmall: 70px;

body > .loading-mask {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($white, 0.8);
    pointer-events: all;
    user-select: none;

    img {
        display: none;
    }

    .loader {
        position: relative;
        width: rem-calc($circleBig * 2);
        height: rem-calc($circleBig * 2);

        &:before, &:after {
            content: "";
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &:before {
            width: rem-calc($circleBig);
            height: rem-calc($circleBig);
            margin-top: calc(-#{$circleBig} / 2);
            margin-left: calc(-#{$circleBig} / 2);
            border: rem-calc(2px) solid $theme-accent-color;
            border-top: rem-calc(2px) solid transparent;
            animation: rotating 1.5s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }

        &:after {
            width: rem-calc($circleSmall);
            height: rem-calc($circleSmall);
            margin-top: calc(-#{$circleSmall} / 2);
            margin-left: calc(-#{$circleSmall} / 2);
            border: rem-calc(2px) solid $theme-secondary-color;
            border-bottom: rem-calc(3px) solid transparent;
            animation: rotating 1.5s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86) reverse;
        }

        p {
            display: none;
        }
    }
}

.disabled, [disabled] {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
}

@keyframes rotating {
    100% {
        transform: rotate(360deg);
    }
}

.no-webkit-appearance {
    -webkit-appearance: none;
}

.field {

    // mark required inputs
    &._required > .label span {
        @include is-required;
    }
    // mark required inputs
    &.required  {

        > .label span {
            @include is-required;
        }

        .field._required {

           > .label:after {
                content: "";
            }
        }
    }

    // set color of field label on error
    &._required._error {

        .label span {
            color: $theme-primary-font-color;
        }
    }

    // override browser yellow input background color on autocomplete
    input[type="text"], [type="email"] {
        @include vendor(box-shadow, inset 0px 0px 0px 100px $white)
    }

    &._error {

        input, select, textarea {
            border-color: $error!important;
        }
    }

    // style error message
    .field-error {
        @include font(14, 14, $global-weight-semibold);
        color: $error;
        margin-top: rem-calc(-8px);
        margin-bottom: rem-calc(15px);
    }
}

select {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background-image: none;

    @include firefox {
        text-indent: 0.01px;
        text-overflow: '';
    }
}

@include internet-explorer {
    select::-ms-expand {
        display: none;
    }
}

// for mobile switch from portrait to landscape mode
.js-off-canvas-overlay.is-visible {
    display: none !important;
}
