.language{
    @include flex-align($y: middle);
	display: flex!important;

    span {
		margin-left: 0;
    }

}

img[src*=".svg"] {
    width: 100%; 
}
