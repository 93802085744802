.switcher {

    &-currency {
        font-size: rem-calc(12);

        @include breakpoint(xlarge) {
            margin-right: $global-margin;
        }
    }

    &-trigger {
        @include flex;
        @include flex-align($x:center, $y: middle);
        color: $theme-header-font-color;
        font-weight: $global-weight-semibold;
    }

    &-options {
        @include flex;
        @include flex-align($x: left, $y: middle);

        button {
            color: inherit;
        }
    }

    &-dropdown {
        width: 100%;
    }

    &-label {
        @include element-invisible;
    }

    @include breakpoint(large down) {
        border: 1px solid $theme-header-font-color;
        margin-bottom: rem-calc(15);
    }

    @include breakpoint(medium down) {
        @include flex;
        @include flex-grid-row;
        justify-content: space-between;
    }

	.switcher-options {

		@include breakpoint(xlarge) {
			width: auto;
		}
	}

    &-language {

        .language-flag {
            height: rem-calc(24);
            width: rem-calc(24);
            border-radius: rem-calc(2px);
            display: block;
            position: relative;
            margin-right: rem-calc(5px);
        }
    }

    &.is-empty {

        .dropdown-pane {
            @include element-invisible;
        }
    }

}

.language,
.store,
.delivery-country {
    position: relative;

    .dropdown-pane {
        height: auto;
        left: 0!important;
        max-height: rem-calc(285px);
        overflow-y: auto;
        top: rem-calc(32)!important;
        width: auto;

        @include breakpoint(xlarge) {
            top: rem-calc(25.6)!important;
        }
    }

    @include breakpoint(xlarge) {
        
        span {
            color: $theme-header-font-color;
            font-size: rem-calc(14);
        }
    }
}

.currency {

    .dropdown-pane {

        @include breakpoint(xlarge) {
            width: auto;
        }
    }
}

.language {

    .dropdown-pane {
        min-width: rem-calc(200px);

        ul {

            margin-bottom: 0;
            a {
                height: rem-calc(12px);
                box-sizing: content-box;
            }
        }
    }
}
